import { React, useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import PinIcon from '@mui/icons-material/Pin';
import Grid from '@mui/material/Unstable_Grid2';
import EastIcon from '@mui/icons-material/East';
import { Slide } from 'react-slideshow-image';
import 'react-slideshow-image/dist/styles.css';
import AIDetectionImage from '../images/AI-Detection.png';
import CalendarImage from '../images/Calendar.png';
import MediaOptionsImage from '../images/MediaOptions.png';
import RatingImage from '../images/Rating.png';

const LandingPage = () => {
  const navigate = useNavigate();
  // mobile viewport detection
  const [width, setWidth] = useState(window.innerWidth);

  const handleWindowSizeChange = () => {
    setWidth(window.innerWidth);
  }

  useEffect(() => {
    window.addEventListener('resize', handleWindowSizeChange);
    return () => {
      window.removeEventListener('resize', handleWindowSizeChange);
    }
  }, []);
  
  const isMobile = width <= 768;
  console.log(`Mobile Veiwport: ${isMobile}`);

  return (
    <Grid container spacing={2} justifyContent="center" alignItems="center" sx={{ height: '100vh', margin: "-10vh 10px 10px 10px" }}> 
      <Grid size={4} xs={12} sm={6}>
        <Typography variant="h2">Welcome to the</Typography>
        <Typography variant="h4" noWrap>&nbsp;Interview App!</Typography>

        <Typography component="p" sx={{margin: '20px 0px 35px 10px', fontWeight: '300', fontSize: '0.90rem', letterSpacing: '0.08333em' }}>
          Introducing our innovative interview app designed specifically for employers. This app simplifies interview scheduling with intelligent calendar integration, allowing employers to effortlessly find optimal times for interviews while accommodating candidates’ availabilities. Featuring AI-powered text detection, recruiters can receive real-time analysis of candidates’ responses analyzing if the answer is likely to be AI Generated. Additionally, the app provides real-time updates, ensuring that both employers and candidates stay informed about any changes or important notifications throughout the interview process. Experience a more organized and efficient hiring journey with our comprehensive interview solution.
        </Typography>

        <Button variant="contained" size="large" onClick={()=> navigate(window.localStorage.getItem("Authorization") ? 'welcome' : '/auth')} sx={{ margin: '-10px 15px 15px 0px', float: 'right'}}>
          Get Started <EastIcon sx={{margin: "0px 0px 0px 15px"}}/>
        </Button>
        
        <Button variant="contained" size="large" onClick={()=> navigate(window.localStorage.getItem("Authorization") ? 'payment' : '/pricing')} sx={{ margin: '-10px 15px 15px 0px', float: 'right'}}>
          Pricing <PinIcon sx={{margin: "0px 0px 0px 15px"}}/>
        </Button>
      </Grid>
      
      {isMobile ? <></> :
        <Grid size={8} xs={12} sm={6}>
          <Slide>
            <div>
              <div style={{ backgroundPosition: 'center', backgroundRepeat: 'no-repeat', height: '30vh', width: '100%', backgroundSize: 'cover', backgroundImage: `url(${AIDetectionImage})`}}></div>
            </div>
            <div>
              <div style={{ backgroundPosition: 'center', backgroundRepeat: 'no-repeat', height: '30vh', width: '100%', backgroundSize: 'contain', backgroundImage: `url(${CalendarImage})`}}></div>
            </div>
            <div>
              <div style={{ backgroundPosition: 'center', backgroundRepeat: 'no-repeat', height: '30vh', width: '100%', backgroundSize: 'contain', backgroundImage: `url(${MediaOptionsImage})`}}></div>
            </div>
            <div>
              <div style={{ backgroundPosition: 'center', backgroundRepeat: 'no-repeat', height: '30vh', width: '100%', backgroundSize: 'contain', backgroundImage: `url(${RatingImage})`}}></div>
            </div>
          </Slide>
        </Grid>
      }


    </Grid>
  )
};

export default LandingPage;