import { useState, useRef } from "react";
import Button from '@mui/material/Button';
import VideocamIcon from '@mui/icons-material/Videocam';
import Modal from '@mui/material/Modal';
import Box from '@mui/material/Box';
import CloseIcon from '@mui/icons-material/Close';

let mimeType = 'video/mp4';
// let mimeType = 'video/webm; codecs="opus,vp8"';
// if (MediaRecorder.isTypeSupported('video/webm; codecs=vp9')) {
//     mimeType = 'video/webm; codecs=vp9';
// } else  if (MediaRecorder.isTypeSupported('video/webm')) {
//     mimeType = 'video/webm';
// } else if (MediaRecorder.isTypeSupported('video/mp4')) {
//     mimeType = 'video/mp4';
// } else {
//     console.error("no suitable mimetype found for this device");
// }

const VideoRecorder = ({ media, ansIndex, isEdit }) => {
    const [permission, setPermission]           = useState(false);
    const [stream, setStream]                   = useState(null);
    const mediaRecorder                         = useRef(null);
    const liveVideoFeed                         = useRef(null);
    const [recordingStatus, setRecordingStatus] = useState("inactive");
    const [videoChunks, setVideoChunks]         = useState([]);
    const [recordedVideo, setRecordedVideo]     = useState(null);
    const [recordedVideoBlob, setRecordedVideoBlob] = useState(null);

    const [open, setOpen]                       = useState(false);
    const [isCameraInitializing, setIsCameraInitializing] = useState(false);

    const getCameraPermission = async () => {
        setIsCameraInitializing(true);
        setRecordedVideo(null);
        if ("MediaRecorder" in window) {
            try {
                const videoConstraints = {
                    audio: false,
                    video: true,
                };
                const audioConstraints = { audio: true };
                // create audio and video streams separately
                const audioStream = await navigator.mediaDevices.getUserMedia(
                    audioConstraints
                );
                const videoStream = await navigator.mediaDevices.getUserMedia(
                    videoConstraints
                );
                setPermission(true);
                //combine both audio and video streams
                const combinedStream = new MediaStream([
                    ...videoStream.getVideoTracks(),
                    ...audioStream.getAudioTracks(),
                ]);
                setStream(combinedStream);
                //set videostream to live feed player
                setOpen(true);
                setTimeout(function(){
                    liveVideoFeed.current.srcObject = videoStream;
                    setIsCameraInitializing(false);
                },100);
            } catch (err) {
                alert(err.message);
            }
        } else {
            alert("The MediaRecorder API is not supported in your browser.");
        }
    };

    const startRecording = async () => {
        setRecordingStatus("recording");
        const media = new MediaRecorder(stream, { mimeType });
        mediaRecorder.current = media;
        mediaRecorder.current.start();
        let localVideoChunks = [];
        mediaRecorder.current.ondataavailable = (event) => {
            if (typeof event.data === "undefined") return;
            if (event.data.size === 0) return;
            localVideoChunks.push(event.data);
        };
        setVideoChunks(localVideoChunks);
    };

    const stopRecording = () => {
        setPermission(false);
        setRecordingStatus("inactive");
        mediaRecorder.current.stop();
        mediaRecorder.current.onstop = () => {
            const videoBlob = new Blob(videoChunks, { type: mimeType });
            const videoUrl = URL.createObjectURL(videoBlob);
            setRecordedVideoBlob(videoBlob);
            setRecordedVideo(videoUrl);
            setVideoChunks([]);
        };
    };

    const closeCamera = () => {
        // Get all the tracks in the stream
        const tracks = stream.getTracks();
        // Stop each track
        tracks.forEach(track => track.stop());
        media([ansIndex, recordedVideo, recordedVideoBlob]);

        setOpen(false);
        setPermission(false);
    }

    return (
        <>
            {!permission ? 
                <Button disabled={isCameraInitializing} onClick={getCameraPermission} sx={{ margin: '5px 0px 0px 0px', fontSize: '10px', minWidth: '100px' }}  startIcon={<VideocamIcon sx={{color: 'red'}} />}>{isCameraInitializing ? "Please wait" : isEdit ? "Re-Record" : "Ready"}</Button>
            :
                <></>
            }
            <Modal open={open} onClose={(event, reason) => {
                // Prevent closing the modal if the reason is 'backdropClick'
                if (reason !== 'backdropClick') {
                    closeCamera();
                }
                }} BackdropProps={{
                    // Disable clicks on the backdrop to prevent closing the modal
                    onClick: (event) => event.stopPropagation(),
                }} aria-labelledby="modal-modal-title" aria-describedby="modal-modal-description">
                <Box sx={{ width: '60%', position: 'absolute', top: '50%', left: '50%', transform: 'translate(-50%, -50%)',bgcolor: 'background.paper', boxShadow: 24, p: 4, padding: '40px 25px 10px 25px!important' }}>
                    {!recordedVideo ? (
                        <video style={{width: '100%', height: '100%'}} ref={liveVideoFeed} autoPlay playsInline></video>
                    ) :  <></>}
                    {recordedVideo ? (
                    <div>
                        <video style={{width: '100%', height: '100%'}} src={recordedVideo} controls></video>
                        <Button onClick={closeCamera} sx={{ margin: '5px 0px 0px 0px', fontSize: '10px', float: 'right' }}  startIcon={<CloseIcon sx={{color: 'red'}} />}>Close</Button>
                        <a style={{float: 'left', margin: '5px 0px 0px 0px', fontSize: '10px' }} download href={recordedVideo}> Download Recording </a>
                    </div>
                ) :  <></>}
                {permission && recordingStatus === "inactive" ? (
                    <>
                        <Button onClick={closeCamera} sx={{ margin: '5px 0px 0px 0px', fontSize: '10px', float: 'right' }}  startIcon={<CloseIcon sx={{color: 'red'}} />}>Cancel</Button>
                        <Button onClick={startRecording} sx={{ margin: '5px 0px 0px 0px', fontSize: '10px', float: 'right' }}  startIcon={<VideocamIcon sx={{color: 'red'}} />}>Start Record</Button>
                    </> 
                ):  <></>}
                {recordingStatus === "recording" ? (
                    <Button onClick={stopRecording} sx={{ margin: '5px 0px 0px 0px', fontSize: '10px', float: 'right' }}  startIcon={<VideocamIcon sx={{color: 'red'}} />}>Stop Record</Button> 
                ) :  <></>}
                </Box>
            </Modal>
        </>
    );
};
export default VideoRecorder;