import React, { useState, useRef, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import Divider from '@mui/material/Divider';
import Typography from '@mui/material/Typography';
import Chip from '@mui/material/Chip';
import Box from '@mui/material/Box';
import FormControl from '@mui/material/FormControl';
import Alert from '@mui/material/Alert';
import Skeleton from '@mui/material/Skeleton';
import ReCAPTCHA from "react-google-recaptcha"
import axios from "axios";
import Link from '@mui/material/Link';
import { Link as RouterLink } from 'react-router-dom';
import Stack from '@mui/material/Stack';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import MicrosoftIcon from '@mui/icons-material/Microsoft';
import GoogleIcon from '@mui/icons-material/Google';
import LinkedInIcon from '@mui/icons-material/LinkedIn';
import LinearProgress from '@mui/material/LinearProgress';
import LogoImage from '../images/interview-high-resolution-logo-grayscale-transparent.png';
import loginImage from '../images/personal-id.png';

const AuthPage = () => {
  const navigate                      = useNavigate();
  const [isError, setIsError]         = useState('');
  const [isInprogress, setInprogress] = useState(false);
  const [isInprogressM, setInprogressM] = useState(false);
  const usernameTextInput             = useRef(null);
  const passwordTextInput             = useRef(null);
  const [username, setUsername]       = useState('');
  const [password, setPassword]       = useState('');
  const [captcha, setCaptcha]         = useState('');
  const [captchaWarn, setCaptchaWarn] = useState(false);
  const [userTier, setUserTier]       = useState(false);
  const [userDetails, setUserDetails] = useState('');
  const [passwordResetResponse, setPasswordResetResponse] = useState('');

  const handleResetClick = (path) => {
    window.location.pathname === path ? window.location.reload() : navigate(path)
  };

  const handleSetCredentialClick = (type) => {
    let regex = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]+$/i;
    switch (type) {
      case 'username':
        if(usernameTextInput.current.value.length > 0){
          if (!regex.test(usernameTextInput.current.value.replace(/\s/g, ''))) {
            setIsError("Invalid Email!");
          }else{
            setIsError("");
            setUsername(usernameTextInput.current.value);
          }
        }
        break;
      case 'password':
        if(passwordTextInput.current.value.length > 0){
          setIsError("");
          setPassword(passwordTextInput.current.value);
        }
        break;
      default:
        setIsError('Unknown Credentials');
        break;
    }
  };

  const handleCaptchaChange = (value) => {
    setCaptcha(value);
  };

  const handleLogoutClick = () => {
    window.localStorage.removeItem("Authorization");
    window.localStorage.removeItem("ssoProvider");
    removeCookie('Authorization');
    removeCookie('ssoProvider');
    window.location.href = '/auth';
  };

  useEffect(() => {
    if (username !== '' && password !== '' && captcha === ''){
      setCaptchaWarn(true);
    }

    if(username !== '' && password !== '' && captcha !== ''){
      setInprogress(true);
      let data = JSON.stringify({
        "INTERVIEW_USERDATA_username": username,
        "INTERVIEW_USERDATA_password": password,
        "token": captcha
      });

      axios.post(`${process.env.REACT_APP_REST_API_URL}/userAccount/login`, data, { 
        headers: { 'Content-Type': 'application/json' },
        maxBodyLength: Infinity,
      })
      .then((res) => {
        if(res.data.error === true) {
          setIsError(res.data.errorMessage);
          setUsername('');
          setPassword('');
          setCaptcha('');
          window.localStorage.removeItem("Authorization");
        }else{
          if(res.data.jwtToken){
            window.localStorage.setItem("Authorization", res.data.jwtToken);
            window.location.href = '/welcome';
          }
        }
        setInprogress(false);
        setCaptchaWarn(false);
      })
      .catch((e) => {
        setInprogress(false);
        console.log(e);
      });
    }
  }, [username, password, captcha]);

  useEffect(() => {
    if (window.localStorage.getItem("Authorization")){
      try {
        var jwtUserDetails = atob(window.localStorage.getItem("Authorization").split(".")[1]);
        let userTier   = JSON.parse(jwtUserDetails).INTERVIEW_USERDATA_tier;
        let userDetail = JSON.parse(jwtUserDetails).INTERVIEW_USERDATA_username
        console.log(`UserTier: ${userTier}`);
        setUserTier(userTier);
        setUserDetails(userDetail);
      } catch (error) {
        navigate("/");
      }
    }
  });

  const removeCookie = (name, path = '/') => {
    var domain = process.env.REACT_APP_ROOT_DOMAIN;
    const cookieString = `${encodeURIComponent(name)}=; expires=Thu, 01 Jan 1970 00:00:00 GMT; Secure=; path=${path}`;
    if (domain) {
        document.cookie = `${cookieString}; domain=${domain}`;
    } else {
        document.cookie = cookieString;
    }
  };

  const passwordResetClick = () => {
    setIsError('');
    setInprogressM(true);
    let __createdtime__ = Date.now();
    axios.post(`${process.env.REACT_APP_REST_API_URL}/userAccount/updateUserPassword`, {}, { 
      headers: { 'Content-Type': 'application/json', authtoken: window.localStorage.getItem("Authorization"), '__createdtime__': __createdtime__ },
      maxBodyLength: Infinity,
    })
    .then((res) => {
      if(res.data.error === true) {
        setIsError(res.data.errorMessage);
      }else{
        setPasswordResetResponse('Password Generated!');
        let ctr = 10;
        const intervalId = setInterval(function(){
          ctr--;
          if (ctr === 0){
            setPasswordResetResponse('');
            clearInterval(intervalId);
          }else{
            setPasswordResetResponse(`Password Generated! [${ctr}]`);
          }
        },1000);
      }
      setInprogressM(false);
    })
    .catch((e) => {
      setInprogressM(false);
      console.log(e);
    });
  };

  return (
    <Stack direction="column" spacing={2} justifyContent="center" alignItems="center" minHeight="70vh" maxWidth="43vh" sx={{margin: "0 auto"}}> 
        <FormControl fullWidth>
          <Box component="form" sx={{ '& .MuiTextField-root': { m: 1, width: '25ch' }, minHeight: "50px" }} noValidate autoComplete="off">
            <img style={{width: '190px', margin: '0px 0px -45px 115px'}} src={loginImage} alt="Something went wrong icons created by andinur - Flaticon" />
          </Box>
        </FormControl>
        { isError === '' ?
          <></>
        :
          <FormControl fullWidth>
            <Box component="form" sx={{ '& .MuiTextField-root': { m: 1, width: '25ch' }, minHeight: "50px" }} noValidate autoComplete="off">
              <Alert sx={{ minWidth: "41vh" }} severity="error">
                {isError} — <strong style={{textDecoration: "underline", cursor: "pointer"}} ><a onClick={() => handleResetClick('/auth')}>start again!</a></strong>
              </Alert>
            </Box>
          </FormControl>
        }

        <Box>
          { window.localStorage.getItem("Authorization") ? 
            <>
              <img style={{width: '80%', margin: '0px 0px 0px 40px'}} src={LogoImage} alt="Logo Image" />
              <Typography color="black" variant="h5" align="center" fontWeight={600}>Authenticated!</Typography>
              {window.localStorage.getItem("ssoProvider") ? 
              <Typography color="black" variant="caption" align="center" display="block"> 
                <strong>{window.localStorage.getItem("ssoProvider")}</strong>
              </Typography> : <></>}
              <Typography color="black" variant="caption" align="center" display="block"> 
                <strong>{userDetails}</strong>
              </Typography>
              <Divider sx={{marginY: '10px'}} >
                {isInprogressM ?
                  <LinearProgress sx={{display: 'inline-block', margin: '0px 5px 0px 0px', width: '50px!important'}} />
                : 
                  passwordResetResponse !== '' ?
                    <><Chip disabled color="success" label={passwordResetResponse}></Chip> | </>
                  : <><Chip onClick={() => passwordResetClick()} color="secondary" label="Reset Password"></Chip> | </>
                }
                <Chip onClick={() => handleResetClick('/welcome')} color="secondary" label="Continue Session"></Chip> | <Chip onClick={() => handleLogoutClick()} color="secondary" label="Logout"></Chip></Divider>
            </>
          : 
            <>
              {/* <img style={{width: '80%', margin: '0px 0px 0px 40px'}} src={LogoImage} alt="Logo Image" /> */}
              <Typography color="black" variant="h5" align="center" fontWeight={600}>Log in</Typography>
              <Typography color="black" variant="caption" align="center" display="block"> 
                No account yet? <Link component={RouterLink} to={"/signup"} color="secondary">create new account</Link>
              </Typography>
            </>
          }
        </Box>
        
        { isInprogress ? 
          <Box sx={{ margin: '10px 10px 10px 2px', minWidth: "41vh" }}>
            <Skeleton />
            <Skeleton animation="wave" />
            <Skeleton animation={false} />
          </Box>
        :
          window.localStorage.getItem("Authorization") ?
            <></>
            :
            <>
              <TextField disabled={username === '' ? false : true} type="email" label="Email" placeholder="email@domain.com" fullWidth size='small' inputRef={usernameTextInput} />
              { username === '' ?
                  <>
                    <Button variant="contained" fullWidth onClick={() => handleSetCredentialClick('username')} sx={{ textTransform: 'none' }} color="secondary">Next</Button>
                    <Button startIcon={<GoogleIcon />} variant="text" fullWidth onClick={() => {window.location.href = `${process.env.REACT_APP_REST_API_URL}/userAccount/sso-google`}} sx={{ textTransform: 'none', backgroundColor: '#ececec', margin: '10px 0px 0px 0px!important' }} color="secondary">Login with Google</Button>
                    <Button startIcon={<MicrosoftIcon />} variant="text" fullWidth onClick={() => {window.location.href = `${process.env.REACT_APP_REST_API_URL}/userAccount/sso-azure-entra`}} sx={{ textTransform: 'none', backgroundColor: '#ececec', margin: '10px 0px 0px 0px!important'  }} color="secondary">Login with Microsoft</Button>
                    <Button startIcon={<LinkedInIcon />} variant="text" fullWidth onClick={() => {window.location.href = `${process.env.REACT_APP_REST_API_URL}/userAccount/sso-linkedin`}} sx={{ textTransform: 'none', backgroundColor: '#ececec', margin: '10px 0px 0px 0px!important'  }} color="secondary">Login with LinkedIn</Button>
                  </>
                : 
                  <>
                    <TextField type="password" label="Password" fullWidth size='small' inputRef={passwordTextInput} />
                    <Box width="100%">
                      <ReCAPTCHA sitekey={process.env.REACT_APP_CAPTCHA_SITE_KEY} onChange={handleCaptchaChange}/>
                      { captchaWarn && (
                        <Typography sx={{ margin: '0px 0px 10px 0px', fontSize: '0.55rem', color: "red" }} variant="caption" display="block" gutterBottom>
                          complete the captcha to login
                        </Typography>
                      )}
                    </Box>
                    <Button variant="contained" fullWidth onClick={() => handleSetCredentialClick('password')} sx={{ textTransform: 'none' }} color="secondary">Log in</Button>
                  </>
              }
              <Typography color="black" align="center" sx={{fontSize: '10px'}}> 
                By continuing, you agree to our <Link component={RouterLink} to={"/terms-of-service"} color="secondary" >Terms of Service</Link> and <Link component={RouterLink} to={"/privacy-policy"} color="secondary">Privacy Policy</Link>
              </Typography>
            </>
        }
    </Stack>
  )
}

export default AuthPage;