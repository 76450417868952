import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import FormControl from '@mui/material/FormControl';
import Alert from '@mui/material/Alert';
import Skeleton from '@mui/material/Skeleton';
import Stack from '@mui/material/Stack';

const SSOPage = () => {
  const navigate                      = useNavigate();
  const [isError, setIsError]         = useState('');
  const [isInprogress, setInprogress] = useState(false);
  const [ssoProvider, setSsoProvider] = useState('');
  const [redirectInterval, setRedirectInterval] = useState(3);

  const handleResetClick = (path) => {
    window.location.pathname === path ? window.location.reload() : navigate(path)
  };

  useEffect(() => {
    if(window.localStorage.getItem("ssoProvider")){
      setSsoProvider(window.localStorage.getItem("ssoProvider"));
      let init = redirectInterval;
      setInterval(function(){
        setRedirectInterval(prevCount => prevCount - 1);
        init--
        if(init === 0){
          window.location.href = '/welcome';
        }
      }, 1000);
    }else{
      window.location.href = '/auth';
    }
  }, []);

  return (
    <Stack direction="column" spacing={2} justifyContent="center" alignItems="center" minHeight="70vh" maxWidth="43vh" sx={{margin: "0 auto"}}> 
        { isError === '' ?
          <></>
        :
          <FormControl fullWidth>
            <Box component="form" sx={{ '& .MuiTextField-root': { m: 1, width: '25ch' }, minHeight: "50px" }} noValidate autoComplete="off">
              <Alert sx={{ minWidth: "41vh" }} severity="error">
                {isError} — <strong style={{textDecoration: "underline", cursor: "pointer"}} ><a onClick={() => handleResetClick('/auth')}>start again!</a></strong>
              </Alert>
            </Box>
          </FormControl>
        }

        <Box>
          <Typography color="black" variant="h5" align="center" fontWeight={600}>Single sign-on (SSO)</Typography>
          { window.localStorage.getItem("Authorization") ? 
            <>
              { ssoProvider ? <Typography color="black" variant="h6" align="center" fontWeight={600}>{ssoProvider}</Typography> : <></>}
            </>
          : 
            <Typography color="black" variant="caption" align="center" display="block"> 
              Authenticate using trusted and supported Identity Provider.
            </Typography>
          }
        </Box>
        
        { isInprogress ? 
          <Box sx={{ margin: '10px 10px 10px 2px', minWidth: "41vh" }}>
            <Skeleton />
            <Skeleton animation="wave" />
            <Skeleton animation={false} />
          </Box>
        :
          <>
            <Alert sx={{ minWidth: "41vh", margin: '5px 0px 0px 0px' }} severity="info">
              Authenticated - redirecting in {redirectInterval}s...
            </Alert>  
          </>
        }
    </Stack>
  )
}

export default SSOPage;