import React, { useState, useRef, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import FormControl from '@mui/material/FormControl';
import Alert from '@mui/material/Alert';
import Skeleton from '@mui/material/Skeleton';
import ReCAPTCHA from "react-google-recaptcha"
import axios from "axios";
import Link from '@mui/material/Link';
import { Link as RouterLink } from 'react-router-dom';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Stack from '@mui/material/Stack';
import signUpImage from '../images/data-protection.png';

const SignupPage = () => {
  const navigate                      = useNavigate();
  const [isError, setIsError]         = useState('');
  const [isInprogress, setInprogress] = useState(false);
  const usernameTextInput             = useRef(null);
  const nameTextInput                 = useRef(null);
  const designationTextInput          = useRef(null);
  const passwordTextInput             = useRef(null);
  const passwordTextCnfrmInput        = useRef(null);
  const [username, setUsername]       = useState('');
  const [name, setName]               = useState('');
  const [designation, setDesignation] = useState('');
  const [password, setPassword]       = useState('');
  const [captcha, setCaptcha]         = useState('');
  const [signupRes, setSignupRes]     = useState('');
  const [redirectCtr, setRedirectCtr] = useState(5);

  const handleResetClick = (path) => {
    window.location.pathname === path ? window.location.reload() : navigate(path)
  };

  const handleSetCredentialClick = (type) => {
    let regex = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]+$/i;
    switch (type) {
      case 'username':
        if(usernameTextInput.current.value.length > 0){
          if (!regex.test(usernameTextInput.current.value.replace(/\s/g, ''))) {
            setIsError("Invalid Email!");
          }else{
            setIsError("");
            setUsername(usernameTextInput.current.value);
          }
        }
        break;
      case 'password':
        if(passwordTextInput.current.value.length > 0 && passwordTextCnfrmInput.current.value.length > 0){
          setIsError('');
          if(passwordTextInput.current.value !== passwordTextCnfrmInput.current.value){
            setIsError("Password not match!");
          }else{
            setPassword(passwordTextInput.current.value);
            if(nameTextInput.current.value.length > 3){
              setName(nameTextInput.current.value);
              if(designationTextInput.current.value.length > 1){
                setDesignation(designationTextInput.current.value);
              }
            }
          }
        }
        break;
      default:
        setIsError('Unknown Credentials');
        break;
    }
  };

  const handleCaptchaChange = (value) => {
    setCaptcha(value);
  };

  useEffect(() => {
    if(username !== '' && name !== '' && designation !== '' && password !== '' && captcha !== ''){
      setIsError("");
      setInprogress(true);
      let data = JSON.stringify({
        "INTERVIEW_USERDATA_username": username,
        "INTERVIEW_USERDATA_name": name,
        "INTERVIEW_USERDATA_designation": designation,
        "INTERVIEW_USERDATA_password": password,
        "INTERVIEW_USERDATA_tier": '0',
        "token": captcha
      }); 

      axios.post(`${process.env.REACT_APP_REST_API_URL}/userAccount/createAccount`, data, { 
        headers: { 'Content-Type': 'application/json' },
        maxBodyLength: Infinity,
      })
      .then((res) => {
        if(res.data.error === true) {
          setIsError(res.data.errorMessage);
          setUsername('');
          setName('');
          setDesignation('');
          setPassword('');
          setCaptcha('');
        }else{
          if(res.data.username){
            setSignupRes(res.data.username)
            console.log(`Account Created: ${res.data.username}... Redirecting...`);

            let init = 5;
            setInterval(function(){
              init--
              setRedirectCtr(init);
              if(init === 0){
                window.location.href = '/auth';
              }
            },1000);
          }
        }
        setInprogress(false);
      })
      .catch((e) => {
        setInprogress(false);
        console.log(e);
      });
    }
  }, [username, name, designation, password, captcha]);

  return (
    <Stack direction="column" spacing={2} justifyContent="center" alignItems="center" minHeight="70vh" maxWidth="43vh" sx={{margin: "0 auto"}}> 
      <FormControl fullWidth> 
        <Box component="form" sx={{ '& .MuiTextField-root': { m: 1, width: '25ch' }, minHeight: "50px" }} noValidate autoComplete="off">
          <img style={{width: '190px', margin: '0px 0px -30px 115px'}} src={signUpImage} alt="Something went wrong icons created by andinur - Flaticon" />
        </Box>
      </FormControl>
      { isError === '' ?
        <></>
      :
        <FormControl fullWidth>
          <Box component="form" sx={{ '& .MuiTextField-root': { m: 1, width: '25ch' }, minHeight: "50px" }} noValidate autoComplete="off">
            <Alert sx={{ minWidth: "41vh" }} severity="error">
              {isError} — <strong style={{textDecoration: "underline", cursor: "pointer"}} ><a onClick={() => handleResetClick('/signup')}>start again!</a></strong>
            </Alert>
          </Box>
        </FormControl>
      }

      <Box >
        {/* <img style={{width: '80%', margin: '0px 0px 0px 40px'}} src={LogoImage} alt="Logo Image" /> */}
        <Typography color="black" variant="h5" align="center" fontWeight={600}>Create an account</Typography>
        <Typography color="black" variant="caption" align="center" display="block">Enter your email to sign up for this app</Typography>
        <Typography color="black" variant="caption" align="center" display="block"> 
          Already have account? <Link component={RouterLink} to={"/auth"} color="secondary">Login</Link>
        </Typography>
      </Box>
      
      { isInprogress ? 
        <Box sx={{ margin: '10px 10px 10px 2px', minWidth: "41vh" }}>
          <Skeleton />
          <Skeleton animation="wave" />
          <Skeleton animation={false} />
        </Box>
      :
        signupRes !== '' ? 
          <FormControl fullWidth>
            <Box component="form" sx={{ '& .MuiTextField-root': { m: 1, width: '25ch' }, minHeight: "50px" }} noValidate autoComplete="off">
              <Alert sx={{ minWidth: "41vh", margin: '5px 0px 0px 0px' }} severity="info">
                {signupRes} account succesfuly created. redirecting in {redirectCtr}s...
              </Alert>
            </Box>
          </FormControl>
        :
        <>
          <TextField disabled={username === '' ? false : true} type="email" label="Email" placeholder="email@domain.com" fullWidth size='small' inputRef={usernameTextInput} />
          { username === '' ?
              <Button variant="contained" fullWidth onClick={() => handleSetCredentialClick('username')} sx={{ textTransform: 'none' }} color="secondary">Sign up with email</Button> 
            :
              <>
                <TextField type="text" label="Name" placeholder="Juan Dela Cruz" fullWidth size='small' inputRef={nameTextInput} />
                <TextField type="text" label="Designation" placeholder="DevOps Engineer" fullWidth size='small' inputRef={designationTextInput} />
                <TextField type="password" label="Password" fullWidth size='small' inputRef={passwordTextInput} />
                <TextField type="password" label="Confirm Password" fullWidth size='small' inputRef={passwordTextCnfrmInput} />
                <Box width="100%">
                  <ReCAPTCHA sitekey={process.env.REACT_APP_CAPTCHA_SITE_KEY} onChange={handleCaptchaChange}/>
                </Box>
                <Button variant="contained" fullWidth onClick={() => handleSetCredentialClick('password')} sx={{ textTransform: 'none' }} color="secondary">Sign up with email</Button>
              </>
          }
        </>
      }
      <Typography color="black" align="center" sx={{fontSize: '10px'}}> 
        By continuing, you agree to our <Link component={RouterLink} to={"/terms-of-service"} color="secondary" >Terms of Service</Link> and <Link component={RouterLink} to={"/privacy-policy"} color="secondary">Privacy Policy</Link>
      </Typography>
    </Stack>
  )
}

export default SignupPage;