import React, { useEffect } from 'react';
import Alert from '@mui/material/Alert';
import FormControl from '@mui/material/FormControl';
import Box from '@mui/material/Box';
import { Typography } from "@mui/material";
import Stack from '@mui/material/Stack';
import ErrorImage from '../images/error.png'

const ErrorPage = () => {
  useEffect(() => {
    window.localStorage.removeItem("Authorization");
    console.log(`Error Occured`);
  });

  return (
    <Stack direction="column" spacing={2} justifyContent="center" alignItems="center" minHeight="70vh" maxWidth="43vh" sx={{margin: "0 auto"}}> 
      <FormControl fullWidth>
        <Box component="form" sx={{ '& .MuiTextField-root': { m: 1, width: '25ch' }, minHeight: "50px" }} noValidate autoComplete="off">
          <img style={{width: '190px', margin: '0px 0px 20px 115px'}} src={ErrorImage} alt="Something went wrong icons created by andinur - Flaticon" />
          <Alert variant="outlined" severity="error" sx={{margin: '0px 0px 0px 0px'}}>
            Something went wrong — <strong style={{textDecoration: "underline", cursor: "pointer"}} ><a onClick={() => window.location.href = '/welcome'}>go back</a></strong>
          </Alert>
        </Box>
      </FormControl>
      <Typography sx={{textAlign: 'center', margin: '10px 0px 0px 0px!important'}} variant="caption" display="block" gutterBottom>
        edcelvista.com &copy; {new Date().getFullYear()} All rights reserved &bull; v{localStorage.getItem('appVersion')}
      </Typography>
    </Stack>
  )
};

export default ErrorPage;