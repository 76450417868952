import React, { useState, useEffect, useRef } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import FormControl from '@mui/material/FormControl';
import Alert from '@mui/material/Alert';
import Skeleton from '@mui/material/Skeleton';
import Grid from '@mui/material/Grid';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Button from '@mui/material/Button';
import EditIcon from '@mui/icons-material/Edit';
import CloseIcon from '@mui/icons-material/Close';
import TextField from '@mui/material/TextField';
import SendIcon from '@mui/icons-material/Send';
import Stack from '@mui/material/Stack';
import Modal from '@mui/material/Modal';
import VideoRecorder from './_VideoRecorder';
import PlayCircleIcon from '@mui/icons-material/PlayCircle';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import socketIO from 'socket.io-client';
import axios from "axios";

const IntervieweePage = (props) => {
  const params                                      = useParams();
  const navigate                                    = useNavigate();
  const [isError, setIsError]                       = useState('');
  const [loggedUser, setLoggedUser]                 = useState("");
  const [isInprogress, setInprogress]               = useState(true);
  // auth
  const [auth, setAuth]                             = useState(false);
  const [authLoading, setAuthLoading]               = useState(true);
  const [socket, setSocket]                         = useState(null);
  const [isSocketConnected, setIsSocketConnected]   = useState(false);
  
  // mobile viewport detection
  const [width, setWidth] = useState(window.innerWidth);

  // page data
  const inputRefs                                   = useRef([]);
  const [inputCurEditIndex, setInputCurEditIndex]   = useState(null);
  const [inputCurEditText, setInputCurEditText]     = useState([]);
  const [interviewMetadata, setInterviewMetadata]   = useState({});
  const [interviewData, setInterviewData]           = useState([]);
  const [response, setResponse]                     = useState(null);
  const [isPublished, setIsPublished]               = useState(false);
  const [mediaBlogs, setMediaBlogs]                 = useState({});
  const [videoPlayerModalIndex, setVideoPlayerModalIndex] = useState(null);
  const [switchTabCtr, setSwitchTabCtr]             = useState(0);

  const handleWindowSizeChange = () => {
    setWidth(window.innerWidth);
  }

  useEffect(() => {
    window.addEventListener('resize', handleWindowSizeChange);
    return () => {
      window.removeEventListener('resize', handleWindowSizeChange);
    }
  }, []);
  
  const isMobile = width <= 768;
  console.log(`Mobile Veiwport: ${isMobile}`);

  const removeCookie = (name, path = '/') => {
    var domain = process.env.REACT_APP_ROOT_DOMAIN;
    const cookieString = `${encodeURIComponent(name)}=; expires=Thu, 01 Jan 1970 00:00:00 GMT; Secure=; path=${path}`;
    if (domain) {
        document.cookie = `${cookieString}; domain=${domain}`;
    } else {
        document.cookie = cookieString;
    }
  };

  useEffect(() => {
    setAuthLoading(false);
    props.authState.then(function(authData){
      if(authData){
        setAuth(true);
        console.log(`Auth Connection Status: true | ${JSON.stringify(authData.data.decodedJwtToken.decodedJwtToken.INTERVIEW_USERDATA_username)}`);
        let __createdtime__ = Date.now();
        var jwtUserDetails = atob(window.localStorage.getItem("Authorization").split(".")[1]);
        setLoggedUser(JSON.parse(jwtUserDetails).INTERVIEW_USERDATA_username);

        // init inputCurEditText index
        var initInputCurEditText = [];
        for (let i = 0; i < interviewData.length; i++) {
          initInputCurEditText[i] = ""
        }
        setInputCurEditText(initInputCurEditText);

        // page data init
        const signingKey = params.signingKey;
        axios.get(`${process.env.REACT_APP_REST_API_URL}/interviewTemplate/${params.INTERVIEW_TEMPLATE_id}/${params.INTERVIEW_METADATA_id}`, { 
          headers: { 'Content-Type': 'application/json', authtoken: window.localStorage.getItem("Authorization"), '__createdtime__': __createdtime__, signingkey: signingKey },
          maxBodyLength: Infinity,
        })
        .then((res) => {
          if(res.data.error === true) {
            setIsError(res.data.errorMessage === 'Interview already Expired.' ? '' : res.data.errorMessage);
            console.log(res.data.errorMessage);
            if(res.data.errorMessage === 'Interview already answered.'){
              setIsError('');
              setInterviewMetadata({});
              setInterviewData([]);
              setIsPublished(true);
            }
          }else{
            if(res.data){
              const signingKeyDecode = atob(params.signingKey.split(".")[1]);
              if(JSON.parse(JSON.parse(signingKeyDecode).interviewee).email === authData.data.decodedJwtToken.decodedJwtToken.INTERVIEW_USERDATA_username){
                setIsError('');

                const inviteExpirationTmp = JSON.parse(signingKeyDecode).exp;
                const inviteExpiration = new Date(parseInt(inviteExpirationTmp * 1000)).toLocaleString("en-US");
                const isExpired        = (inviteExpirationTmp * 1000 - Date.now() > 0) ? false : true;
                const hrsLeft          = (inviteExpirationTmp * 1000 - Date.now()) / (1000 * 60 * 60);
         
                setInterviewMetadata({
                  interviewer: JSON.parse(res.data.Item.INTERVIEW_METADATA_interviewer.S),
                  interviewee: JSON.parse(JSON.parse(signingKeyDecode).interviewee),
                  status: JSON.parse(signingKeyDecode).INTERVIEW_METADATA_status,
                  createdDate: JSON.parse(signingKeyDecode).INTERVIEW_METADATA_created_date,
                  inviteExpiration: inviteExpiration,
                  hrsLeft: hrsLeft
                });
                setInterviewData(JSON.parse(res.data.Item.INTERVIEW_TEMPLATE_json.S));

                // detects if browser tab is in the view
                const onVisibilityChange = () => {
                  let wasHidden = document.hidden ? setSwitchTabCtr(prevCount => prevCount + 1) : false;
                  return wasHidden;
                };
                document.addEventListener("visibilitychange", onVisibilityChange);

                const socketInit = socketIO.connect(process.env.REACT_APP_SOCKET_API_URL,
                  { transports: ["websocket"], query: `token=${window.localStorage.getItem("Authorization")}`, extraHeaders: { Authorization: `Bearer ${window.localStorage.getItem("Authorization")}` } }
                );
                setSocket(socketInit);
              }else{
                setIsError('Invalid Signing Key. Not Authorized!');
              }
            }else{
              setIsError('Unable to fetched interview list.');
            }
          }
          setInprogress(false);
        })
        .catch((e) => {
          setInprogress(false);
          console.log(e);
        });
      }else{
        setIsError("Unathorized!");
        console.log(`Auth Connection Status: false | Error`);
        window.localStorage.removeItem("Authorization");
        window.localStorage.removeItem("ssoProvider");
        removeCookie("Authorization");
        removeCookie("ssoProvider");
        window.location.href = '/auth';
      }
    }).catch(function(err){
      setIsError("Unathorized!");
      console.log(`Auth Connection Status: false | ${JSON.stringify(err)}`);
      window.localStorage.removeItem("Authorization");
      window.localStorage.removeItem("ssoProvider");
      removeCookie("Authorization");
      removeCookie("ssoProvider");
      window.location.href = '/auth';
    });
  }, []);

  useEffect(() => {
    if(socket !== null){
      setTimeout(function(){
        setIsSocketConnected(socket.connected);
      },1000);

      socket.on('unauthorized', (error, callback) => {
        if (error.data.type == 'UnauthorizedError' || error.data.code == 'invalid_token') {
          // redirect user to login page perhaps?
          callback();
          console.log('User token has expired');
        }
      });

      socket.emit('ping', (data) => {
        console.log(`Ping: ${JSON.stringify(data)}`);
      });

      socket.on('pong', (data) => {
        console.log(`Pong: ${JSON.stringify(data)}`);
      });

      let event = {'INTERVIEW_METADATA_id': params.INTERVIEW_METADATA_id, 'INTERVIEW_USERDATA_interviewee_username': loggedUser};
      socket.emit('interviewEventView', event);
      console.log(`Event Recorded!`);
    }
  }, [socket]);

  useEffect(()=> {
    if(switchTabCtr > 0){
      let switchData = {'INTERVIEW_METADATA_id': params.INTERVIEW_METADATA_id, 'INTERVIEW_USERDATA_interviewee_username': loggedUser, 'INTERVIEW_METADATA_switchTabs': switchTabCtr};
      socket.emit('switchTabsCounter', switchData);
      console.log(`Detected Tab Switched!!!`);
      // console.log(`Disable emit`);
    }
  }, [switchTabCtr])

  const handleResetClick = (path) => {
    window.location.pathname === path ? window.location.reload() : navigate(path)
  };

  const toggleAnswerType = (action, type, index) => {
    switch (type.toLowerCase()) {
      case 'text':
        if (action === 'create'){
          let __createdtime__ = Date.now();
          let curInputElem = inputRefs[index];
          let curTextVal   = curInputElem.value
          let curData      = interviewData[index];
          curData.a        = curTextVal;
          curData.__createdtime__ = __createdtime__;
          setInterviewData(prevData => prevData.map((item, i) => 
            i === index ? curData : item
          ));
          setInputCurEditIndex(null);
        }else{
          let curData = interviewData[index];
          setInputCurEditIndex(index);
          setInputCurEditText(prevData => prevData.map((item, i) => 
            i === index ? curData.a : item
          ));
        }
        break;
      default:
        break;
    }
  };

  const resetCurEdit = () => {
    setInputCurEditIndex(null);
  };

  const handleSubmitResponse = () => {
    if (interviewData.every(obj => "a" in obj)) { //every() some()
      setIsError('');
      setInprogress(true);
      const data = new FormData();
      data.append('INTERVIEW_METADATA_response_json', JSON.stringify(interviewData));
      data.append('INTERVIEW_METADATA_interviewer', JSON.stringify(interviewMetadata.interviewer));
      data.append('INTERVIEW_METADATA_interviewee', JSON.stringify(interviewMetadata.interviewee));
      data.append('INTERVIEW_METADATA_status', "PUBLISHED");
      data.append('INTERVIEW_METADATA_id', params.INTERVIEW_METADATA_id);

      let fileIndexes = [];
      for(const key in mediaBlogs){
        if (mediaBlogs.hasOwnProperty(key)) {
          data.append(key, mediaBlogs[key].blob, `${key}-video.webm`); // 'video' is the field name, and 'video.mp4' is the filename
          fileIndexes.push(key);
        }
      }

      data.append('fileIndexes', fileIndexes);

      let __createdtime__ = Date.now();
      axios.post(`${process.env.REACT_APP_REST_API_URL}/interview/response`, data, { 
        headers: { 'Content-Type': 'multipart/form-data', authtoken: window.localStorage.getItem("Authorization"), '__createdtime__': __createdtime__ },
        maxBodyLength: Infinity,
      })
      .then((res) => {
        if(res.data.error === true) {
          setIsError(res.data.errorMessage === 'Interview already Expired.' ? '' : res.data.errorMessage);
        }else{
          if(res.data.responseId){
            setIsError('');
            setResponse(`Response Submitted!`);
            console.log(`Response Submitted: ${res.data.responseId}...`);
          }else{
            setIsError('Response ID not generated.');
          }
        }
        setInprogress(false);
      })
      .catch((e) => {
        setInprogress(false);
        console.log(e);
      });
    } else {
      const indices = interviewData.map((obj, index) => !("a" in obj) ? index + 1 : -1).filter(index => index !== -1);
      setIsError(`No Response Detected! Item # ${indices}`);
    }
  };

  const handleMediaCapture = (media) => {
    // [ansIndex, videoUrl, videoBlob]
    let index = media[0];
    if(media[1] && media[2])
      setMediaBlogs(prevItems => ({ ...prevItems, [index]: { "blobUrl":  media[1], "blob": media[2] }  }) );

    let __createdtime__ = Date.now();
    let curData      = interviewData[index];
    curData.a        = index;
    curData.__createdtime__ = __createdtime__;
    setInterviewData(prevData => prevData.map((item, i) => 
      i === index ? curData : item
    ));
  };

  return (
    <>
      <Grid container spacing={1} direction="column" alignItems="center" sx={{margin: "15px 0px 0px 0px"}}> 
        <Grid sx={{minWidth: isMobile ? "100%!important" : "80%!important", maxWidth: isMobile ? "100%!important" : "80%!important"}}>

          { isError === '' ?
            <></>
          :
            <FormControl fullWidth>
              <Box noValidate autoComplete="off">
                <Alert sx={{ margin: "10px", width: isMobile ? '93%' : '98%' }} severity="error">
                  {isError} — <strong style={{textDecoration: "underline", cursor: "pointer"}} ><a onClick={() => handleResetClick(isError === "Unathorized!"? "/auth" : "/welcome")}>start again!</a></strong>
                </Alert>
              </Box>
            </FormControl>
          }

          { auth === true && !isError.includes('Invalid Signing Key.') ? 
            <FormControl fullWidth sx={{margin: "0px 0px 0px 0px"}}>
              <Box sx={{ padding: '0px 30px 0px 20px' }} noValidate autoComplete="off">
                <Typography variant="h4" gutterBottom> Interview Questionaire </Typography> 
                { isInprogress ? 
                    <Box sx={{margin: '10px 0px 10px 0px', minWidth: "41vh"}}>
                      <Skeleton />
                      <Skeleton animation="wave" />
                      <Skeleton animation={false} />
                    </Box>
                  :
                    !isPublished ? 
                      Object.keys(interviewData).length <= 0 || Object.keys(interviewMetadata).length <= 0 ?
                        <>
                          <Alert sx={{margin: '10px 0px 10px 0px', minWidth: "41vh"}} severity="warning">
                            <div>No Content Available or Inverview Invite already expired.</div>
                          </Alert>
                          <Button onClick={() => navigate('/welcome')} sx={{ textTransform: 'none', float: 'right' }} color="secondary">GO BACK</Button>
                        </>
                      :
                        <Card sx={{ minWidth: 275, margin: '10px 0px 15px 0px', backgroundColor: '#fbfbfb', borderRadius: '0px' }}>
                          <CardContent>
                            <Typography gutterBottom sx={{ color: 'text.secondary', fontSize: 14 }}>
                              {interviewMetadata.interviewee.designation}
                            </Typography>
                            <Typography variant="h5" component="div">
                              {interviewMetadata.interviewee.name}
                            </Typography>
                            {response ? 
                              <Typography sx={{ color: 'text.secondary', mb: 1.5, color: 'green', fontWeight: '800' }}>SUBMITTED</Typography>
                            :
                              <Typography sx={{ color: 'text.secondary', mb: 1.5, color: 'orange', fontWeight: '800' }}>{interviewMetadata.status} - {interviewMetadata.inviteExpiration} - {Math.floor(interviewMetadata.hrsLeft)}hrs left</Typography>
                            }
                            <Typography variant="body2">
                              <em>Prepared by {interviewMetadata.interviewer.designation} - {interviewMetadata.interviewer.name}. </em>
                              <br/>
                              <em>contains {interviewData.length} questions.</em>
                            </Typography>
                          </CardContent>
                        </Card>
                      :
                    <></>
                }
              </Box>
              {isPublished ? 
                <>
                  <FormControl fullWidth>
                    <Box sx={{ padding: '0px 30px 0px 20px' }} noValidate autoComplete="off">
                      <Alert sx={{margin: '10px 0px 10px 0px', minWidth: "41vh"}} severity="success">
                        <div>Interview already published.</div>
                      </Alert>
                      <Button onClick={() => navigate('/welcome')} sx={{ textTransform: 'none', float: 'right' }} color="secondary">GO BACK</Button>
                    </Box>
                  </FormControl>
                </>
              :
                <Box sx={{ padding: '0px 30px 0px 20px' }} noValidate autoComplete="off">
                  {Object.keys(interviewData).length > 0 ? <Typography variant="button" sx={{color: '#808080'}} gutterBottom> Your Responses </Typography> : <></>}
                    { isInprogress ? 
                      <Box sx={{margin: '10px 0px 10px 0px', minWidth: "41vh"}}>
                        <Skeleton />
                        <Skeleton animation="wave" />
                        <Skeleton animation={false} />
                      </Box>
                    :
                      <>
                        { response ? 
                          <>
                            <Alert sx={{margin: '10px 0px 10px 0px', minWidth: "41vh"}} severity="info">
                              <div>{response}</div>
                            </Alert>
                            <FormControl fullWidth>
                              <Box sx={{ minHeight: "50px" }} noValidate autoComplete="off">
                                <Button onClick={() => navigate('/welcome')} sx={{ textTransform: 'none', float: 'right' }} color="secondary">Done</Button>
                              </Box>
                            </FormControl>
                          </>
                        :
                          <Box sx={{margin: '10px 0px 10px 0px', minWidth: "41vh"}}>
                            {interviewData.map(function(perQa, index){
                              const q         = perQa.q;
                              const type      = perQa.type;
                              return(
                                <div key={index} >
                                  <div style={{ minWidth: 275, margin: '10px 0px 15px 0px' }}>
                                    <Accordion sx={{margin: '10px 0px 15px 0px'}}>
                                      <AccordionSummary 
                                        onClick={(e) => resetCurEdit()}
                                        aria-controls={ "panel" + index + 1 + "-content" }
                                        id={ "panel" + index + 1 + "-header" }
                                        sx={{fontWeight: '450', fontSize: '13px', backgroundColor: '#222222', color: 'white' }}
                                        expandIcon={<ExpandMoreIcon sx={{color: 'white'}} />}>
                                        {index + 1}. {q} {"a" in interviewData[index] ? <CheckCircleOutlineIcon sx={{margin: '-2px 0px 0px 5px'}}/> : <></>}
                                      </AccordionSummary>
                                      <AccordionDetails sx={{ fontSize: '13px', backgroundColor: '#f7f7f7' }}>
                                        {
                                          type.toLowerCase() === 'text' ?
                                            <Box sx={{ maxWidth: '100%', margin: '10px 0px 0px 0px', display: 'flex', justifyContent: 'flex-end'}}>
                                              { perQa.a && inputCurEditIndex !== index ? 
                                                <>
                                                  <AccordionDetails  sx={{ width: '100%', fontSize: '13px', backgroundColor: '#f7f7f7', whiteSpace: 'pre-wrap' }}>
                                                    {perQa.a}
                                                  </AccordionDetails>
                                                  <Button size="small" onClick={(e) => toggleAnswerType('edit', 'text', index)} sx={{ margin: '6px 0px 0px 0px', maxHeight: '40px' }} startIcon={<EditIcon />}>Edit</Button>
                                                </>
                                              :
                                                <>
                                                  <TextField InputProps={{style: { fontSize: '16px'}}} InputLabelProps={{ style: { fontSize: '16px' }}} sx={{ width: '98%!important', maxWidth: '100%'}} fullWidth multiline label="Text Answer" id={ "ans" + index + 1 } inputRef={(el) => (inputRefs[index] = el)} onChange={(e) => { setInputCurEditText(prevData => prevData.map((item, i) => i === index ? e.target.value : item))}} value={inputCurEditText[index] !== "" ? inputCurEditText[index] : ""} />
                                                  <Button size="small" onClick={(e) => toggleAnswerType('create', 'text', index)} sx={{ margin: '9px 0px 0px 10px', maxHeight: '40px' }} startIcon={<SendIcon />}>Save</Button>
                                                  {
                                                    perQa.a ?
                                                      <Button size="small" onClick={() => setInputCurEditIndex(null)} sx={{ margin: '8px 0px 0px 9px', maxHeight: '40px' }} startIcon={<CloseIcon />}>Cancel</Button>
                                                    :
                                                      <></>
                                                  }
                                                </>
                                              }
                                            </Box>
                                          :
                                          <Box sx={{ maxWidth: '100%', margin: '10px 0px 0px 0px', display: 'flex', justifyContent: 'flex-end'}}>
                                            {Object.keys(mediaBlogs).length > 0 ? 
                                                mediaBlogs[index] ? 
                                                  <>
                                                    <Typography variant="caption" gutterBottom sx={{ color: "green", fontWeight: '800', width: '90%!important', maxWidth: '100%', margin: '12px 24px 0px 0px'}} >
                                                      YOUR VIDEO RECORDING HAS BEEN CAPTURED
                                                    </Typography>
                                                    <Modal open={videoPlayerModalIndex === index} onClose={(event, reason) => {
                                                        // Prevent closing the modal if the reason is 'backdropClick'
                                                        if (reason !== 'backdropClick') {
                                                            setVideoPlayerModalIndex(null);
                                                        }
                                                        }} BackdropProps={{
                                                            // Disable clicks on the backdrop to prevent closing the modal
                                                            onClick: (event) => event.stopPropagation(),
                                                        }} aria-labelledby="modal-modal-title" aria-describedby="modal-modal-description">
                                                        <Box sx={{ position: 'absolute', top: '50%', left: '50%', transform: 'translate(-50%, -50%)', bgcolor: 'background.paper', border: '2px solid #000', boxShadow: 24, p: 4 }}>
                                                          <video style={{width: '60vw'}} src={mediaBlogs[index].blobUrl} controls></video>
                                                          <Button onClick={() => {setVideoPlayerModalIndex(null)}} sx={{ margin: '5px 0px 0px 0px', float: 'right' }}  startIcon={<CloseIcon sx={{color: 'red'}} />}>Close</Button>
                                                          <a style={{float: 'left', margin: '5px 0px 0px 0px', fontSize: '10px' }} download href={mediaBlogs[index].blobUrl}> Download Recording </a>
                                                        </Box>
                                                    </Modal>
                                                    <Button onClick={() => {setVideoPlayerModalIndex(index)}} sx={{ margin: '5px 0px 0px 0px', float: 'right', fontSize: '10px' }}  startIcon={<PlayCircleIcon sx={{color: 'red'}} />}>Replay</Button>
                                                    <VideoRecorder media={handleMediaCapture} ansIndex={index} isEdit={true}/>
                                                  </>
                                                : <>
                                                    <Typography variant="caption" gutterBottom sx={{ width: '90%!important', maxWidth: '100%', margin: '12px 24px 0px 0px'}} >
                                                      <em>Record a video as your answer. Prepare a silent and organized environment during the video recording to avoid invalidity of the video answer.</em>
                                                    </Typography>
                                                    <VideoRecorder media={handleMediaCapture} ansIndex={index} isEdit={false}/>
                                                  </>
                                              :
                                              <>
                                                <Typography variant="caption" gutterBottom sx={{ width: '90%!important', maxWidth: '100%', margin: '12px 24px 0px 0px'}} >
                                                  <em>Record a video as your answer. Prepare a silent and organized environment during the video recording to avoid invalidity of the video answer.</em>
                                                </Typography>
                                                <VideoRecorder media={handleMediaCapture} ansIndex={index} isEdit={false}/>
                                              </>
                                            }
                                          </Box>
                                        }
                                      </AccordionDetails>
                                    </Accordion>
                                  </div>
                                </div>
                              )
                            })}
                            {Object.keys(interviewData).length > 0 ?
                              <Stack direction="row" spacing={2} sx={{ float: 'right' }}>
                                <Button onClick={()=>navigate("/welcome")} sx={{ margin: '5px 0px 0px 0px'}} color="secondary">Cancel</Button>
                                <Button onClick={() => {handleSubmitResponse()}} sx={{ margin: '5px 0px 0px 0px'}} variant="contained" color="secondary">
                                  Submit
                                </Button>
                              </Stack>
                              : <></>
                            }
                          </Box>
                        }
                      </>
                    }
                    
                    {/* { 
                      isMobile ?
                        <></>
                      : 
                        <></>
                    } */}
                </Box>
              }
            </FormControl>
          : 
            <></>
          }
        </Grid>
      </Grid>
    </>
  )
}

export default IntervieweePage;