import React, { useState, useEffect, useRef } from 'react';
import { useParams } from 'react-router-dom';
import dayjs from 'dayjs';
import { useNavigate } from 'react-router-dom';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import FormControl from '@mui/material/FormControl';
import Alert from '@mui/material/Alert';
import Skeleton from '@mui/material/Skeleton';
import Grid from '@mui/material/Grid';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Stack from '@mui/material/Stack';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import InputLabel from '@mui/material/InputLabel';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import ForbiddenImage from '../images/error.png'
import axios from "axios";

const InvitePage = (props) => {
  const { date }                                    = useParams();
  const navigate                                    = useNavigate();
  const [isError, setIsError]                       = useState('');
  const [loggedUser, setLoggedUser]                 = useState("");
  const [isInprogress, setInprogress]               = useState(true);
  const [isInprogressM, setInprogressM]             = useState(true);
  const [tier, setTier]                             = useState(1);
  // auth
  const [auth, setAuth]                             = useState(false);
  const [authLoading, setAuthLoading]               = useState(true);

  // page data
  const [templates, setTemplates]                   = useState([]);
  const nameTextInput                               = useRef(null);
  const designationTextInput                        = useRef(null);
  const emailTextInput                              = useRef(null);
  const [name, setName]                             = useState('');
  const [designation, setDesignation]               = useState('');
  const [interviewDate, setInterviewDate]           = useState(date ? dayjs(date).add(23, 'hour') : dayjs());
  const [email, setEmail]                           = useState('');
  const [selectedTemplate, setSelectedTemplate]     = useState('');
  const [response, setResponse]                     = useState(null);
  const [credits, setCredits]                       = useState(0);
  // mobile viewport detection
  const [width, setWidth]                           = useState(window.innerWidth);

  const handleWindowSizeChange = () => {
    setWidth(window.innerWidth);
  }

  useEffect(() => {
    window.addEventListener('resize', handleWindowSizeChange);
    return () => {
      window.removeEventListener('resize', handleWindowSizeChange);
    }
  }, []);
  
  const isMobile = width <= 768;
  console.log(`Mobile Veiwport: ${isMobile}`);

  const removeCookie = (name, path = '/') => {
    var domain = process.env.REACT_APP_ROOT_DOMAIN;
    const cookieString = `${encodeURIComponent(name)}=; expires=Thu, 01 Jan 1970 00:00:00 GMT; Secure=; path=${path}`;
    if (domain) {
        document.cookie = `${cookieString}; domain=${domain}`;
    } else {
        document.cookie = cookieString;
    }
  };

  useEffect(() => {
    setAuthLoading(false);
    props.authState.then(function(authData){
      if(authData){
        setAuth(true);
        console.log(`Auth Connection Status: true | ${JSON.stringify(authData.data.decodedJwtToken.decodedJwtToken.INTERVIEW_USERDATA_username)}`);
        var jwtUserDetails = atob(window.localStorage.getItem("Authorization").split(".")[1]);
        setLoggedUser(JSON.parse(jwtUserDetails).INTERVIEW_USERDATA_username);
        setTier(JSON.parse(jwtUserDetails).INTERVIEW_USERDATA_tier);
        setCredits(JSON.parse(jwtUserDetails).INTERVIEW_USERDATA_credits);
        setInprogress(false);
      }else{
        setIsError("Unathorized!");
        setInprogress(false);
        console.log(`Auth Connection Status: false | Error`);
        window.localStorage.removeItem("Authorization");
        window.localStorage.removeItem("ssoProvider");
        removeCookie("Authorization");
        removeCookie("ssoProvider");
        window.location.href = '/auth';
      }
    }).catch(function(err){
      setIsError("Unathorized!");
      setInprogress(false);
      console.log(`Auth Connection Status: false | ${JSON.stringify(err)}`);
      window.localStorage.removeItem("Authorization");
      window.localStorage.removeItem("ssoProvider");
      removeCookie("Authorization");
      removeCookie("ssoProvider");
      window.location.href = '/auth';
    });
  }, []);

  useEffect(()=> {
    if(name !== ''){
      // page data init
      let __createdtime__ = Date.now();
      axios.get(`${process.env.REACT_APP_REST_API_URL}/interviewTemplate`, { 
        headers: { 'Content-Type': 'application/json', authtoken: window.localStorage.getItem("Authorization"), '__createdtime__': __createdtime__ },
        maxBodyLength: Infinity,
      })
      .then((res) => {
        if(res.data.error === true) {
          setIsError(res.data.errorMessage);
        }else{
          if(res.data){
            setIsError('')
            setTemplates(res.data.Items);
          }else{
            setIsError('Unable to fetched templates list.');
          }
        }
        setInprogressM(false);
      })
      .catch((e) => {
        setIsError(e.message);
        setInprogressM(false);
        console.log(e);
      });
    }
  }, [name]);

  const handleResetClick = (path) => {
    window.location.pathname === path ? window.location.reload() : navigate(path)
  };

  const handleSetCredentialClick = (type) => {
    let regex = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]+$/i;
    switch (type) {
      case 'name':
        if(nameTextInput.current.value.length > 2){
          setIsError('');
          setName(nameTextInput.current.value);
        }else{
          setIsError("Invalid Name!");
        }
        break;
      case 'designation-email':
        if(emailTextInput.current.value.length > 0){
          if (!regex.test(emailTextInput.current.value.replace(/\s/g, ''))) {
            setIsError("Invalid Email!");
          }else{
            setIsError("");
            setEmail(emailTextInput.current.value);
            if(designationTextInput.current.value.length > 2){
              setIsError('');
              setDesignation(designationTextInput.current.value);
            }else{
              setIsError("Invalid Designation!");
            }
          }
        }
        break;
      default:
        setIsError('Unknown Credentials');
        break;
    }
  };

  const handleTemplateChange = (event) => {
    setSelectedTemplate(event.target.value);
  };

  useEffect(() => {
   if(name !=='' && designation !== '' && email !== ''){
    setInprogress(true);
    var jwtUserDetails  = atob(window.localStorage.getItem("Authorization").split(".")[1]);
    let __createdtime__ = Date.now();
    const data = new FormData();
    data.append('INTERVIEW_TEMPLATE_id', selectedTemplate);
    data.append('INTERVIEW_METADATA_interviewer', `{"name":"${JSON.parse(jwtUserDetails).INTERVIEW_USERDATA_name}","designation":"${JSON.parse(jwtUserDetails).INTERVIEW_USERDATA_designation}", "email": "${JSON.parse(jwtUserDetails).INTERVIEW_USERDATA_username}"}`);
    data.append('INTERVIEW_METADATA_interviewee', `{"name":"${name}","designation":"${designation}","email": "${email}"}`);
    data.append('interviewDate', interviewDate.valueOf());

    axios.post(`${process.env.REACT_APP_REST_API_URL}/interview/generate_invite_link`, data, { 
      headers: { 'Content-Type': 'application/x-www-form-urlencoded', authtoken: window.localStorage.getItem("Authorization"), '__createdtime__': __createdtime__ },
      maxBodyLength: Infinity,
    })
    .then((res) => {
      if(res.data.error === true) {
        setIsError(res.data.errorMessage);
      }else{
        if(res.data){
          setIsError('')
          setResponse(res.data);
        }else{
          setIsError(`Unable to invite ${name} - ${email}.`);
          setName('');
          setDesignation('');
          setEmail('');
        }
      }
      setInprogress(false);
    })
    .catch((e) => {
      setInprogress(false);
      console.log(e);
    });
   }
  }, [name, designation, email]);

  return (
    <>
      <Grid container spacing={1} direction="column" alignItems="center" sx={{margin: "15px 0px 0px 0px"}}> 
        <Grid sx={{minWidth: isMobile ? "100%!important" : "80%!important", maxWidth: isMobile ? "100%!important" : "80%!important"}}>
          { auth === true && parseInt(tier) === 0? 
            <FormControl fullWidth sx={{margin: "0px 0px 0px 0px"}}>
              <Box sx={{ '& .MuiTextField-root': { m: 1 }, padding: '0px 30px 0px 20px' }} noValidate autoComplete="off">
                <Stack direction="column" spacing={2} justifyContent="center" alignItems="center" minHeight="70vh" maxWidth="43vh" sx={{margin: "0 auto"}}> 
                  <Typography variant="h6" color="black" align="center"> 
                    Invite Interviewee
                  </Typography>
                  { isInprogress ? 
                    <Box sx={{margin: '10px 0px 10px 0px', minWidth: "41vh"}}>
                      <Skeleton />
                      <Skeleton animation="wave" />
                      <Skeleton animation={false} />
                    </Box>
                  :
                    response ? 
                      <>
                        <FormControl fullWidth>
                          <Box component="form" sx={{ minHeight: "50px" }} noValidate autoComplete="off">
                            <Alert sx={{ minWidth: "100%" }} severity="success">
                              Invite sent to {JSON.parse(response.interviewee).name} — <strong style={{textDecoration: "underline", cursor: "pointer"}} >{JSON.parse(response.interviewee).email}</strong>
                            </Alert>
                          </Box>
                        </FormControl>
                        <FormControl fullWidth>
                          <Box component="form" sx={{ minHeight: "50px" }} noValidate autoComplete="off">
                            <Button onClick={() => navigate('/welcome')} sx={{ textTransform: 'none', float: 'right' }} color="secondary">Done</Button>
                          </Box>
                        </FormControl>
                      </>
                    :
                      <>
                        { isError === '' ?
                          <></>
                        :
                          <FormControl fullWidth>
                            <Box component="form" sx={{ minHeight: "50px" }} noValidate autoComplete="off">
                              <Alert sx={{ minWidth: "100%" }} severity="error">
                                {isError} — <strong style={{textDecoration: "underline", cursor: "pointer"}} ><a onClick={() => handleResetClick(isError === "Unathorized!"? "/auth" : "/invite")}>start again!</a></strong>
                              </Alert>
                            </Box>
                          </FormControl>
                        }
                        <Typography variant="caption" color="black" align="center" sx={{margin: "0px 0px 0px 0px!important"}}> 
                          Email notification will be sent to the provided email. Make sure the name and email is correct.
                        </Typography>
                        <TextField type="name" label="Full Name" placeholder="Juan Dela Cruz" fullWidth size='small' inputRef={nameTextInput} />
                        { name === '' ?
                          <FormControl fullWidth>
                            <Box component="form" sx={{ minHeight: "50px" }} noValidate autoComplete="off">
                              {credits > 0 ?
                                <Button variant="contained" onClick={() => handleSetCredentialClick('name')} sx={{ textTransform: 'none', float: 'right' }} color="secondary">Proceed</Button> 
                              : 
                                <>
                                  <Button variant="contained" onClick={() => navigate("/payment")} sx={{ textTransform: 'none', float: 'right' }} color="secondary">No More Credits. Top up now!</Button>
                                  <span style={{ margin: '40px 0px 0px 0px', fontSize: '9px', position: 'absolute', right: '0'}}>please re-login for the credits to take effect.</span>
                                </>
                              }
                            </Box>
                          </FormControl>
                        : <>
                            <LocalizationProvider dateAdapter={AdapterDayjs}>
                              <DatePicker
                                size="small"
                                sx={{width: '100%'}}
                                label="Interview Date"
                                value={interviewDate}
                                onChange={(newValue) => {
                                  setInterviewDate(newValue);
                                }}
                                renderInput={(params) => <TextField size="small" {...params} fullWidth />}
                              />
                            </LocalizationProvider>
                            <TextField type="designation" label="Job Title" fullWidth size='small' inputRef={designationTextInput} />
                            <TextField type="email" label="Email" fullWidth size='small' inputRef={emailTextInput} />
                            {isInprogressM ? 
                              <Box sx={{margin: '10px 0px 10px 0px', minWidth: "41vh"}}>
                                <Skeleton />
                                <Skeleton animation="wave" />
                                <Skeleton animation={false} />
                              </Box>
                            :
                              <FormControl sx={{width: '100%'}}>
                                <InputLabel id="interview-template-select" sx={{margin: '-8px 0px 0px 0px'}}>Interview Template</InputLabel>
                                <Select
                                  labelId="interview-template-select"
                                  id="interview-template-select-autowidth"
                                  value={selectedTemplate}
                                  onChange={handleTemplateChange}
                                  fullWidth
                                  size='small'
                                  label="Template"
                                >
                                  {templates.map((e, i) => (
                                    <MenuItem key={e.INTERVIEW_TEMPLATE_id.S} value={e.INTERVIEW_TEMPLATE_id.S}>{e.INTERVIEW_TEMPLATE_name.S}</MenuItem>
                                  ))}
                                </Select>
                              </FormControl>
                            }
                            <FormControl fullWidth>
                              <Box component="form" sx={{ minHeight: "50px" }} noValidate autoComplete="off">
                                <Button onClick={() => handleSetCredentialClick('designation-email')} variant="contained" sx={{ textTransform: 'none', float: 'right' }} color="secondary">Invite</Button>
                                <Button onClick={() => navigate('/welcome')} sx={{ textTransform: 'none', float: 'right' }} color="secondary">Cancel</Button>
                              </Box>
                            </FormControl>
                          </>
                        }
                      </>
                  }
                </Stack>
                  {/* { 
                    isMobile ?
                      <></>
                    : 
                      <></>
                  } */}
              </Box>
            </FormControl>
          : 
            isError === '' ?
              <Stack direction="column" spacing={2} justifyContent="center" alignItems="center" minHeight="70vh" maxWidth="43vh" sx={{margin: "0 auto"}}> 
                <FormControl fullWidth>
                  <Box component="form" sx={{ '& .MuiTextField-root': { m: 1, width: '25ch' }, minHeight: "50px" }} noValidate autoComplete="off">
                    <img style={{width: '190px', margin: '0px 0px 20px 115px'}} src={ForbiddenImage} alt="Something went wrong icons created by andinur - Flaticon" />
                    <Alert variant="outlined" severity="error">
                      Unauthorized <strong style={{textDecoration: "underline", cursor: "pointer"}} ><a onClick={() => window.location.href = '/welcome'}>go back to home</a></strong>
                    </Alert>
                  </Box>
                </FormControl>
                <Typography color="black" align="center" sx={{fontSize: '10px', margin: '5px 0px 0px 0px!important'}}> 
                  If you are receiving this error with high occurence - please contact the support <a href="mailto:example@example.com">administrator@edcelvista.com</a>
                </Typography>
              </Stack>
            :
              <FormControl fullWidth>
                <Box component="form" sx={{ '& .MuiTextField-root': { m: 1, width: '25ch' }, minHeight: "50px" }} noValidate autoComplete="off">
                  <Alert sx={{ minWidth: "100%", margin: "10px" }} severity="error">
                    {isError} — <strong style={{textDecoration: "underline", cursor: "pointer"}} ><a onClick={() => handleResetClick(isError === "Unathorized!"? "/auth" : "/invite")}>start again!</a></strong>
                  </Alert>
                </Box>
              </FormControl>
          }
          </Grid>
      </Grid>
    </>
  )
}

export default InvitePage;