import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import FormControl from '@mui/material/FormControl';
import Alert from '@mui/material/Alert';
import Skeleton from '@mui/material/Skeleton';
import Grid from '@mui/material/Grid';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Stack from '@mui/material/Stack';
import IconButton from '@mui/material/IconButton';
import InputAdornment from '@mui/material/InputAdornment';
import FileUploadIcon from '@mui/icons-material/FileUpload';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import ArrowRightAltIcon from '@mui/icons-material/ArrowRightAlt';
import Modal from '@mui/material/Modal';
import CloseIcon from '@mui/icons-material/Close';
import DeleteIcon from '@mui/icons-material/Delete';
import ForbiddenImage from '../images/error.png'
import axios from "axios";
import socketIO from 'socket.io-client';
import { Divider } from '@mui/material';

const UploadTemplatePage = (props) => {
  const navigate                                    = useNavigate();
  const [isError, setIsError]                       = useState('');
  const [loggedUser, setLoggedUser]                 = useState("");
  const [isInprogress, setInprogress]               = useState(true);
  const [tier, setTier]                             = useState(1);
  // auth
  const [auth, setAuth]                             = useState(false);
  const [authLoading, setAuthLoading]               = useState(true);
  const [socket, setSocket]                         = useState(null);
  const [isSocketConnected, setIsSocketConnected]   = useState(false);

  // page data
  const [templates, setTemplates]                   = useState([]);
  const [template, setTemplate]                     = useState(false);
  const [response, setResponse]                     = useState(null);
  const [isActionConfirmationOpen, setIsActionConfirmationOpen] = useState(false);
  // mobile viewport detection
  const [width, setWidth]                           = useState(window.innerWidth);

  const handleWindowSizeChange = () => {
    setWidth(window.innerWidth);
  }

  useEffect(() => {
    window.addEventListener('resize', handleWindowSizeChange);
    return () => {
      window.removeEventListener('resize', handleWindowSizeChange);
    }
  }, []);
  
  const isMobile = width <= 768;
  console.log(`Mobile Veiwport: ${isMobile}`);

  const removeCookie = (name, path = '/') => {
    var domain = process.env.REACT_APP_ROOT_DOMAIN;
    const cookieString = `${encodeURIComponent(name)}=; expires=Thu, 01 Jan 1970 00:00:00 GMT; Secure=; path=${path}`;
    if (domain) {
        document.cookie = `${cookieString}; domain=${domain}`;
    } else {
        document.cookie = cookieString;
    }
  };

  useEffect(() => {
    setAuthLoading(false);
    props.authState.then(function(authData){
      if(authData){
        setAuth(true);
        console.log(`Auth Connection Status: true | ${JSON.stringify(authData.data.decodedJwtToken.decodedJwtToken.INTERVIEW_USERDATA_username)}`);
        let __createdtime__ = Date.now();
        var jwtUserDetails = atob(window.localStorage.getItem("Authorization").split(".")[1]);
        setLoggedUser(JSON.parse(jwtUserDetails).INTERVIEW_USERDATA_username);
        setTier(JSON.parse(jwtUserDetails).INTERVIEW_USERDATA_tier);

        axios.get(`${process.env.REACT_APP_REST_API_URL}/interviewTemplate`, { 
          headers: { 'Content-Type': 'application/json', authtoken: window.localStorage.getItem("Authorization"), '__createdtime__': __createdtime__ },
          maxBodyLength: Infinity,
        })
        .then((res) => {
          if(res.data.error === true) {
            setIsError(res.data.errorMessage);
          }else{
            if(res.data){
              setIsError('')
              setTemplates(res.data.Items);
            }else{
              setIsError('Unable to fetched templates list.');
            }
          }
          setInprogress(false);
        })
        .catch((e) => {
          setInprogress(false);
          console.log(e);
        });
        
        const socketInit = socketIO.connect(process.env.REACT_APP_SOCKET_API_URL,
          { transports: ["websocket"], query: `token=${window.localStorage.getItem("Authorization")}`, extraHeaders: { Authorization: `Bearer ${window.localStorage.getItem("Authorization")}` } }
        );
        setSocket(socketInit);
      }else{
        setIsError("Unathorized!");
        setInprogress(false);
        console.log(`Auth Connection Status: false | Error`);
        window.localStorage.removeItem("Authorization");
        window.localStorage.removeItem("ssoProvider");
        removeCookie("Authorization");
        removeCookie("ssoProvider");
        window.location.href = '/auth';
      }
    }).catch(function(err){
      setIsError("Unathorized!");
      setInprogress(false);
      console.log(`Auth Connection Status: false | ${JSON.stringify(err)}`);
      window.localStorage.removeItem("Authorization");
      window.localStorage.removeItem("ssoProvider");
      removeCookie("Authorization");
      removeCookie("ssoProvider");
      window.location.href = '/auth';
    });
  }, []);

  useEffect(() => {
    if(socket !== null){
      setTimeout(function(){
        setIsSocketConnected(socket.connected);
      },1000);

      socket.on('unauthorized', (error, callback) => {
        if (error.data.type == 'UnauthorizedError' || error.data.code == 'invalid_token') {
          // redirect user to login page perhaps?
          callback();
          console.log('User token has expired');
        }
      });

      socket.emit('ping', (data) => {
        console.log(`Ping: ${JSON.stringify(data)}`);
      });

      socket.on('pong', (data) => {
        console.log(`Pong: ${JSON.stringify(data)}`);
      });
    }
  }, [socket]);

  const handleResetClick = (path) => {
    window.location.pathname === path ? window.location.reload() : navigate(path)
  };

  const handleFileUpload = (e, type) => {
    const file   = e.target.files[0];
    switch (type) {
      case 'csv':
        setTemplate(file);
        break;
      default:
        break;
    }
  };

  const handleFileUploadClick = () => {
    if(template){
      setIsError('');
      setInprogress(true);
      let __createdtime__ = Date.now();
      const data = new FormData();
      data.append('template', template, `${template}.csv`);
      
      axios.post(`${process.env.REACT_APP_REST_API_URL}/interviewTemplateUpload`, data, { 
        headers: { 'Content-Type': 'multipart/form-data', authtoken: window.localStorage.getItem("Authorization"), '__createdtime__': __createdtime__ },
        maxBodyLength: Infinity,
      })
      .then((res) => {
        if(res.data.error === true) {
          setIsError(res.data.errorMessage);
        }else{
          setIsError('');
          setResponse(res.data);
          setTemplate(false);
          console.log(`File Template Uploaded! ${res.data.templateName} -  ${res.data.templateId}`);
        }
        setInprogress(false);
      })
      .catch((e) => {
        setInprogress(false);
        console.log(e);
      });
    }else{
      setIsError('No File Detected.');
    }
  };

  const handleFileUploadCancel = () => {
    setIsError('');
    setTemplate(false);
  };

  const handleDeleteTemplate = () => {
    let e  = isActionConfirmationOpen[0];
    let id = isActionConfirmationOpen[1];

    let deleteDynamicPanel = e.target.parentElement.parentElement.querySelector(`[deletedynamicpanel="${id}"]`);
    let deleteDynamicBtn   = e.target.parentElement.parentElement.querySelector(`[deletedynamicbtn="${id}"]`);
    if (deleteDynamicPanel)
      deleteDynamicPanel.style.display = 'inline-grid';

    if(deleteDynamicBtn)
      deleteDynamicBtn.style.display = 'none';

    // templates.splice(index, 1);
    const filteredItems = templates.filter(item => item.INTERVIEW_TEMPLATE_id.S !== id);
    setTemplates(filteredItems);

    setIsActionConfirmationOpen(false);
    let deleteData = {'INTERVIEW_TEMPLATE_id': id};
    socket.emit('deleteTemplate', deleteData);
    console.log(`unArchived ${id}`);
  };

  return (
    <>
      <Grid container spacing={1} direction="column" alignItems="center" sx={{margin: "15px 0px 0px 0px"}}> 
        <Grid sx={{minWidth: isMobile ? "100%!important" : "80%!important", maxWidth: isMobile ? "100%!important" : "80%!important"}}>
          { auth === true && parseInt(tier) === 0? 
            <FormControl fullWidth sx={{margin: "0px 0px 0px 0px"}}>
              <Box sx={{ '& .MuiTextField-root': { m: 1 }, padding: '0px 30px 0px 20px' }} noValidate autoComplete="off">
                <Stack direction="column" spacing={2} justifyContent="center" alignItems="center" minHeight="70vh" maxWidth="43vh" sx={{margin: "0 auto"}}> 
                  <Typography variant="h6" color="black" align="center"> 
                    Interview Templates
                  </Typography>
                  { isInprogress ? 
                    <Box sx={{margin: '10px 0px 10px 0px', minWidth: "41vh"}}>
                      <Skeleton />
                      <Skeleton animation="wave" />
                      <Skeleton animation={false} />
                    </Box>
                  :
                    response ? 
                      <FormControl fullWidth>
                        <Box noValidate autoComplete="off">
                          <Alert sx={{ minWidth: "100%" }} severity="success">
                            Template — <strong style={{textDecoration: "underline", cursor: "pointer"}}> {response.templateName}</strong> Uploaded - {response.templateId}
                          </Alert>
                          <Button onClick={() => {window.location.reload()}} sx={{ margin: '5px 0px 0px 0px', fontSize: '10px', float: 'right' }} color="secondary">Done</Button>
                        </Box>
                      </FormControl>
                    :
                      <>
                        { isError === '' ?
                          <></>
                        :
                          <FormControl fullWidth>
                            <Box component="form" noValidate autoComplete="off">
                              <Alert sx={{ minWidth: "100%" }} severity="error">
                                {isError} — <strong style={{textDecoration: "underline", cursor: "pointer"}} ><a onClick={() => handleResetClick(isError === "Unathorized!"? "/auth" : "/uploadTemplate")}>start again!</a></strong>
                              </Alert>
                            </Box>
                          </FormControl>
                        }

                        <Box sx={{ width: '90%', position: 'absolute', top: '50%', left: '50%', transform: 'translate(-50%, -50%)',bgcolor: 'background.paper', boxShadow: 1, p: 4, padding: '40px 40px 30px 40px!important' }}>
                          {isInprogress ? 
                              <Box sx={{margin: '10px 0px 10px 0px'}}>
                                <Skeleton />
                                <Skeleton animation="wave" />
                                <Skeleton animation={false} />
                              </Box>
                            :
                              <>
                                <Typography variant="h6" color="black" align="center" sx={{margin: '0px 0px 18px 0px'}}> 
                                  Interview Template
                                </Typography>
                                <TableContainer component={Paper} sx={{maxHeight: '271px'}}>
                                  <Table stickyHeader sx={{ minWidth: '100%' }} size="small" aria-label="a dense table">
                                    <TableHead>
                                      <TableRow>
                                        <TableCell>Name</TableCell>
                                        <TableCell>Interviewer</TableCell>
                                        <TableCell>Designation</TableCell>
                                        <TableCell>Questions</TableCell>
                                        <TableCell>Actions</TableCell>
                                      </TableRow>
                                    </TableHead>
                                    <TableBody>
                                      {templates.map((row) => (
                                        <TableRow
                                          key={row.INTERVIEW_TEMPLATE_id.S}
                                          sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                        >
                                          <TableCell component="th" scope="row">
                                            {row.INTERVIEW_TEMPLATE_name.S}
                                          </TableCell>
                                          <TableCell>{JSON.parse(row.INTERVIEW_METADATA_interviewer.S).name}</TableCell>
                                          <TableCell sx={{width: '15%'}}>{JSON.parse(row.INTERVIEW_METADATA_interviewer.S).designation}</TableCell>
                                          <TableCell sx={{width: '3%'}}>{JSON.parse(row.INTERVIEW_TEMPLATE_json.S).length}</TableCell>
                                          <TableCell sx={{width: '3%'}}><span style={{display: 'none'}} deletedynamicpanel={row.INTERVIEW_TEMPLATE_id.S}>Deleting...</span><DeleteIcon sx={{cursor: 'pointer'}} deletedynamicbtn={row.INTERVIEW_TEMPLATE_id.S} onClick={(e) => (setIsActionConfirmationOpen([e, row.INTERVIEW_TEMPLATE_id.S, row.INTERVIEW_TEMPLATE_name.S]))}/> </TableCell>
                                        </TableRow>
                                      ))}
                                    </TableBody>
                                  </Table>
                                </TableContainer>

                                <Modal open={isActionConfirmationOpen !== false} onClose={(event, reason) => {
                                  // Prevent closing the modal if the reason is 'backdropClick'
                                  if (reason !== 'backdropClick') {
                                    setIsActionConfirmationOpen(false);
                                  }
                                  }} BackdropProps={{
                                      // Disable clicks on the backdrop to prevent closing the modal
                                      onClick: (event) => event.stopPropagation(),
                                  }} aria-labelledby="modal-modal-title" aria-describedby="modal-modal-description">
                                  <Box sx={{ width: isMobile? '90%' : '40%', position: 'absolute', top: '50%', left: '50%', transform: 'translate(-50%, -50%)',bgcolor: 'background.paper', boxShadow: 24, p: 4, padding: '40px 40px 30px 40px!important' }}>
                                    <Typography variant="body2" gutterBottom>
                                      Are you sure you want to delete with <strong>{isActionConfirmationOpen[2]}</strong> template?
                                    </Typography>
                                    <Button onClick={() => {handleDeleteTemplate()}}  sx={{ display: "flex", margin: '15px 0px 0px 0px', fontSize: '10px', float: 'right' }}  endIcon={<ArrowRightAltIcon/>}>Delete</Button>
                                    <Button onClick={() => setIsActionConfirmationOpen(false)} sx={{ display: "flex", margin: '15px 0px 0px 0px', fontSize: '10px', float: 'right' }}  startIcon={<CloseIcon/>}>Close</Button>
                                  </Box>
                                </Modal>
                                
                                <Divider sx={{color: 'gray', fontSize: '13px', margin: '25px 0px 15px 0px'}}>Upload Template</Divider>

                                <TextField fullWidth disabled={isError ? true : false} type="file" onChange={(e) => handleFileUpload(e, 'csv')}
                                  InputProps={{ style: { fontSize: '10px', margin: '0px 0px 0px -10px' },
                                    startAdornment: (
                                      <InputAdornment position="start">
                                        <IconButton component="label">
                                          <FileUploadIcon />
                                        </IconButton>
                                      </InputAdornment>
                                    ),
                                }}/>
                                <Typography variant="caption" color="black" align="center" sx={{margin: "0px 0px 0px 0px!important"}}> 
                                  Dont change the template structure as this will result to failed template parsing. &nbsp;
                                  <a href="https://interviewapp-virginia.s3.amazonaws.com/templates/Interview+Template+-+DevOps+Engineer.csv" target='_blank'>Download</a> Template Excel Sheet
                                </Typography>

                                {template ? 
                                  <>
                                    <div style={{fontFamily: "monospace", fontSize: "10px", margin: '0px 0px 0px 0px', width: '100%'}}><strong style={{fontWeight: '800'}}>File Name:</strong> {template.name} | <strong style={{fontWeight: '800'}}>File Size:</strong> {(template.size / 1024).toFixed(2)} KB</div> 
                                    <div style={{textAlign: "right", width: '100%'}}>
                                      {isError ? 
                                        <Button onClick={() => handleResetClick("/uploadTemplate")} sx={{ margin: '5px 0px 0px 0px', fontSize: '10px' }} color="secondary">Start Over</Button>
                                      :
                                      <>
                                        <Button onClick={() => handleFileUploadCancel()} sx={{ margin: '5px 0px 0px 0px', fontSize: '10px' }} color="secondary">Cancel</Button>
                                        <Button onClick={() => handleFileUploadClick()} sx={{ margin: '5px 0px 0px 0px', fontSize: '10px' }} variant="contained" color="secondary">
                                          Upload
                                        </Button>
                                      </>
                                      }
                                    </div>
                                  </>                        
                                : <></>}
                              </>
                          }
                        </Box>
                      </>
                  }
                </Stack>
                  {/* { 
                    isMobile ?
                      <></>
                    : 
                      <></>
                  } */}
              </Box>
            </FormControl>
          : 
            isError === '' ?
              <Stack direction="column" spacing={2} justifyContent="center" alignItems="center" minHeight="70vh" maxWidth="43vh" sx={{margin: "0 auto"}}> 
                <FormControl fullWidth>
                  <Box component="form" sx={{ '& .MuiTextField-root': { m: 1, width: '25ch' }, minHeight: "50px" }} noValidate autoComplete="off">
                    <img style={{width: '190px', margin: '0px 0px 20px 115px'}} src={ForbiddenImage} alt="Something went wrong icons created by andinur - Flaticon" />
                    <Alert variant="outlined" severity="error">
                      Unauthorized <strong style={{textDecoration: "underline", cursor: "pointer"}} ><a onClick={() => window.location.href = '/welcome'}>go back to home</a></strong>
                    </Alert>
                  </Box>
                </FormControl>
                <Typography color="black" align="center" sx={{fontSize: '10px', margin: '5px 0px 0px 0px!important'}}> 
                  If you are receiving this error with high occurence - please contact the support <a href="mailto:example@example.com">administrator@edcelvista.com</a>
                </Typography>
              </Stack>
            :
              <FormControl fullWidth>
                <Box component="form" sx={{ '& .MuiTextField-root': { m: 1, width: '25ch' }, minHeight: "50px" }} noValidate autoComplete="off">
                  <Alert sx={{ minWidth: "100%", margin: "10px" }} severity="error">
                    {isError} — <strong style={{textDecoration: "underline", cursor: "pointer"}} ><a onClick={() => handleResetClick(isError === "Unathorized!"? "/auth" : "/welcome")}>start again!</a></strong>
                  </Alert>
                </Box>
              </FormControl>
          }
        </Grid>
      </Grid>
    </>
  )
}

export default UploadTemplatePage;