import React, {useState, useEffect} from "react";
import { useNavigate } from 'react-router-dom';
import { Outlet, useNavigation } from "react-router-dom";
import { Box, Typography } from "@mui/material";
import { createTheme, ThemeProvider, responsiveFontSizes  } from '@mui/material';
import AppBar from '@mui/material/AppBar';
import CssBaseline from '@mui/material/CssBaseline';
import Divider from '@mui/material/Divider';
import Drawer from '@mui/material/Drawer';
import IconButton from '@mui/material/IconButton';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemText from '@mui/material/ListItemText';
import MenuIcon from '@mui/icons-material/Menu';
import Toolbar from '@mui/material/Toolbar';
import Button from '@mui/material/Button';
import AccountCircle from '@mui/icons-material/AccountCircle';
import MenuItem from '@mui/material/MenuItem';
import Menu from '@mui/material/Menu';
import Tooltip from '@mui/material/Tooltip';
import LogoImage from '../images/logo-no-background.png';

const themeAttr = {
  palette: {
    primary: {
      main: '#000000',
      contrastText: "#ffffff",
    },
    secondary: {
      main: '#000000'
    },
    error: {
      main: '#ef5350'
    }
  },
  typography: {
    fontFamily: [
      'IBM Plex Sans',
      'Inter',
      'sans-serif',
    ].join(',')
  }
};
const theme = responsiveFontSizes(createTheme(themeAttr));

const drawerWidth = 240;
const Root = (props) => {
  const { _window }                 = props;
  const { state }                   = useNavigation();
  const navigate                    = useNavigate();
  const [loggedUser, setLoggedUser] = useState("");
  const [tier, setTier]             = useState(1);
  const [mobileOpen, setMobileOpen] = useState(false);
  const [anchorEl, setAnchorEl]     = useState(null);

  //mobile viewport detection
  const [width, setWidth] = useState(window.innerWidth);

  const handleWindowSizeChange = () => {
    setWidth(window.innerWidth);
  }

  useEffect(() => {
    window.addEventListener('resize', handleWindowSizeChange);
    return () => {
      window.removeEventListener('resize', handleWindowSizeChange);
    }
  }, []);
  
  const isMobile = width <= 768;
  console.log(`Mobile Veiwport: ${isMobile}`);

  const handleDrawerToggle = () => {
    setMobileOpen((prevState) => !prevState);
  };

  const handleMenu = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };
  
  const drawer = (
    <Box onClick={handleDrawerToggle} sx={{ textAlign: 'center', backgroundColor: themeAttr.palette.primary.main, color: themeAttr.palette.primary.contrastText }}>
      <Typography variant="h6" sx={{ my: 2 }}>
        {process.env.REACT_APP_HEADER_NAME}
      </Typography>
      <Divider />
      <List>
      { loggedUser !== ""  ? 
          parseInt(tier) === 0 ?
            <>
              <ListItem disablePadding>
                <ListItemButton sx={{ textAlign: 'center' }}>
                  <ListItemText onClick={()=> navigate("/payment")} primary="Credit Balance" />
                </ListItemButton>
              </ListItem>
              <ListItem disablePadding>
                <ListItemButton sx={{ textAlign: 'center' }}>
                  <ListItemText onClick={()=> navigate("/welcome")} primary="Interviews" />
                </ListItemButton>
              </ListItem>
              <ListItem disablePadding>
                <ListItemButton sx={{ textAlign: 'center' }}>
                  <ListItemText onClick={()=> navigate("/invite")} primary="Invite" />
                </ListItemButton>
              </ListItem>
              <ListItem disablePadding>
                <ListItemButton sx={{ textAlign: 'center' }}>
                  <ListItemText onClick={()=> navigate("/uploadTemplate")} primary="Templates" />
                </ListItemButton>
              </ListItem>
              <ListItem disablePadding>
                <ListItemButton sx={{ textAlign: 'center' }}>
                  <ListItemText onClick={()=> navigate("/updateUserDetails")} primary="UserType" />
                </ListItemButton>
              </ListItem>
              <ListItem disablePadding>
                <ListItemButton sx={{ textAlign: 'center' }}>
                  <ListItemText onClick={()=> handleLogoutClick()} primary="Logout" />
                </ListItemButton>
              </ListItem>
            </>
          :
            <>
              <ListItem disablePadding>
                <ListItemButton sx={{ textAlign: 'center' }}>
                  <ListItemText onClick={()=> navigate("/payment")} primary="Credit Balance" />
                </ListItemButton>
              </ListItem>
              <ListItem disablePadding>
                <ListItemButton sx={{ textAlign: 'center' }}>
                  <ListItemText onClick={()=> navigate("/welcome")} primary="Interviews" />
                </ListItemButton>
              </ListItem>
              <ListItem disablePadding>
                <ListItemButton sx={{ textAlign: 'center' }}>
                  <ListItemText onClick={()=> handleLogoutClick()} primary="Logout" />
                </ListItemButton>
              </ListItem>
            </>
      :
        <>
          <ListItem disablePadding>
            <ListItemButton sx={{ textAlign: 'center' }}>
              <ListItemText onClick={()=> navigate("/pricing")} primary="Pricing" />
            </ListItemButton>
          </ListItem>
          <ListItem disablePadding>
            <ListItemButton sx={{ textAlign: 'center' }}>
              <ListItemText onClick={()=> navigate("/auth")} primary="Login" />
            </ListItemButton>
          </ListItem>
          <ListItem disablePadding>
            <ListItemButton sx={{ textAlign: 'center' }}>
              <ListItemText onClick={()=> navigate("/signup")} primary="Signup" />
            </ListItemButton>
          </ListItem>
        </>
      }
      </List>
    </Box>
  );

  const container         = _window !== undefined ? () => _window().document.body : undefined;
  const handleLogoutClick = () => {
    removeCookie('Authorization');
    removeCookie('ssoProvider');
    window.localStorage.removeItem("Authorization");
    window.localStorage.removeItem("ssoProvider");
    window.location.href = '/auth';
  };

  const removeCookie = (name, path = '/') => {
    var domain = process.env.REACT_APP_ROOT_DOMAIN;
    const cookieString = `${encodeURIComponent(name)}=; expires=Thu, 01 Jan 1970 00:00:00 GMT; Secure=; path=${path}`;
    if (domain) {
        document.cookie = `${cookieString}; domain=${domain}`;
    } else {
        document.cookie = cookieString;
    }
  }

  const getCookie = (name) => {
    const value = `; ${document.cookie}`;
    const parts = value.split(`; ${name}=`);
    if (parts.length === 2) {
      return decodeURIComponent(parts.pop().split(';').shift());
    }
    return null;
  }

  useEffect(() => {
    const appVersion = document.querySelector('meta[name="version"]').content;
    const cachedVersion = localStorage.getItem('appVersion');
  
    if (cachedVersion !== appVersion) {
      // New version detected, clear cache and reload
      localStorage.clear();
      localStorage.setItem('appVersion', appVersion);
      if ('caches' in window) {
        caches.keys().then(cacheNames => {
            cacheNames.forEach(cacheName => {
                caches.delete(cacheName);
            });
        }).then(() => {
            console.log('All caches cleared!');
        }).catch(err => {
            console.error('Error clearing caches:', err);
        });
      }
      window.location.reload(true); // Force reload without cache
      console.log(`Cleared Cache for version ${appVersion} -> ${cachedVersion}`);
    }else{
      console.log(`App version ${cachedVersion} -> ${appVersion}`);
    }
  }, []);

  useEffect(() => {
    var jwtUserDetails;
    if (window.localStorage.getItem("Authorization")){
      try {
        jwtUserDetails = atob(window.localStorage.getItem("Authorization").split(".")[1]);
        setLoggedUser(JSON.parse(jwtUserDetails).INTERVIEW_USERDATA_username);
        setTier(JSON.parse(jwtUserDetails).INTERVIEW_USERDATA_tier);
      } catch (error) {
        navigate("/auth");
      }
    }else{
      var authCookie = getCookie("Authorization");
      if (authCookie){ // sso returned token via cookie
        try {
          jwtUserDetails = atob(authCookie.split(".")[1]);
          var ssoProvider    = getCookie("SSOProvider");
          setLoggedUser(JSON.parse(jwtUserDetails).INTERVIEW_USERDATA_username);
          console.log(`SSO User: ${JSON.parse(jwtUserDetails).INTERVIEW_USERDATA_username}`);
          window.localStorage.setItem("Authorization", authCookie);
          window.localStorage.setItem("ssoProvider", ssoProvider);
          window.location.href = '/sso';
        } catch (error) {
          removeCookie('Authorization');
          removeCookie('ssoProvider');
          window.localStorage.removeItem("Authorization");
          window.localStorage.removeItem("ssoProvider");
          window.location.href = '/auth';
        }
      }
    }
  }, [loggedUser]);

  return (
    <ThemeProvider theme={theme}>
      <Box sx={{ display: 'flex' , overflowX: "hidden" }}>
        <CssBaseline />
        
        <AppBar component="nav">
          <Toolbar>
            <IconButton color="inherit" aria-label="open drawer" edge="start" onClick={handleDrawerToggle} sx={{ mr: 2, display: { sm: 'none' } }}>
              <MenuIcon />
            </IconButton>
            <Typography onClick={()=> navigate(loggedUser ? "/welcome" : "/")}  variant="h6" component="div" sx={{ flexGrow: 1, display: { xs: 'none', sm: 'block' }, cursor: "pointer" }}>
               {process.env.REACT_APP_HEADER_NAME} 
              {/* <img style={{width: '145px', margin: '-9px 0px 0px -5px', position: 'absolute'}} src={LogoImage} alt="Logo Image" /> <small style={{fontSize: '8px'}}>v{localStorage.getItem('appVersion')}</small> */}
            </Typography>
            <Box sx={{ display: { xs: 'none', sm: 'block' } }}>
            { loggedUser !== "" ? 
              <>
                <Button onClick={()=> navigate("/welcome")} sx={{ color: '#fff' }}>
                  Interviews
                </Button>
                {parseInt(tier) === 0 ?
                <>
                  <Button onClick={()=> navigate("/invite")} sx={{ color: '#fff' }}>
                    Invite
                  </Button>
                  <Button onClick={()=> navigate("/uploadTemplate")} sx={{ color: '#fff' }}>
                    Templates
                  </Button>
                  <Button onClick={()=> navigate("/updateUserDetails")} sx={{ color: '#fff' }}>
                    UserType
                  </Button>
                </>
                : <></>}

                <div style={{ display: "inline" }}>
                  <Tooltip title={loggedUser}>
                    <IconButton style={{fontSize: "12px"}} aria-label="account of current user" aria-controls="menu-appbar" aria-haspopup="true" onClick={handleMenu} color="inherit">
                      <AccountCircle style={{ color: "white" }} />
                      <span style={{ color: "white" }}></span>
                    </IconButton>
                  </Tooltip>
                  <Menu id="menu-appbar" anchorEl={anchorEl} anchorOrigin={{ vertical: 'top', horizontal: 'right' }} keepMounted transformOrigin={{ vertical: 'top', horizontal: 'right'}} open={Boolean(anchorEl)} onClose={handleClose}>
                    <MenuItem onClick={()=> navigate("/payment")}>Credits balance</MenuItem>
                    <MenuItem onClick={()=> navigate("/auth")}>My Account</MenuItem>
                    <MenuItem onClick={()=> handleLogoutClick()}>Logout</MenuItem>
                  </Menu>
                </div>
              </>
            : 
              <>
                <Button onClick={()=> navigate("/pricing")} sx={{ color: '#fff' }}>
                  Pricing
                </Button>
                <Button onClick={()=> navigate("/auth")} sx={{ color: '#fff' }}>
                  Login
                </Button>
                <Button onClick={()=> navigate("/signup")} sx={{ color: '#fff' }}>
                  Signup
                </Button>
              </>
            }
            </Box>
          </Toolbar>
          { isMobile ? 
            <div style={{zIndex: '9999', position: 'fixed', right: 0, margin: '15px 20px 0px 0px', color: 'white' }} onClick={() => {navigate("/auth")}}>{loggedUser}</div>
            : <></>
          }
        </AppBar>

        <nav>
          <Drawer container={container} variant="temporary" open={mobileOpen} onClose={handleDrawerToggle} ModalProps={{ keepMounted: true, // Better open performance on mobile.
            }}
            sx={{ display: { xs: 'block', sm: 'none' }, '& .MuiDrawer-paper': { boxSizing: 'border-box', width: drawerWidth }}} >
            {drawer}
          </Drawer>
        </nav>

        <Box component="main" sx={{ width: '100%', height: '95vh', overflowX: "hidden" }}>
          <Toolbar />
            { state === 'loading' ? 
              <div style={{position: "fixed", width: "100%", height: "100%", margin: "0 auto", backgroundColor: "rgb(159,159,159,0.8)", textAlign: "center", zIndex: "9999", flexDirection: "column", justifyContent: "center", alignContent: "center", color: "white", fontSize: "24px", fontWeight: "300"}}>
                Loading.. Please wait...
              </div>
            : 
              <></>
            }
          <Outlet />
        </Box>
      </Box>

      <Typography sx={{textAlign: 'center', margin: '10px 0px 0px 0px'}} variant="caption" display="block" gutterBottom>
        edcelvista.com &copy; {new Date().getFullYear()} All rights reserved &bull; v{localStorage.getItem('appVersion')}
      </Typography>
    </ThemeProvider>
  )
}

export default Root;