import axios from "axios";

const removeCookie = (name, path = '/') => {
  var domain = process.env.REACT_APP_ROOT_DOMAIN;
  const cookieString = `${encodeURIComponent(name)}=; expires=Thu, 01 Jan 1970 00:00:00 GMT; path=${path}`;
  if (domain) {
      document.cookie = `${cookieString}; domain=${domain}`;
  } else {
      document.cookie = cookieString;
  }
};

export const verifyToken = async () => {
  if (window.localStorage.getItem("Authorization") !== null){
    try {
      const verifySession = await axios.post(`${process.env.REACT_APP_REST_API_URL}/userAccount/verifySession`, {
        params: {}
      }, {headers: {
        authtoken: window.localStorage.getItem("Authorization")
      }});
      return verifySession;
    } catch (error) {
      removeCookie("Authorization");
      removeCookie("ssoProvider");
      window.localStorage.removeItem("Authorization");
      window.localStorage.removeItem("ssoProvider");
      console.log(error);
    }
  }
  return false;
};
