import React, { useState, useEffect } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import FormControl from '@mui/material/FormControl';
import Alert from '@mui/material/Alert';
import Grid from '@mui/material/Grid';
import Button from '@mui/material/Button';
import Stack from '@mui/material/Stack';
import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import ListAltIcon from '@mui/icons-material/ListAlt';
import Modal from '@mui/material/Modal';
import Skeleton from '@mui/material/Skeleton';
import Divider from '@mui/material/Divider';
import Paper from '@mui/material/Paper';
import Tooltip from '@mui/material/Tooltip';
import BrowserUpdatedIcon from '@mui/icons-material/BrowserUpdated';
import CloseIcon from '@mui/icons-material/Close';
import ForbiddenImage from '../images/error.png'
import axios from "axios";

const PaymentPage = (props) => {
  const navigate                                    = useNavigate();
  const [searchParams]                              = useSearchParams();
  const [isError, setIsError]                       = useState("");
  const [loggedUser, setLoggedUser]                 = useState("");
  const [isInprogress, setInprogress]               = useState(true);
  const [isInprogressM, setInprogressM]             = useState(true);
  const [tier, setTier]                             = useState(1);
  // auth
  const [auth, setAuth]                             = useState(false);
  const [authLoading, setAuthLoading]               = useState(true);

  // page data | STRIPE 
  const [productList, setProductList]               = useState([
    {name: "Starter plan I", "price": "$20.00", "priceNocur": "20.00", "credits":"5", "price_id": "price_1Q9qTwGI7PTiSnQi50N7Ov06", "color": '#db8d00'},
    {name: "Starter plan II", "price": "$25.00", "priceNocur": "25.00", "credits":"10", "price_id": "price_1Q9qThGI7PTiSnQiEvcB52PY", "color": '#4646dc'},
    {name: "Starter plan III", "price": "$30.00", "priceNocur": "30.00", "credits":"15", "price_id": "price_1Q9qTKGI7PTiSnQil7kiI66x", "color": '#7e287e'}
  ]);

  const [response, setResponse]                     = useState(null);
  // mobile viewport detection
  const [width, setWidth]                           = useState(window.innerWidth);

  // payment sp callback
  const [credits, setCredits]                       = useState(0);
  const [transactionList, setTransactionList]       = useState(null);
  const [isInvoiceOpen, setIsInvoiceOpen]           = useState(false);
  const state                                       = searchParams.get('state');  // Get the 'state' query parameter
  const session_id                                  = searchParams.get('session_id');  // Get the 'session_id' query parameter

  console.log(`state: ${state} | session_id: ${session_id} `);

  const handleWindowSizeChange = () => {
    setWidth(window.innerWidth);
  }

  useEffect(() => {
    window.addEventListener('resize', handleWindowSizeChange);
    return () => {
      window.removeEventListener('resize', handleWindowSizeChange);
    }
  }, []);
  
  const isMobile = width <= 768;
  console.log(`Mobile Veiwport: ${isMobile}`);

  const removeCookie = (name, path = '/') => {
    var domain = process.env.REACT_APP_ROOT_DOMAIN;
    const cookieString = `${encodeURIComponent(name)}=; expires=Thu, 01 Jan 1970 00:00:00 GMT; Secure=; path=${path}`;
    if (domain) {
        document.cookie = `${cookieString}; domain=${domain}`;
    } else {
        document.cookie = cookieString;
    }
  };

  useEffect(() => {
    setAuthLoading(false);
    props.authState.then(function(authData){
      if(authData){
        setAuth(true);
        console.log(`Auth Connection Status: true | ${JSON.stringify(authData.data.decodedJwtToken.decodedJwtToken.INTERVIEW_USERDATA_username)}`);
        var jwtUserDetails = atob(window.localStorage.getItem("Authorization").split(".")[1]);
        setLoggedUser(JSON.parse(jwtUserDetails).INTERVIEW_USERDATA_username);
        setTier(JSON.parse(jwtUserDetails).INTERVIEW_USERDATA_tier);

        // page data init
        let __createdtime__ = Date.now();
        axios.get(`${process.env.REACT_APP_REST_API_URL}/userAccount/creditsCheck`, { 
          headers: { 'Content-Type': 'application/json', authtoken: window.localStorage.getItem("Authorization"), '__createdtime__': __createdtime__ },
          maxBodyLength: Infinity,
        })
        .then((res) => {
          if(res.data.error === true) {
            setIsError(res.data.errorMessage);
          }else{
            if(res.data){
              setIsError('');
              //if session_id present validate
              setCredits(res.data.latestCredits);
            }else{
              setIsError('Unable to fetched credit details.');
            }
          }
          setInprogress(false);
        })
        .catch((e) => {
          setInprogress(false);
          console.log(e);
        });

      }else{
        setIsError("Unathorized!");
        setInprogress(false);
        console.log(`Auth Connection Status: false | Error`);
        window.localStorage.removeItem("Authorization");
        window.localStorage.removeItem("ssoProvider");
        removeCookie("Authorization");
        removeCookie("ssoProvider");
        window.location.href = '/auth';
      }
    }).catch(function(err){
      setIsError("Unathorized!");
      setInprogress(false);
      console.log(`Auth Connection Status: false | ${JSON.stringify(err)}`);
      window.localStorage.removeItem("Authorization");
      window.localStorage.removeItem("ssoProvider");
      removeCookie("Authorization");
      removeCookie("ssoProvider");
      window.location.href = '/auth';
    });
  }, []);

  const handleResetClick = (path) => {
    window.location.pathname === path ? window.location.reload() : navigate(path)
  };

  const handlePayClick = (method, selectedProduct) => {
    const price_id = selectedProduct.price_id
    switch (method) {
      case "STRIPE":
        console.log(`price_id: ${price_id}`); 
        let __createdtime__ = Date.now();
        const data = new FormData();

        data.append('price_id', price_id);
        axios.post(`${process.env.REACT_APP_REST_API_URL}/payment/create-checkout-session`, data, { 
          headers: { 'Content-Type': 'application/x-www-form-urlencoded', authtoken: window.localStorage.getItem("Authorization"), '__createdtime__': __createdtime__ },
          maxBodyLength: Infinity,
        })
        .then((res) => {
          if(res.data.error === true) {
            setIsError(res.data.errorMessage);
          }else{
            if(res.data){
              setIsError('')
              setResponse(res.data);
              setTimeout(function(){
                console.log(`Redirecting: ${res.data.session_url}`)
                window.location.href=res.data.session_url;
              },1000);
            }else{
              setIsError(`Unable to proceed with the payment.`);
            }
          }
          setInprogress(false);
        })
        .catch((e) => {
          setInprogress(false);
          console.log(e);
        });
        break;
      case "PAYPAL":        
        navigate(`/paymentPaypal/${btoa(JSON.stringify(selectedProduct))}`)
        break;
    }  
  };

  const handleViewInvoiceClick = (toogle) => {
    if (process.env == "STRIPE"){
      setIsInvoiceOpen(toogle);
      if(toogle && transactionList === null){
        let __createdtime__ = Date.now();
        axios.get(`${process.env.REACT_APP_REST_API_URL}/userAccount/transactionList`, { 
          headers: { 'Content-Type': 'application/json', authtoken: window.localStorage.getItem("Authorization"), '__createdtime__': __createdtime__ },
          maxBodyLength: Infinity,
        })
        .then((res) => {
          if(res.data.error === true) {
            setIsError(res.data.errorMessage);
          }else{
            if(res.data){
              setTransactionList(res.data);
            }
          }
          setInprogressM(false);
        })
        .catch((e) => {
          setInprogressM(false);
          console.log(e);
        });
      }
    }else{
      alert("Invoice for paypal payments not available at the moment.");
    }
  };

  return (
    <>
      <Grid container spacing={1} direction="column" alignItems="center" sx={{margin: "15px 0px 0px 0px"}}> 
        <Grid sx={{minWidth: isMobile ? "100%!important" : "80%!important", maxWidth: isMobile ? "100%!important" : "80%!important"}}>
          { auth === true ? 

            <FormControl fullWidth sx={{margin: "0px 0px 0px 0px"}}>
              <Box sx={{ '& .MuiTextField-root': { m: 1 }, padding: '0px 30px 0px 20px' }} noValidate autoComplete="off">
                <Stack direction="column" spacing={2} justifyContent="center" alignItems="center" minHeight="70vh" maxWidth="70vh" sx={{margin: "0 auto"}}> 
                  <Typography variant="h6" color="black" align="center" sx={{margin: '0px 0px 0px 0px!important'}}> 
                    Payment & Subscription <small>(soon)</small>
                  </Typography>
                  <Typography variant="caption" color="black" align="center" sx={{margin: '0px 0px 0px 0px!important'}}> 
                    Ping us at administrator@edcelvista.com if you need more volume or some special features. We'll be glad to help.
                  </Typography>
                  <FormControl fullWidth>
                    <Box sx={{ minHeight: "50px" }} noValidate autoComplete="off">
                      <Alert sx={{ minWidth: "100%" }} severity="info">
                        Remaining Credits <strong>{isInprogress ? "fetching credits..." :credits}</strong>
                      </Alert>
                    </Box>
                  </FormControl>
                  <FormControl sx={{ margin: '0px 0px -21px 0px!important' }} fullWidth>
                    <Box sx={{ minHeight: "50px" }} noValidate autoComplete="off">
                      <Button onClick={() => handleViewInvoiceClick(isInvoiceOpen ? false : true)} sx={{ margin: '0px 0px 0px 0px', float: 'right' }} disabled={credits > 0 ? false : true} startIcon={<ListAltIcon/>}>VIEW INVOICES</Button>
                    </Box>
                  </FormControl>

                  <Modal open={isInvoiceOpen} onClose={(event, reason) => {
                    // Prevent closing the modal if the reason is 'backdropClick'
                    if (reason !== 'backdropClick') {
                      setIsInvoiceOpen(false);
                    }
                    }} BackdropProps={{
                        // Disable clicks on the backdrop to prevent closing the modal
                        onClick: (event) => event.stopPropagation(),
                    }} aria-labelledby="modal-modal-title" aria-describedby="modal-modal-description">
                    <Box sx={{ width: '90%', position: 'absolute', top: '50%', left: '50%', transform: 'translate(-50%, -50%)',bgcolor: 'background.paper', boxShadow: 24, p: 4, padding: '40px 40px 30px 40px!important' }}>
                      <Typography variant="h6" color="black" align="center"> 
                        Invoice List
                      </Typography>
                      {isInprogressM ? 
                          <Box sx={{margin: '10px 0px 10px 0px'}}>
                            <Skeleton />
                            <Skeleton animation="wave" />
                            <Skeleton animation={false} />
                          </Box>
                        :
                          <>
                            {Object.keys(transactionList).length > 0 ?
                              <Divider sx={{color: 'gray', fontSize: '13px', margin: '10px 0px 15px 0px'}}> Invoices ({transactionList.invoices.length})</Divider>
                              : <></>
                            }
                            <TableContainer component={Paper} sx={{maxHeight: '271px'}}>
                              <Table stickyHeader sx={{ minWidth: '100%' }} size="small" aria-label="a dense table">
                                {"invoices" in transactionList > 0 ?
                                  <>
                                    <TableHead>
                                      <TableRow>
                                        <TableCell>Id</TableCell>
                                        <TableCell>Customer Email</TableCell>
                                        <TableCell>Customer Name</TableCell>
                                        <TableCell>Created Date</TableCell>
                                        <TableCell>Status</TableCell>
                                        <TableCell>Total</TableCell>
                                        <TableCell>Actions</TableCell>
                                      </TableRow>
                                    </TableHead>
                                  
                                    <TableBody>
                                      {transactionList.invoices.map((row, index) => (
                                        <TableRow key={row.id} sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                                          <TableCell component="th" scope="row">
                                            {row.number}
                                          </TableCell>
                                          <TableCell component="th" scope="row">
                                            {row.customer_email}
                                          </TableCell>
                                          <TableCell>{row.customer_name}</TableCell>
                                          <TableCell component="th" scope="row">
                                            {new Date(parseInt(`${row.created}000`)).toLocaleString("en-US")}
                                          </TableCell>
                                          <TableCell sx={{width: '10%'}}>{row.status}</TableCell>
                                          <TableCell sx={{width: '10%'}}>{row.total} USD</TableCell>
                                          <TableCell sx={{width: '3%'}}>
                                            <Tooltip title="Download">
                                              <BrowserUpdatedIcon onClick={() => (window.open(row.invoice_pdf, '_blank'))} sx={{margin: '5px 0px 0px 3px', cursor: 'pointer', color: '#787878'}}/>
                                            </Tooltip>
                                          </TableCell>
                                        </TableRow>
                                      ))}
                                    </TableBody>
                                  </>
                                : 
                                  <Alert sx={{margin: '10px 0px 10px 0px', width: '100%'}} severity="warning">
                                    No Invoice Data
                                  </Alert>
                                }
                              </Table>
                            </TableContainer>
                          </>
                      }
                      <Button onClick={() => handleViewInvoiceClick(false)} sx={{ margin: '15px 0px 0px 0px', fontSize: '10px', float: 'right' }}  startIcon={<CloseIcon/>}>Close</Button>
                    </Box>
                  </Modal>

                  { parseInt(tier) === 0 ?
                      response === null ? 
                        state === 'success' && session_id ? 
                          <>
                            <FormControl fullWidth>
                              <Box sx={{ minHeight: "50px" }} noValidate autoComplete="off">
                                <Alert sx={{ minWidth: "100%" }} severity="success">
                                  Payment Complete... <strong style={{textDecoration: 'underline', cursor: 'pointer'}} onClick={()=>(navigate("/welcome"))}>Schedule an interview!</strong>
                                </Alert>
                              </Box>
                            </FormControl>
                            <FormControl fullWidth>
                              <Box sx={{ minHeight: "50px" }} noValidate autoComplete="off">
                                <Button onClick={()=>(navigate("/welcome"))} sx={{ textTransform: 'none', float: 'right' }} color="secondary">Done</Button>
                              </Box>
                            </FormControl>
                          </>
                        :
                          state === 'canceled' ?
                            <> 
                              <FormControl fullWidth>
                                <Box sx={{ minHeight: "50px" }} noValidate autoComplete="off">
                                  <Alert sx={{ minWidth: "100%" }} severity="error">
                                    Payment Failed!
                                  </Alert>
                                </Box>
                              </FormControl>
                              <FormControl fullWidth>
                                <Box sx={{ minHeight: "50px" }} noValidate autoComplete="off">
                                  <Button onClick={() => navigate('/welcome')} sx={{ textTransform: 'none', float: 'right' }} color="secondary">Done</Button>
                                </Box>
                              </FormControl>
                            </>
                          :
                            <>
                              { isError === '' ?
                                <Grid container spacing={2} sx={{minWidth: '200px', justifyContent: 'center', margin: '0px 0px 0px 0px!important'}}>
                                  {productList.map((value, index) => (
                                    <Grid key={index} item xs={12} sm={6} md={4} sx={{margin: '0px 0px 0px 0px', padding: '10px!important'}}>
                                      <Card>
                                        <CardContent sx={{backgroundColor: value.color, color: 'white', padding: '10px'}}>
                                          <Typography gutterBottom sx={{ color: 'text.secondary', fontSize: 14, color: 'white' }}>
                                            {value.name}
                                          </Typography>
                                          <Typography variant="h5" component="div">
                                            {value.price}
                                          </Typography>
                                          <Typography sx={{ color: 'text.secondary', fontSize: '10px', color: 'white'}}>one-time</Typography>
                                          <br/>
                                          <Typography sx={{ color: 'text.secondary', fontSize: '14px', fontWeight: '800', color: 'white'}}>Includes:</Typography>
                                          <Typography variant="body2">
                                            <strong>{value.credits}</strong> Interview credits
                                            <br/>
                                            <strong>Unlimited</strong> AI Checks <span style={{fontSize: '9px'}}>beta</span>
                                          </Typography>
                                        </CardContent>
                                        <CardActions sx={{justifyContent: 'right'}}>
                                          <Button onClick={() => (handlePayClick(process.env.REACT_APP_PAYMENT_METHOD, value))} sx={{fontSize: '10px'}}>Buy</Button>
                                        </CardActions>
                                      </Card>
                                    </Grid>
                                  ))}
                                </Grid>
                              :
                                <FormControl fullWidth>
                                  <Box sx={{ minHeight: "50px" }} noValidate autoComplete="off">
                                    <Alert sx={{ minWidth: "100%" }} severity="error">
                                      {isError} — <strong style={{textDecoration: "underline", cursor: "pointer"}} ><a onClick={() => handleResetClick(isError === "Unathorized!"? "/auth" : "/payment")}>start again!</a></strong>
                                    </Alert>
                                  </Box>
                                </FormControl>
                              }
                            </>
                    
                    :
                      <> 
                        <FormControl fullWidth>
                          <Box sx={{ minHeight: "50px" }} noValidate autoComplete="off">
                            <Alert sx={{ minWidth: "100%" }} severity="info">
                              Redirecting to payment gateway... Please wait...
                            </Alert>
                          </Box>
                        </FormControl>
                        <Typography color="black" align="center" sx={{fontSize: '10px', margin: '5px 0px 0px 0px!important', whiteSpace: 'pre-wrap'}}> 
                          checkout.stripe.com
                        </Typography>
                      </>
                  : 
                    <>
                      <FormControl fullWidth>
                        <Box sx={{ minHeight: "50px" }} noValidate autoComplete="off">
                          <Alert sx={{ minWidth: "100%" }} severity="info">
                            You have no active <strong>credits. FREE Account</strong>
                          </Alert>
                        </Box>
                      </FormControl>
                      <FormControl fullWidth>
                        <Box sx={{ minHeight: "50px" }} noValidate autoComplete="off">
                          <Button onClick={() => navigate('/welcome')} sx={{ textTransform: 'none', float: 'right' }} color="secondary">Go Back</Button>
                        </Box>
                      </FormControl>
                    </>
                  }
                </Stack>
                  {/* { 
                    isMobile ?
                      <></>
                    : 
                      <></>
                  } */}
              </Box>
            </FormControl>
          : 
            <Stack direction="column" spacing={2} justifyContent="center" alignItems="center" minHeight="70vh" maxWidth="43vh" sx={{margin: "0 auto"}}> 
              <FormControl fullWidth>
                <Box sx={{ '& .MuiTextField-root': { m: 1, width: '25ch' }, minHeight: "50px" }} noValidate autoComplete="off">
                  <img style={{width: '190px', margin: '0px 0px 20px 115px'}} src={ForbiddenImage} alt="Something went wrong icons created by andinur - Flaticon" />
                  <Alert variant="outlined" severity="error">
                    Unauthorized <strong style={{textDecoration: "underline", cursor: "pointer"}} ><a onClick={() => window.location.href = '/welcome'}>go back to home</a></strong>
                  </Alert>
                </Box>
              </FormControl>
              <Typography color="black" align="center" sx={{fontSize: '10px', margin: '5px 0px 0px 0px!important'}}> 
                If you are receiving this error with high occurence - please contact the support <a href="mailto:administrator@edcelvista.com">administrator@edcelvista.com</a>
              </Typography>
            </Stack>
          }
          </Grid>
      </Grid>
    </>
  )
}

export default PaymentPage;