import React, { useState, useEffect, useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import FormControl from '@mui/material/FormControl';
import Alert from '@mui/material/Alert';
import Skeleton from '@mui/material/Skeleton';
import Grid from '@mui/material/Grid';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Stack from '@mui/material/Stack';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import InputLabel from '@mui/material/InputLabel';
import ForbiddenImage from '../images/error.png'
import axios from "axios";

const UpdateUserDetailsPage = (props) => {
  const navigate                                    = useNavigate();
  const [isError, setIsError]                       = useState('');
  const [loggedUser, setLoggedUser]                 = useState("");
  const [isInprogress, setInprogress]               = useState(true);
  const [tier, setTier]                             = useState(1);
  // auth
  const [auth, setAuth]                             = useState(false);
  const [authLoading, setAuthLoading]               = useState(true);

  // page data
  const emailTextInput                              = useRef(null);
  const [email, setEmail]                           = useState('');
  const [ttier, setTtier]                           = useState('');
  const [userType, setUserType]                     = useState('');
  const [response, setResponse]                     = useState(null);
  // mobile viewport detection
  const [width, setWidth]                           = useState(window.innerWidth);

  const handleWindowSizeChange = () => {
    setWidth(window.innerWidth);
  }

  useEffect(() => {
    window.addEventListener('resize', handleWindowSizeChange);
    return () => {
      window.removeEventListener('resize', handleWindowSizeChange);
    }
  }, []);
  
  const isMobile = width <= 768;
  console.log(`Mobile Veiwport: ${isMobile}`);

  const removeCookie = (name, path = '/') => {
    var domain = process.env.REACT_APP_ROOT_DOMAIN;
    const cookieString = `${encodeURIComponent(name)}=; expires=Thu, 01 Jan 1970 00:00:00 GMT; Secure=; path=${path}`;
    if (domain) {
        document.cookie = `${cookieString}; domain=${domain}`;
    } else {
        document.cookie = cookieString;
    }
  };

  useEffect(() => {
    setAuthLoading(false);
    props.authState.then(function(authData){
      if(authData){
        setAuth(true);
        console.log(`Auth Connection Status: true | ${JSON.stringify(authData.data.decodedJwtToken.decodedJwtToken.INTERVIEW_USERDATA_username)}`);
        var jwtUserDetails = atob(window.localStorage.getItem("Authorization").split(".")[1]);
        setLoggedUser(JSON.parse(jwtUserDetails).INTERVIEW_USERDATA_username);
        setTier(JSON.parse(jwtUserDetails).INTERVIEW_USERDATA_tier);
        setInprogress(false);
      }else{
        setIsError("Unathorized!");
        setInprogress(false);
        console.log(`Auth Connection Status: false | Error`);
        window.localStorage.removeItem("Authorization");
        window.localStorage.removeItem("ssoProvider");
        removeCookie("Authorization");
        removeCookie("ssoProvider");
        window.location.href = '/auth';
      }
    }).catch(function(err){
      setIsError("Unathorized!");
      setInprogress(false);
      console.log(`Auth Connection Status: false | ${JSON.stringify(err)}`);
      window.localStorage.removeItem("Authorization");
      window.localStorage.removeItem("ssoProvider");
      removeCookie("Authorization");
      removeCookie("ssoProvider");
      window.location.href = '/auth';
    });
  }, []);

  useEffect(() => {
    if(email !== '' && userType !== ''){
      setInprogress(true);
      // page data init
      let __createdtime__ = Date.now();
      let data = JSON.stringify({
        "INTERVIEW_USERDATA_username": email,
        "INTERVIEW_USERDATA_tier": userType
      });
      axios.post(`${process.env.REACT_APP_REST_API_URL}/userAccount/updateUserDetails`, data, { 
        headers: { 'Content-Type': 'application/json', authtoken: window.localStorage.getItem("Authorization"), '__createdtime__': __createdtime__ },
        maxBodyLength: Infinity,
      })
      .then((res) => {
        if(res.data.error === true) {
          setIsError(res.data.errorMessage);
        }else{
          if(res.data){
            setIsError('');
            setEmail('');
            setTtier('');
            setResponse(res.data);
          }else{
            setEmail('');
            setTtier('');
            setIsError('Unable to update user tier.');
          }
        }
        setInprogress(false);
      })
      .catch((e) => {
        setInprogress(false);
        console.log(e);
      });
    }
  }, [email, userType]);


  const handleResetClick = (path) => {
    window.location.pathname === path ? window.location.reload() : navigate(path)
  };

  const handleSetCredentialClick = (type) => {
    setIsError("");
    let regex = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]+$/i; // email
    switch (type) {
      case 'email':
        if(emailTextInput.current.value.length > 0){
          if (!regex.test(emailTextInput.current.value.replace(/\s/g, ''))) {
            setIsError("Invalid Email!");
          }else{
            setIsError("");
            setEmail(emailTextInput.current.value);
          }
        }else{
          setIsError("Invalid Email!");
        }
        break;
      case 'ttier':
        if(ttier.length > 0){
          setUserType(ttier);
          setIsError("");
        }else{
          setIsError("Invalid Tier!");
        }
        break;
      default:
        setIsError('Unknown Credentials');
        break;
    }
  };

  const handleUserTypeChange = (event) => {
    setTtier(event.target.value);
  };

  return (
    <>
      <Grid container spacing={1} direction="column" alignItems="center" sx={{margin: "15px 0px 0px 0px"}}> 
        <Grid sx={{minWidth: isMobile ? "100%!important" : "80%!important", maxWidth: isMobile ? "100%!important" : "80%!important"}}>
          { auth === true && parseInt(tier) === 0? 
            <FormControl fullWidth sx={{margin: "0px 0px 0px 0px"}}>
              <Box sx={{ '& .MuiTextField-root': { m: 1 }, padding: '0px 30px 0px 20px' }} noValidate autoComplete="off">
                <Stack direction="column" spacing={2} justifyContent="center" alignItems="center" minHeight="70vh" maxWidth="43vh" sx={{margin: "0 auto"}}> 
                  <Typography variant="h6" color="black" align="center"> 
                    Update User type
                  </Typography>
                  { isInprogress ? 
                    <Box sx={{margin: '10px 0px 10px 0px', minWidth: "41vh"}}>
                      <Skeleton />
                      <Skeleton animation="wave" />
                      <Skeleton animation={false} />
                    </Box>
                  :
                    response ? 
                      <>
                        <FormControl fullWidth>
                          <Box sx={{ minHeight: "50px" }} noValidate autoComplete="off">
                            <Alert sx={{ minWidth: "100%" }} severity="success">
                              User Type updated — <strong style={{textDecoration: "underline", cursor: "pointer"}} >{response.email}</strong> is now a <strong>{JSON.parse(response.data.Attributes.INTERVIEW_USERDATA_tier.S) === '0' ? 'Interviewer' : 'Interviewee'}.</strong>
                            </Alert>
                          </Box>
                        </FormControl>
                        <FormControl fullWidth>
                          <Box sx={{ minHeight: "50px" }} noValidate autoComplete="off">
                            <Button onClick={() => navigate('/welcome')} sx={{ textTransform: 'none', float: 'right' }} color="secondary">Done</Button>
                          </Box>
                        </FormControl>
                      </>
                    :
                      <>
                        { isError === '' ?
                          <></>
                        :
                          <FormControl fullWidth>
                            <Box sx={{ minHeight: "50px" }} noValidate autoComplete="off">
                              <Alert sx={{ minWidth: "100%" }} severity="error">
                                {isError} — <strong style={{textDecoration: "underline", cursor: "pointer"}} ><a onClick={() => handleResetClick(isError === "Unathorized!"? "/auth" : "/updateUserDetails")}>start again!</a></strong>
                              </Alert>
                            </Box>
                          </FormControl>
                        }
                        <Typography variant="caption" color="black" align="center" sx={{margin: "0px 0px 0px 0px!important"}}> 
                          Update user whether interviewee or interviewer. Make sure email is correct.
                        </Typography>
                        <TextField disabled={isError !==''} type="email" label="Email" fullWidth size='small' inputRef={emailTextInput} />
                        { email === '' ?
                            isError !== '' ?
                              <FormControl fullWidth>
                                <Box sx={{ minHeight: "50px" }} noValidate autoComplete="off">
                                  <Button onClick={() => handleResetClick("/updateUserDetails")} sx={{ textTransform: 'none', float: 'right' }} color="secondary">Start Over</Button>
                                </Box>
                              </FormControl>
                              : <FormControl fullWidth>
                                  <Box sx={{ minHeight: "50px" }} noValidate autoComplete="off">
                                    <Button variant="contained" onClick={() => handleSetCredentialClick('email')} sx={{ textTransform: 'none', float: 'right' }} color="secondary">Proceed</Button>
                                  </Box>
                                </FormControl>
                        : <>
                            <FormControl sx={{width: '100%'}}>
                              <InputLabel id="user-type-select" sx={{margin: '-8px 0px 0px 0px'}}>User Type</InputLabel>
                              <Select
                                labelId="user-type-select"
                                id="user-type-select-autowidth"
                                value={ttier}
                                onChange={handleUserTypeChange}
                                fullWidth
                                size='small'
                                label="Template"
                                disabled={isError !==''}
                              >
                                <MenuItem value="0">Interviewer</MenuItem>
                                <MenuItem value="1">Interviewee</MenuItem>
                              </Select>
                            </FormControl>
                            {isError !== '' ?
                              <FormControl fullWidth>
                                <Box sx={{ minHeight: "50px" }} noValidate autoComplete="off">
                                  <Button onClick={() => handleResetClick("/updateUserDetails")} sx={{ textTransform: 'none', float: 'right' }} color="secondary">Start Over</Button>
                                </Box>
                              </FormControl>
                              :
                              <FormControl fullWidth>
                                <Box sx={{ minHeight: "50px" }} noValidate autoComplete="off">
                                  <Button onClick={() => handleSetCredentialClick('ttier')} variant="contained" sx={{ textTransform: 'none', float: 'right' }} color="secondary">Change</Button>
                                  <Button onClick={() => navigate('/welcome')} sx={{ textTransform: 'none', float: 'right' }} color="secondary">Cancel</Button>
                                </Box>
                              </FormControl>
                            }
                          </>
                        }
                      </>
                  }
                </Stack>
                  {/* { 
                    isMobile ?
                      <></>
                    : 
                      <></>
                  } */}
              </Box>
            </FormControl>
          : 
            isError === '' ?
              <Stack direction="column" spacing={2} justifyContent="center" alignItems="center" minHeight="70vh" maxWidth="43vh" sx={{margin: "0 auto"}}> 
                <FormControl fullWidth>
                  <Box sx={{ '& .MuiTextField-root': { m: 1, width: '25ch' }, minHeight: "50px" }} noValidate autoComplete="off">
                    <img style={{width: '190px', margin: '0px 0px 20px 115px'}} src={ForbiddenImage} alt="Something went wrong icons created by andinur - Flaticon" />
                    <Alert variant="outlined" severity="error">
                      Unauthorized <strong style={{textDecoration: "underline", cursor: "pointer"}} ><a onClick={() => window.location.href = '/welcome'}>go back to home</a></strong>
                    </Alert>
                  </Box>
                </FormControl>
                <Typography color="black" align="center" sx={{fontSize: '10px', margin: '5px 0px 0px 0px!important'}}> 
                  If you are receiving this error with high occurence - please contact the support <a href="mailto:example@example.com">administrator@edcelvista.com</a>
                </Typography>
              </Stack>
            :
              <FormControl fullWidth>
                <Box sx={{ '& .MuiTextField-root': { m: 1, width: '25ch' }, minHeight: "50px" }} noValidate autoComplete="off">
                  <Alert sx={{ minWidth: "100%", margin: "10px" }} severity="error">
                    {isError} — <strong style={{textDecoration: "underline", cursor: "pointer"}} ><a onClick={() => handleResetClick(isError === "Unathorized!"? "/auth" : "/invite")}>start again!</a></strong>
                  </Alert>
                </Box>
              </FormControl>
          }
          </Grid>
      </Grid>
    </>
  )
}

export default UpdateUserDetailsPage;