import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import Grid from '@mui/material/Grid';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Typography from '@mui/material/Typography';
import FormControl from '@mui/material/FormControl';
import Stack from '@mui/material/Stack';
import Box from '@mui/material/Box';
import Alert from '@mui/material/Alert';
import axios from "axios";
import { PayPalButtons, usePayPalScriptReducer } from "@paypal/react-paypal-js";

const PaypalCheckout = (props) => {
    const navigate                           = useNavigate();

    const [isError, setIsError]              = useState("");
    const [isInprogress, setInprogress]      = useState(false);
    
    const [{ options, isPending }, dispatch] = usePayPalScriptReducer();
    const [currency, setCurrency]            = useState(options.currency);
    const productDetails                     = props.productDetails // {name: "Starter plan III", "price": "$30.00", "priceNocur": "30.00", "credits":"15", "price_id": "price_1Q9qTKGI7PTiSnQil7kiI66x", "color": '#7e287e'}
    const loggedUser                         = props.loggedUser

    const [response, setResponse]            = useState(null);

    // mobile viewport detection
    const [width, setWidth]                           = useState(window.innerWidth);

    const handleWindowSizeChange = () => {
        setWidth(window.innerWidth);
    }

    useEffect(() => {
        window.addEventListener('resize', handleWindowSizeChange);
        return () => {
        window.removeEventListener('resize', handleWindowSizeChange);
        }
    }, []);
  
    const isMobile = width <= 768;
    console.log(`Mobile Veiwport: ${isMobile}`);

    const handleResetClick = (path) => {
        window.location.pathname === path ? window.location.reload() : navigate(path)
    };

    const onCurrencyChange = ({ target: { value } }) => {
        setCurrency(value);
        dispatch({
            type: "resetOptions",
            value: {
                ...options,
                currency: value,
            },
        });
    };

    const onCreateOrder = (data, actions) => {
        return actions.order.create({
            purchase_units: [
                {
                    description: `${productDetails.name}|${productDetails.price}|${productDetails.priceNocur}|${productDetails.credits}|${productDetails.price_id}|${productDetails.color}`,
                    amount: {
                        value: productDetails.priceNocur,
                    },
                },
            ],
        });
    };

    const onApproveOrder = (data, actions) => {
        setInprogress(true);
        return actions.order.capture().then((details) => {
            let __createdtime__ = Date.now();
            const data = new FormData();

            const name = details.payer.name.given_name;
            data.append('session_id', details.id);
            axios.post(`${process.env.REACT_APP_REST_API_URL}/payment/paypalSuccess`, data, { 
                headers: { 'Content-Type': 'application/x-www-form-urlencoded', authtoken: window.localStorage.getItem("Authorization"), '__createdtime__': __createdtime__ },
                maxBodyLength: Infinity,
            })
            .then((res) => {
                if(res.data.error === true) {
                    setIsError(res.data.errorMessage);
                }else{
                    if(res.data){
                        setIsError('')
                        setResponse(res.data);
                        console.log(`Redirecting: ${res.data.redirect_url}`)
                        window.location.href=res.data.redirect_url;
                    }else{
                        setIsError(`Unable to proceed with the payment.`);
                    }
                }
                setInprogress(false);
            })
            .catch((e) => {
                setInprogress(false);
                console.log(e);
            });
        });
    };

    return (
        <div className="checkout">
            {isPending ? <p>LOADING...</p> : (
                response ? 
                    <FormControl fullWidth>
                        <Box noValidate autoComplete="off">
                            <Alert sx={{ minWidth: "100%" }} severity="success">
                                Payment Completed. Please wait to redirect.
                            </Alert>
                        </Box>
                    </FormControl>
                :
                    <>
                        { isError === '' ?
                            <></>
                        :
                            <FormControl fullWidth>
                                <Box component="form" sx={{ '& .MuiTextField-root': { m: 1, width: '25ch' }, minHeight: "50px" }} noValidate autoComplete="off">
                                    <Alert sx={{ margin: "10px", width: isMobile ? '93%' : '98%' }} severity="error">
                                        {isError} — <strong style={{textDecoration: "underline", cursor: "pointer"}} ><a onClick={() => handleResetClick(isError === "Unathorized!"? "/auth" : "/payment")}>start again!</a></strong>
                                    </Alert>
                                </Box>
                            </FormControl>
                        }
                        
                        { isInprogress ?
                            <FormControl fullWidth>
                                <Box noValidate autoComplete="off">
                                    <Alert sx={{ margin: "10px", width: isMobile ? '93%' : '98%' }}severity="info">
                                        Processing.. Please wait.. Do not refresh the page.
                                    </Alert>
                                </Box>
                            </FormControl>
                        : <></>}

                        <FormControl fullWidth sx={{margin: "10px 0px 0px 0px"}}>
                            <Box sx={{ '& .MuiTextField-root': { m: 1 }, padding: '0px 30px 0px 20px' }} noValidate autoComplete="off">
                                <Stack direction="column" spacing={2} justifyContent="center" alignItems="center" sx={{margin: "0 auto"}}> 
                                    <Typography variant="h6" color="black" align="center" sx={{margin: '0px 0px 0px 0px!important'}}> 
                                        Payment Gateway - PayPal
                                    </Typography>
                                    <Typography variant="caption" color="black" align="center" sx={{margin: '0px 0px 0px 0px!important'}}> 
                                        Email us at administrator@edcelvista.com.
                                    </Typography>
                                    <Grid item xs={12} sm={12} md={12} sx={{margin: '0px 0px 0px 0px', padding: '10px!important'}}>
                                        <Card sx={{width: '310px', minWidth: '210px', margin: '-20px 0px 15px 0px'}}>
                                            <CardContent sx={{backgroundColor: productDetails.color, color: 'white', padding: '10px'}}>
                                                <Typography gutterBottom sx={{ color: 'text.secondary', fontSize: 14, color: 'white' }}>
                                                {productDetails.name}
                                                </Typography>
                                                <Typography variant="h5" component="div">
                                                {productDetails.price}
                                                </Typography>
                                                <Typography sx={{ color: 'text.secondary', fontSize: '10px', color: 'white'}}>one-time</Typography>
                                                <br/>
                                                <Typography sx={{ color: 'text.secondary', fontSize: '14px', fontWeight: '800', color: 'white'}}>Includes:</Typography>
                                                <Typography variant="body2">
                                                <strong>{productDetails.credits}</strong> Interview credits
                                                <br/>
                                                <strong>Unlimited</strong> AI Checks <span style={{fontSize: '9px'}}>beta</span>
                                                </Typography>
                                            </CardContent>
                                        </Card>
                                        {/* <div style={{ margin: "-12px 0px 3px 0px", textAlign: "center", fontSize: "10px", textDecoration: "underline", fontStyle: "italic", fontWeight: "bold" }}>Account Detected for {loggedUser}</div> */}
                                        <PayPalButtons
                                            style={{ layout: "vertical", shape: "sharp" }}
                                            createOrder={(data, actions) => onCreateOrder(data, actions)}
                                            onApprove={(data, actions) => onApproveOrder(data, actions)}
                                        />
                                    </Grid>
                                </Stack>
                            </Box>
                        </FormControl>
                    </>
            )}
        </div>
    );
}

export default PaypalCheckout;
