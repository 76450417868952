import { createBrowserRouter } from 'react-router-dom';
import LandingPage from '../components/LandingPage';
import ErrorPage from '../components/ErrorPage';
import AuthPage from '../components/AuthPage';
import SSOPage from '../components/SSOPage';
import SignupPage from '../components/SignupPage';
import WelcomePage from '../components/WelcomePage';
import InterviewPage from '../components/InterviewPage';
import IntervieweePage from '../components/IntervieweePage';
import InvitePage from '../components/InvitePage';
import UploadTemplatePage from '../components/UploadTemplatePage';
import UpdateUserDetailsPage from '../components/UpdateUserDetailsPage';
import PaymentPage from '../components/PaymentPage';
import PaymentPagePaypal from '../components/PaymentPagePaypal';
import PricingPage from '../components/PricingPage';
import { verifyToken } from '../service/DataService';
import Root from '../components/Root';

const verifyTokenRes = verifyToken();
export const router = createBrowserRouter([
  {
    path: '/',
    element: <Root />,
    errorElement: <ErrorPage />,
    children: [
      {
        index: true,
        element: <LandingPage />
      },
      {
        path: '/auth',
        element: <AuthPage />
      },
      {
        path: '/sso',
        element: <SSOPage />
      },
      {
        path: '/signup',
        element: <SignupPage />
      },
      {
        path: '/welcome',
        element: <WelcomePage authState={verifyTokenRes} />
      },
      {
        path: '/interview/:INTERVIEW_METADATA_id',
        element: <InterviewPage authState={verifyTokenRes} />
      },
      {
        path: '/interviewee/:INTERVIEW_TEMPLATE_id/:signingKey/:INTERVIEW_METADATA_id',
        element: <IntervieweePage authState={verifyTokenRes} />
      },
      {
        path: '/invite/:date?',
        element: <InvitePage authState={verifyTokenRes} />
      },
      {
        path: '/uploadTemplate',
        element: <UploadTemplatePage authState={verifyTokenRes} />
      },
      {
        path: '/updateUserDetails',
        element: <UpdateUserDetailsPage authState={verifyTokenRes} />
      },
      {
        path: '/payment',
        element: <PaymentPage authState={verifyTokenRes} />
      },
      {
        path: '/paymentPaypal/:productDetails',
        element: <PaymentPagePaypal authState={verifyTokenRes} />
      },
      {
        path: '/pricing',
        element: <PricingPage/>
      }
    ]
  },
]);

export default router;