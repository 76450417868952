import React, { useState, useEffect, useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import FormControl from '@mui/material/FormControl';
import Alert from '@mui/material/Alert';
import socketIO from 'socket.io-client';
import Skeleton from '@mui/material/Skeleton';
import Grid from '@mui/material/Grid';
import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import Button from '@mui/material/Button';
import ArrowRightAltIcon from '@mui/icons-material/ArrowRightAlt';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import InputLabel from '@mui/material/InputLabel';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import OutlinedInput from '@mui/material/OutlinedInput';
import Snackbar from '@mui/material/Snackbar';
import Tooltip from '@mui/material/Tooltip';
import Zoom from '@mui/material/Zoom';
import Rating from '@mui/material/Rating';
import TextField from '@mui/material/TextField';
import SearchIcon from '@mui/icons-material/Search';
import SortIcon from '@mui/icons-material/Sort';
import HighlightOffIcon from '@mui/icons-material/HighlightOff';
import Divider from '@mui/material/Divider';
import NotificationsNoneSharpIcon from '@mui/icons-material/NotificationsNoneSharp';
import NotificationsActiveIcon from '@mui/icons-material/NotificationsActive';
import FormatAlignLeftIcon from '@mui/icons-material/FormatAlignLeft';
import CalendarViewMonthIcon from '@mui/icons-material/CalendarViewMonth';
import LensBlurIcon from '@mui/icons-material/LensBlur';
import Stack from '@mui/material/Stack';
import ToggleButton from '@mui/material/ToggleButton';
import ToggleButtonGroup from '@mui/material/ToggleButtonGroup';
import Modal from '@mui/material/Modal';
import CloseIcon from '@mui/icons-material/Close';
import ArchiveIcon from '@mui/icons-material/Archive';
import ListAltIcon from '@mui/icons-material/ListAlt';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import BrowserUpdatedIcon from '@mui/icons-material/BrowserUpdated';
import { TransitionGroup } from 'react-transition-group';
import Collapse from '@mui/material/Collapse';
import axios from "axios";
import { Calendar, momentLocalizer } from 'react-big-calendar';
import 'react-big-calendar/lib/css/react-big-calendar.css';
import moment from 'moment';
import dayjs from 'dayjs';
import NothingImage from '../images/events.png'
const localizer = momentLocalizer(moment);
const timezone = Intl.DateTimeFormat().resolvedOptions().timeZone;

const WelcomePage = (props) => {
  const navigate                                    = useNavigate();
  const [isError, setIsError]                       = useState('');
  const [loggedUser, setLoggedUser]                 = useState("");
  const [isInprogress, setInprogress]               = useState(true);
  const [tier, setTier]                             = useState(1);
  // auth
  const [auth, setAuth]                             = useState(false);
  const [authLoading, setAuthLoading]               = useState(true);
  const [socket, setSocket]                         = useState(null);
  const [isSocketConnected, setIsSocketConnected]   = useState(false);

  // mobile viewport detection
  const [width, setWidth]                           = useState(window.innerWidth);

  // page data
  const [interviewsList, setInterviewsList]         = useState({
    interviewee: [],
    interviewer: {}
  });
  const [interviewsListRaw, setInterviewsListRaw]   = useState({
    interviewee: [],
    interviewer: {}
  });
  const [sortOpen, setSortOpen]                     = useState(false);
  const [isSorted, setIsSorted]                     = useState(false);
  const [isSearchedOpen, setIsSearchedOpen]         = useState(false);
  const [isSearched, setIsSearched]                 = useState(false);
  const searchTextInput                             = useRef(null);
  const [sortBy, setSortBy]                         = useState(false);
  const [sortOrder, setSortOrder]                   = useState(false);
  const [recentUpdates, setRecentUpdates]           = useState([]);
  const [updateNotifOpen, setUpdateNotifOpen]       = useState(false);
  const [calEvents, setCalEvents]                   = useState([]);
  const [calEventsRaw, setCalEventsRaw]             = useState([]);
  const [isPendingOnly, setIsPendingOnly]           = useState(false);
  const [liveFeed, setLiveFeed]                     = useState(false);
  const [viewType, setViewType]                     = useState('calendar');
  const [isViewInterviewConfirmationOpen, setIsViewInterviewConfirmationOpen] = useState(false);
  const [interviewSelectedLink, setInterviewSelectedLink] = useState(false);
  const [isArchiveOpen, setIsArchiveOpen]           = useState(false);
  const [isInprogressM, setInprogressM]             = useState(true);
  const [interviewArchives, setInterviewArchives]   = useState([]);
  const [isCalSelectSlotOpen, setIsCalSelectSlotOpen] = useState(false);
  const [inviteInterviewDateSelected, setInviteInterviewDateSelected] = useState(null);

  const handleWindowSizeChange = () => {
    setWidth(window.innerWidth);
  }

  useEffect(() => {
    window.addEventListener('resize', handleWindowSizeChange);
    return () => {
      window.removeEventListener('resize', handleWindowSizeChange);
    }
  }, []);
  
  const isMobile = width <= 768;
  console.log(`Mobile Veiwport: ${isMobile}`);

  const removeCookie = (name, path = '/') => {
    var domain = process.env.REACT_APP_ROOT_DOMAIN;
    const cookieString = `${encodeURIComponent(name)}=; expires=Thu, 01 Jan 1970 00:00:00 GMT; Secure=; path=${path}`;
    if (domain) {
        document.cookie = `${cookieString}; domain=${domain}`;
    } else {
        document.cookie = cookieString;
    }
  };
  
  useEffect(() => {
    setAuthLoading(false);
    props.authState.then(function(authData){
      if(authData){
        setAuth(true);
        console.log(`Auth Connection Status: true | ${JSON.stringify(authData.data.decodedJwtToken.decodedJwtToken.INTERVIEW_USERDATA_username)}`);
        let __createdtime__ = Date.now();
        var jwtUserDetails = atob(window.localStorage.getItem("Authorization").split(".")[1]);
        setLoggedUser(JSON.parse(jwtUserDetails).INTERVIEW_USERDATA_username);
        setTier(JSON.parse(jwtUserDetails).INTERVIEW_USERDATA_tier);

        const socketInit = socketIO.connect(process.env.REACT_APP_SOCKET_API_URL,
          { transports: ["websocket"], query: `token=${window.localStorage.getItem("Authorization")}`, extraHeaders: { Authorization: `Bearer ${window.localStorage.getItem("Authorization")}` } }
        );
        setSocket(socketInit);

        // page data init
        const data = new FormData();
        data.append('INTERVIEW_METADATA_isArchive', "0");
        axios.post(`${process.env.REACT_APP_REST_API_URL}/interview/all`, data, { 
          headers: { 'Content-Type': 'application/json', authtoken: window.localStorage.getItem("Authorization"), '__createdtime__': __createdtime__ },
          maxBodyLength: Infinity,
        })
        .then((res) => {
          if(res.data.error === true) {
            setIsError(res.data.errorMessage);
          }else{
            if(res.data){
              setIsError('')
              var intervieweeList = [];
              var interviewer;
              var calEventsTmp = [];
              res.data.Items.forEach(e => {
                let tmp = JSON.parse(e.INTERVIEW_METADATA_interviewee.S);

                const inviteExpirationTmp = JSON.parse(atob(e.INTERVIEW_METADATA_invite_link.S.split("/")[2].split(".")[1])).exp;
                const inviteExpiration = new Date(parseInt(inviteExpirationTmp * 1000)).toLocaleString("en-US");
                const isExpired        = (inviteExpirationTmp * 1000 - Date.now() > 0) ? false : true;
                const hrsLeft          = (inviteExpirationTmp * 1000 - Date.now()) / (1000 * 60 * 60);

                tmp.INTERVIEW_METADATA_id             = e.INTERVIEW_METADATA_id;
                tmp.INTERVIEW_METADATA_created_date   = e.INTERVIEW_METADATA_created_date;
                tmp.INTERVIEW_METADATA_status         = e.INTERVIEW_METADATA_status;
                tmp.INTERVIEW_METADATA_invite_link    = e.INTERVIEW_METADATA_invite_link;
                tmp.INTERVIEW_METADATA_published_date = e.INTERVIEW_METADATA_published_date;
                tmp.INTERVIEW_METADATA_rating         = e.INTERVIEW_METADATA_rating;
                tmp.inviteExpiration                  = inviteExpiration;
                tmp.currentStatusIsExpired            = isExpired;
                tmp.hrsLeft                           = hrsLeft;

                if (isExpired && e.INTERVIEW_METADATA_status.S === 'PENDING') // set pending state with expired link to EXPIRED STATUS 
                  e.INTERVIEW_METADATA_status.S = 'EXPIRED';

                intervieweeList.push(tmp);
                if(!interviewer)
                  interviewer = JSON.parse(e.INTERVIEW_METADATA_interviewer.S)

                //creating calendar events
                let intervieweeTmp = JSON.parse(e.INTERVIEW_METADATA_interviewee.S);

                calEventsTmp.push({
                  title: `${intervieweeTmp.name} - ${intervieweeTmp.designation}`,
                  start: new Date(parseInt(inviteExpirationTmp * 1000)),
                  end: new Date(parseInt(inviteExpirationTmp * 1000)), // Add 3 hours in milliseconds
                  allDay: true,
                  resource: 'any',
                  id: e.INTERVIEW_METADATA_id.S,
                  inviteLink: e.INTERVIEW_METADATA_invite_link.S,
                  email: tmp.email,
                  status: e.INTERVIEW_METADATA_status,
                  name: intervieweeTmp.name,
                  designation: intervieweeTmp.designation,
                  isExpired: isExpired,
                  hrsLeft: hrsLeft,
                  INTERVIEW_METADATA_published_date: tmp.INTERVIEW_METADATA_published_date,
                  INTERVIEW_METADATA_rating: tmp.INTERVIEW_METADATA_rating
                });
              });

              if(window.localStorage.getItem("sort-by") !== null && window.localStorage.getItem("order-by") !== null){
                let persistentSortBy  = window.localStorage.getItem("sort-by");
                let persistentOrderBy = window.localStorage.getItem("order-by");
                setSortBy(persistentSortBy);
                setSortOrder(persistentOrderBy);
                if(persistentSortBy === 'created_date' && persistentOrderBy === 'd'){
                  intervieweeList.sort((a, b) => b.INTERVIEW_METADATA_created_date.S - a.INTERVIEW_METADATA_created_date.S);
                }
                if(persistentSortBy === 'created_date' && persistentOrderBy === 'a'){
                  intervieweeList.sort((a, b) => a.INTERVIEW_METADATA_created_date.S - b.INTERVIEW_METADATA_created_date.S);
                }
                console.log(`Sorting Param Detected: ${persistentSortBy} order ${persistentOrderBy}`);
              }else{
                // default sort created_date DESC
                intervieweeList.sort((a, b) => b.INTERVIEW_METADATA_created_date.S - a.INTERVIEW_METADATA_created_date.S);
                console.log(`Sorting Default: ${sortBy} order ${sortOrder}`);
              }

              setInterviewsList({
                interviewee: intervieweeList,
                interviewer: interviewer
              });

              setInterviewsListRaw({
                interviewee: intervieweeList,
                interviewer: interviewer
              });

              //creating calendar events
              setCalEvents(calEventsTmp);
              setCalEventsRaw(calEventsTmp);
            }else{
              setIsError('Unable to fetched interview list.');
            }
          }
          setInprogress(false);
        })
        .catch((e) => {
          setInprogress(false);
          console.log(e);
        });

      }else{
        setIsError("Unathorized!");
        setInprogress(false);
        console.log(`Auth Connection Status: false | Error`);
        window.localStorage.removeItem("Authorization");
        window.localStorage.removeItem("ssoProvider");
        removeCookie("Authorization");
        removeCookie("ssoProvider");
        window.location.href = '/auth';
      }
    }).catch(function(err){
      setIsError("Unathorized!");
      setInprogress(false);
      console.log(`Auth Connection Status: false | ${JSON.stringify(err)}`);
      window.localStorage.removeItem("Authorization");
      window.localStorage.removeItem("ssoProvider");
      removeCookie("Authorization");
      removeCookie("ssoProvider");
      window.location.href = '/auth';
    });

    const interval = setInterval(() => {
      setLiveFeed(prev => !prev);
    }, 3000); // Change transition every second

    return () => clearInterval(interval); 
  }, []);

  useEffect(() => {
    if(socket !== null){
      setTimeout(function(){
        setIsSocketConnected(socket.connected);
      },1000);

      socket.on('unauthorized', (error, callback) => {
        if (error.data.type == 'UnauthorizedError' || error.data.code == 'invalid_token') {
          // redirect user to login page perhaps?
          callback();
          console.log('User token has expired');
        }
      });

      socket.emit('ping', (data) => {
        console.log(`Ping: ${JSON.stringify(data)}`);
      });

      socket.on('pong', (data) => {
        console.log(`Pong: ${JSON.stringify(data)}`);
      });

      socket.on("interview_updates", (data) => {
        console.log(`Receiving Socket Broadcast Data...`);
        try {
          console.log(`Socket Broadcast Data: ${JSON.stringify(data)}`);
          setRecentUpdates(prevItems => [...prevItems, data.message.id]);
          setUpdateNotifOpen(true);
          setTimeout(function(){
            setUpdateNotifOpen(false);
          }, 8000);
          
          var docTitle = "New Updates Available!";
          setInterval(function(){
            docTitle = docTitle === "New Updates Available!" ? "* New Updates Available!" : "New Updates Available!";
            document.title = docTitle;
          },2000);
        } catch (error) {
          console.log(`Socket Broadcast Data: Failed... ${new Date().toISOString()}`);
        }
      });
    }
  }, [socket]);

  const handleResetClick = (path) => {
    window.location.pathname === path ? window.location.reload() : navigate(path)
  };

  const handleSortChange = (event, type) => {
    switch (type.toLowerCase()) {
      case 'sort-by':
        setSortBy(event.target.value);
        window.localStorage.setItem("sort-by", event.target.value);
        break;
      case 'order-by':
        setSortOrder(event.target.value);
        window.localStorage.setItem("order-by", event.target.value);
        break;
      default:
        break;
    }
  };

  const handleSortClick = () => {
    if(sortBy === 'created_date'){
      if(sortOrder === 'd'){
        interviewsList.interviewee.sort((a, b) => b.INTERVIEW_METADATA_created_date.S - a.INTERVIEW_METADATA_created_date.S);
      }
      if(sortOrder === 'a'){
        interviewsList.interviewee.sort((a, b) => a.INTERVIEW_METADATA_created_date.S - b.INTERVIEW_METADATA_created_date.S);
      }
    }

    if (sortBy === 'rating'){
      if(sortOrder === 'd'){
        interviewsList.interviewee.sort((a, b) => {
          if (a.INTERVIEW_METADATA_rating === undefined) return 1; // Move `undefined` to the end
          if (b.INTERVIEW_METADATA_rating === undefined) return -1; // Move `undefined` to the end
          return a.INTERVIEW_METADATA_rating.S - b.INTERVIEW_METADATA_rating.S;
        });
      }
      if(sortOrder === 'a'){
        interviewsList.interviewee.sort((a, b) => {
          if (b.INTERVIEW_METADATA_rating === undefined) return -1; // Move `undefined` to the end
          if (a.INTERVIEW_METADATA_rating === undefined) return 1; // Move `undefined` to the end
          return b.INTERVIEW_METADATA_rating.S - a.INTERVIEW_METADATA_rating.S;
        });
      }
    }

    if (sortBy === 'published_date'){
      if(sortOrder === 'd'){
        interviewsList.interviewee.sort((a, b) => {
          if (a.INTERVIEW_METADATA_published_date === undefined) return 1; // Move `undefined` to the end
          if (b.INTERVIEW_METADATA_published_date === undefined) return -1; // Move `undefined` to the end
          return a.INTERVIEW_METADATA_published_date.S - b.INTERVIEW_METADATA_published_date.S;
        });
      }
      if(sortOrder === 'a'){
        interviewsList.interviewee.sort((a, b) => {
          if (b.INTERVIEW_METADATA_published_date === undefined) return -1; // Move `undefined` to the end
          if (a.INTERVIEW_METADATA_published_date === undefined) return 1; // Move `undefined` to the end
          return b.INTERVIEW_METADATA_published_date.S - a.INTERVIEW_METADATA_published_date.S;
        });
      }
    }

    setSortOpen(false);
    console.log(`Sorting: ${sortBy} order ${sortOrder}`);
  };

  const setTextFilter = (textFilter = searchTextInput.current.value) => {
    setIsSearched(textFilter);

    if(textFilter.toUpperCase().includes('PENDING') || textFilter.toUpperCase().includes('EXPIRED') || textFilter.toUpperCase().includes('PUBLISHED')){
      // Filtering to get items with name 'Banana'
      const filteredItems        = interviewsList.interviewee.filter(item => item.INTERVIEW_METADATA_status.S.includes(textFilter));
      interviewsList.interviewee = filteredItems;

      //calEvents
      const filteredItemsCal     = calEvents.filter(item => item.status.S.includes(textFilter));
      setCalEvents(filteredItemsCal);
    }else{
      // Filtering to get items with name 'Banana'
      const filteredItems        = interviewsList.interviewee.filter(item => item.name.includes(textFilter) || item.email.includes(textFilter));
      interviewsList.interviewee = filteredItems;

      //calEvents
      const filteredItemsCal     = calEvents.filter(item => item.title.includes(textFilter) || item.email.includes(textFilter));
      setCalEvents(filteredItemsCal);
    }
  };

  const handleSearchClick = () => {
    handleSearchReset();
    setIsSearchedOpen(false);
    setTextFilter();
  };

  const handleSortReset = () => {
    interviewsList.interviewee.sort((a, b) => a.INTERVIEW_METADATA_created_date.S - b.INTERVIEW_METADATA_created_date.S);
    setSortBy(false);
    setSortOrder(false);
    setIsSorted(false);
    window.localStorage.removeItem("sort-by");
    window.localStorage.removeItem("order-by");
    setIsPendingOnly(false);
  };
  
  const handleSearchReset = () => {
    // reset filter
    interviewsList.interviewee = interviewsListRaw.interviewee;
    setIsSearched(false);
    setCalEvents(calEventsRaw);
    setIsPendingOnly(false);
  };

  const handleRating = (id, ratingParam, index) => {
    let rating = ratingParam === null ? 0 : ratingParam;
    console.log(`Rating: [${id}] => ${rating}`);
    let ratingData = {'INTERVIEW_METADATA_id': id, 'INTERVIEW_METADATA_rating': rating};
    socket.emit('rating', ratingData);
    // Create a copy of the object
    const interviewsListTmp = { ...interviewsList };
    // Update the specific key
    interviewsListTmp.interviewee[index].INTERVIEW_METADATA_rating = {S: rating}
    // Set the new object in the state
    setInterviewsList(interviewsListTmp);
  };

  const getHoursDifference = (timestamp1, timestamp2) => {
    // Convert the timestamps to Date objects
    const date1 = timestamp1;
    const date2 = timestamp2;

    // Calculate the difference in milliseconds
    const diffInMs = Math.abs(date2 - date1);

    // Convert milliseconds to hours
    const diffInHours = diffInMs / (1000 * 60 * 60);

    return diffInHours;
  };

  const handleCalEventClick = (e) => {
    if (parseInt(tier) === 0) {
      // navigate(`/interview/${e.id}`);
      handleInterviewConfirmation(true, parseInt(tier) === 0 ? `/interview/${e.id}|${e.name}|${e.designation}|${e.status.S}|${e.isExpired}|${e.hrsLeft}|${e.INTERVIEW_METADATA_published_date === undefined ? undefined : e.INTERVIEW_METADATA_published_date.S}|${e.INTERVIEW_METADATA_rating === undefined ? undefined : e.INTERVIEW_METADATA_rating.S}` : `/${e.inviteLink}|${e.name}|${e.designation}|${e.status.S}|${e.isExpired}|${e.hrsLeft}|${e.INTERVIEW_METADATA_published_date === undefined ? undefined : e.INTERVIEW_METADATA_published_date.S}|${e.INTERVIEW_METADATA_rating === undefined ? undefined : e.INTERVIEW_METADATA_rating.S}`)
    }else{
      // navigate(`/${e.inviteLink}`);
      handleInterviewConfirmation(true, parseInt(tier) === 0 ? `/interview/${e.inviteLink}|${e.name}|${e.designation}|${e.status.S}|${e.isExpired}|${e.hrsLeft}|${e.INTERVIEW_METADATA_published_date === undefined ? undefined : e.INTERVIEW_METADATA_published_date.S}|${e.INTERVIEW_METADATA_rating === undefined ? undefined : e.INTERVIEW_METADATA_rating.S}` : `/${e.inviteLink}|${e.name}|${e.designation}|${e.status.S}|${e.isExpired}|${e.hrsLeft}|${e.INTERVIEW_METADATA_published_date === undefined ? undefined : e.INTERVIEW_METADATA_published_date.S}|${e.INTERVIEW_METADATA_rating === undefined ? undefined : e.INTERVIEW_METADATA_rating.S}`)
    }
  };

  const handlePendingClick = (state) => {
    if(state){
      setTextFilter('PENDING');
    }else{
      // reset filter
      interviewsList.interviewee = interviewsListRaw.interviewee;
      setIsSearched(false);
      setCalEvents(calEventsRaw);
    } 
    setIsPendingOnly(state);
  };

  const handleViewToggle = (event, toogle) => {
    setViewType(toogle);
  };

  const handleInterviewConfirmation = (toggle, navlink = false) => {
    setIsViewInterviewConfirmationOpen(toggle);
    if (navlink){
      console.log(navlink);
      setInterviewSelectedLink(navlink.split("|")); // 0 = link | 1 = name | 2 = designation | 3 = status | 4 = expired | 5 = hrsLeft | 6 = publishedDate | 7 = rating
    }
  };

  const handleArchiveClick = (e, id) =>{
    let archiveDynamicPanel = e.target.parentElement.parentElement.parentElement.querySelector(`[archivedynamicpanel="${id}"]`);
    let archiveDynamicBtn = e.target.parentElement.parentElement.parentElement.querySelector(`[archivedynamicbtn="${id}"]`);
    if (archiveDynamicPanel)
      archiveDynamicPanel.style.display = 'inline-grid';

    if(archiveDynamicBtn)
      archiveDynamicBtn.style.display = 'none';

    // interviewsList.interviewee.splice(index, 1);
    const filteredItems           = interviewsList.interviewee.filter(item => item.INTERVIEW_METADATA_id.S !== id);
    interviewsList.interviewee    = filteredItems;
    interviewsListRaw.interviewee = filteredItems;

    let archiveData = {'INTERVIEW_METADATA_id': id};
    socket.emit('archiveInterview', archiveData);
    console.log(`Archived ${id}`);
  };

  const handleUnArchiveClick = (e, id) =>{
    let unArchiveDynamicPanel = e.target.parentElement.parentElement.querySelector(`[unarchivedynamicpanel="${id}"]`);
    let unArchiveDynamicBtn   = e.target.parentElement.parentElement.querySelector(`[unarchivedynamicbtn="${id}"]`);
    if (unArchiveDynamicPanel)
      unArchiveDynamicPanel.style.display = 'inline-grid';

    if(unArchiveDynamicBtn)
      unArchiveDynamicBtn.style.display = 'none';

    // interviewArchives.interviewee.splice(index, 1);
    const filteredItems           = interviewArchives.interviewee.filter(item => item.INTERVIEW_METADATA_id.S !== id);
    interviewArchives.interviewee = filteredItems;

    let archiveData = {'INTERVIEW_METADATA_id': id};
    socket.emit('unArchiveInterview', archiveData);
    console.log(`unArchived ${id}`);
  };

  const handleViewArchiveClick = (toogle) => {
    setIsArchiveOpen(toogle);
    if(toogle){
        let __createdtime__ = Date.now();
        setInprogressM(true);
        const data = new FormData();
        data.append('INTERVIEW_METADATA_isArchive', "1");
        axios.post(`${process.env.REACT_APP_REST_API_URL}/interview/all`, data, { 
          headers: { 'Content-Type': 'application/json', authtoken: window.localStorage.getItem("Authorization"), '__createdtime__': __createdtime__ },
          maxBodyLength: Infinity,
        })
        .then((res) => {
          if(res.data.error === true) {
            setIsError(res.data.errorMessage);
          }else{
            if(res.data){
              setIsError('')
              var intervieweeList = [];
              var interviewer;
              res.data.Items.forEach(e => {
                let tmp = JSON.parse(e.INTERVIEW_METADATA_interviewee.S);

                const inviteExpirationTmp = JSON.parse(atob(e.INTERVIEW_METADATA_invite_link.S.split("/")[2].split(".")[1])).exp;
                const inviteExpiration = new Date(parseInt(inviteExpirationTmp * 1000)).toLocaleString("en-US");
                const isExpired        = (inviteExpirationTmp * 1000 - Date.now() > 0) ? false : true;
                const hrsLeft          = (inviteExpirationTmp * 1000 - Date.now()) / (1000 * 60 * 60);

                tmp.INTERVIEW_METADATA_id             = e.INTERVIEW_METADATA_id;
                tmp.INTERVIEW_METADATA_created_date   = e.INTERVIEW_METADATA_created_date;
                tmp.INTERVIEW_METADATA_status         = e.INTERVIEW_METADATA_status;
                tmp.INTERVIEW_METADATA_invite_link    = e.INTERVIEW_METADATA_invite_link;
                tmp.INTERVIEW_METADATA_published_date = e.INTERVIEW_METADATA_published_date;
                tmp.INTERVIEW_METADATA_rating         = e.INTERVIEW_METADATA_rating;
                tmp.inviteExpiration                  = inviteExpiration;
                tmp.currentStatusIsExpired            = isExpired;
                tmp.hrsLeft                           = hrsLeft;

                if (isExpired && e.INTERVIEW_METADATA_status.S === 'PENDING') // set pending state with expired link to EXPIRED STATUS 
                  e.INTERVIEW_METADATA_status.S = 'EXPIRED';

                intervieweeList.push(tmp);
                if(!interviewer)
                  interviewer = JSON.parse(e.INTERVIEW_METADATA_interviewer.S)
              });

              setInterviewArchives({
                interviewee: intervieweeList,
                interviewer: interviewer
              });
            }else{
              setIsError('Unable to fetched interview list archives.');
            }
          }
          setInprogressM(false);
        })
        .catch((e) => {
          setInprogressM(false);
          console.log(e);
        });
    }
  };

  const handleCalSlotSelection = (slotInfo) => {
    if(parseInt(tier) === 0){
      const timeSelected      = slotInfo.start; //format 10-06-2024
      const date              = dayjs(timeSelected); // Input date-time string

      if (date.valueOf() > Date.now()){
        const formattedDateTime = date.format('MM-DD-YYYY');
        setIsCalSelectSlotOpen(true);
        setInviteInterviewDateSelected(formattedDateTime);
      }else{
        console.log(`Date too old to schedule Interview`);
      }
    }else{
      console.log(`Prohibited.`);
    }
  };

  const eventStyleGetter = (event, start, end, isSelected) => {
    var backgroundColor = '#' + event.hexColor;

    if(event.status.S.toUpperCase() === 'EXPIRED'){
      backgroundColor = '#9e0000';
    }

    if(event.status.S.toUpperCase() === 'PUBLISHED'){
      backgroundColor = '#2e7d32';
    }

    var style = {
        backgroundColor: backgroundColor,
        borderRadius: '0px',
        opacity: 0.8,
        color: 'white',
        border: '0px',
        display: 'block'
    };
    return {
      style: style
    };
  };

  return (
    <>
      <Grid container spacing={1} direction="column" alignItems="center" sx={{margin: "15px 0px 0px 0px"}}> 
        <Grid sx={{minWidth: isMobile ? "100%!important" : "80%!important", maxWidth: isMobile ? "100%!important" : "80%!important"}}>

          { isError === '' ?
            <></>
          :
            <FormControl fullWidth>
              <Box component="form" sx={{ '& .MuiTextField-root': { m: 1, width: '25ch' }, minHeight: "50px" }} noValidate autoComplete="off">
                <Alert sx={{ minWidth: "100%", margin: "10px" }} severity="error">
                  {isError} — <strong style={{textDecoration: "underline", cursor: "pointer"}} ><a onClick={() => handleResetClick(isError === "Unathorized!"? "/auth" : "/welcome")}>start again!</a></strong>
                </Alert>
              </Box>
            </FormControl>
          }

          { auth === true ? 
            <FormControl fullWidth sx={{margin: "0px 0px 0px 0px"}}>
              <Box component="form" sx={{ '& .MuiTextField-root': { m: 1, width: '25ch' }, padding: '0px 30px 0px 20px' }} noValidate autoComplete="off">
                <Typography variant="h4" gutterBottom> Your {parseInt(tier) === 0 ? "Interviewees" : "Interviews" } {isSocketConnected ? <div style={{display: 'inline'}}><Tooltip title="Live feed available"><Zoom in={liveFeed} timeout={1000}><LensBlurIcon sx={{color: 'green', fontSize: '24px'}}/></Zoom></Tooltip></div> : <div style={{display: 'inline'}}><Tooltip title="Live feed not available"><LensBlurIcon sx={{color: 'gray', fontSize: '20px'}}/></Tooltip></div>}</Typography> 
                  
                  { isInprogress ? 
                    <Box sx={{margin: '10px 0px 10px 0px', minWidth: "41vh"}}>
                      <Skeleton />
                      <Skeleton animation="wave" />
                      <Skeleton animation={false} />
                    </Box>
                  :
                    Object.keys(interviewsList.interviewee).length <= 0 ? 
                      <Stack direction="column" spacing={2} justifyContent="center" alignItems="center" minHeight="70vh" maxWidth="43vh" sx={{margin: "0 auto"}}> 
                        <FormControl fullWidth>
                          <Box sx={{ '& .MuiTextField-root': { m: 1, width: '25ch' }, minHeight: "50px" }} noValidate autoComplete="off">
                            <img style={{width: '190px', margin: '0px 0px 20px 115px'}} src={NothingImage} alt="Something went wrong icons created by andinur - Flaticon" />
                            <Alert variant="outlined" severity="warning">
                            <div>
                              { isSearched ? 
                                  <>
                                    No Interview available for the applied filter "{isSearched}". &nbsp;
                                    <span style={{textDecoration: "underline", cursor: 'pointer', fontWeight: '800'}} onClick={() => handleResetClick("/welcome")}>reset filter</span> 
                                  </>
                                  :
                                  <span>No Interview available.</span>
                              }
                            </div>
                            </Alert>
                          </Box>
                        </FormControl>
                      </Stack>
                    : 
                    <>

                      <Button sx={{margin: '-3px 0px 0px 0px'}} onClick={() => setIsSearchedOpen(true)} startIcon={<SearchIcon />}>Search</Button>
                      { isSearched ? <span style={{fontSize: '14px', textDecoration: 'underline'}}> {isSearched} <span onClick={() => {handleSearchReset()}} style={{textDecoration: 'underline', cursor: 'pointer', fontSize: '10px'}}><HighlightOffIcon sx={{margin: '0px 0px -4px 0px', display: 'inline', fontSize: '18px', color: 'red'}}/></span></span> : <></>}
                      <Dialog disableEscapeKeyDown open={isSearchedOpen} onClose={() => setIsSearchedOpen(false)}>
                        <DialogTitle>Fill the search form</DialogTitle>
                        <DialogContent>
                          <Box component="form" sx={{ display: 'flex', flexWrap: 'wrap'}}>
                            <TextField size="small" sx={{width: '45vw'}} id="outlined-basic" label="Search name or email" variant="outlined" fullWidth inputRef={searchTextInput}/>
                          </Box>
                        </DialogContent>
                        <DialogActions>
                          <Button onClick={() => {setIsSearchedOpen(false)}} sx={{ margin: '0px 5px 5px 0px', fontSize: '10px' }} color="secondary">Cancel</Button>
                          <Button onClick={() => handleSearchClick()} sx={{ margin: '0px 5px 5px 0px', fontSize: '10px' }} variant="contained" color="secondary">
                            Ok
                          </Button>
                        </DialogActions>
                      </Dialog>

                      <Button sx={{margin: '-3px 0px 0px 0px'}} onClick={() => setSortOpen(true)} startIcon={<SortIcon />}>Sort</Button>
                      { sortBy && sortOrder ? <span style={{fontSize: '14px', textDecoration: 'underline'}}>by {sortBy} {sortOrder === 'd' ? 'descending' : 'ascending'} <span onClick={() => {handleSortReset()}} style={{textDecoration: 'underline', cursor: 'pointer', fontSize: '10px'}}><HighlightOffIcon sx={{margin: '0px 0px -4px 0px', display: 'inline', fontSize: '18px', color: 'red'}}/></span></span> : <></>}
                      <Dialog disableEscapeKeyDown open={sortOpen} onClose={() => setSortOpen(false)}>
                        <DialogTitle>Fill the sort form</DialogTitle>
                        <DialogContent>
                          <Box component="form" sx={{ display: 'flex', flexWrap: 'wrap' }}>
                            <FormControl sx={{ m: 1, minWidth: '260px', width: '100%' }}>
                              <InputLabel htmlFor="sort-by-native">Sort by</InputLabel>
                              <Select size="small" labelId="sort-by-select-label" id="sort-by-select" value={sortBy} onChange={(e) => handleSortChange(e, 'sort-by')} input={<OutlinedInput label="Sort by" id="sort-by-select" />}>
                                <MenuItem value="">
                                  <em>None</em>
                                </MenuItem>
                                <MenuItem value='created_date'>Created Date</MenuItem>
                                <MenuItem value='published_date'>Completed Date</MenuItem>
                                {parseInt(tier) === 0 ?
                                <MenuItem value='rating'>Rating</MenuItem>
                                : <></>}
                              </Select>
                            </FormControl>
                            <FormControl sx={{ m: 1, minWidth: '260px', width: '100%' }}>
                              <InputLabel id="order-select-label">Order</InputLabel>
                              <Select size="small" labelId="order-select-label" id="order-select" value={sortOrder} onChange={(e) => handleSortChange(e, 'order-by')} input={<OutlinedInput label="Order by" />}>
                                <MenuItem value="">
                                  <em>None</em>
                                </MenuItem>
                                <MenuItem value='a'>Ascending</MenuItem>
                                <MenuItem value='d'>Descending</MenuItem>
                              </Select>
                            </FormControl>
                          </Box>
                        </DialogContent>
                        <DialogActions>
                          <Button onClick={() => {setSortOpen(false)}} sx={{ margin: '0px 5px 5px 0px', fontSize: '10px' }} color="secondary">Cancel</Button>
                          <Button onClick={() => handleSortClick()} sx={{ margin: '0px 5px 5px 0px', fontSize: '10px' }} variant="contained" color="secondary">
                            Ok
                          </Button>
                        </DialogActions>
                      </Dialog>

                      <Button sx={{margin: '-3px 0px 0px 0px', backgroundColor: isPendingOnly ? '#f1f1f1': 'white', boxShadow: isPendingOnly ? 'inset 0 3px 5px rgba(0, 0, 0, 0.125)' : 'none'}} onClick={() => handlePendingClick(isPendingOnly ? false : true)} startIcon={isPendingOnly ? <NotificationsActiveIcon/> : <NotificationsNoneSharpIcon/>}>{isPendingOnly ? 'SHOW ALL' : 'SHOW PENDING'}</Button>
                      
                      <Stack direction="row" spacing={4} sx={{display: 'flex', justifyContent: 'flex-end', margin: '0px 0px 0px 0px'}}>
                        <ToggleButtonGroup value={viewType} exclusive onChange={handleViewToggle} aria-label="View">
                          <ToggleButton value="list" size='small' aria-label="left aligned">
                            <FormatAlignLeftIcon />
                          </ToggleButton>
                          <ToggleButton value="calendar" size='small' aria-label="centered">
                            <CalendarViewMonthIcon />
                          </ToggleButton>
                        </ToggleButtonGroup>
                      </Stack>

                      <Modal open={isViewInterviewConfirmationOpen} onClose={(event, reason) => {
                        // Prevent closing the modal if the reason is 'backdropClick'
                        if (reason !== 'backdropClick') {
                          handleInterviewConfirmation(false);
                        }
                        }} BackdropProps={{
                            // Disable clicks on the backdrop to prevent closing the modal
                            onClick: (event) => event.stopPropagation(),
                        }} aria-labelledby="modal-modal-title" aria-describedby="modal-modal-description">
                        <Box sx={{ width: isMobile? '90%' : '40%', position: 'absolute', top: '50%', left: '50%', transform: 'translate(-50%, -50%)',bgcolor: 'background.paper', boxShadow: 24, p: 4, padding: '40px 40px 30px 40px!important' }}>
                          <div style={{fontSize: '13px', display: 'inline-grid', float: 'right', padding: '10px', minWidth: '80px', textAlign: 'center', fontWeight: '450', backgroundColor: '#b7b7b7', color: 'white', margin: '-40px -40px 0px 0px'}}>{interviewSelectedLink[4] === true && interviewSelectedLink[3] === 'PENDING' ? "EXPIRED" : interviewSelectedLink[3]}</div>
                          <Typography variant="body2" gutterBottom>
                            {parseInt(tier) !== 0 && (interviewSelectedLink[3] === 'PENDING' && interviewSelectedLink[4] == "false")? <span style={{color: "orange", fontWeight: '800'}}>The application detects if the page is current not in view - refrain from switching tabs.<br/><br/></span> : <></>}
                            
                            Are you sure you want to proceed with <strong>{interviewSelectedLink[1]}</strong> - {interviewSelectedLink[2]} ?

                            {parseInt(tier) === 0 && (interviewSelectedLink[3] === 'PUBLISHED')? <span style={{color: "green", fontWeight: '800'}}><br/>PUBLISHED - {new Date(parseInt(interviewSelectedLink[6])).toLocaleString("en-US")}<br/>
                              <Rating sx={{margin: '0px 0px 0px -5px!important'}} name="simple-controlled"
                                value={parseInt(interviewSelectedLink[7] === 'undefined' ? 0 : interviewSelectedLink[7])}
                                disabled={true}/>
                            </span> : <></>}
                            {parseInt(tier) === 0 && (interviewSelectedLink[3] === 'EXPIRED')? <><span style={{color: "red", fontWeight: '800'}}><br/>EXPIRED</span> <span> - not complete.</span></>: <></>}
                            {parseInt(tier) === 0 && (interviewSelectedLink[3] === 'PENDING')? <><span style={{color: "orange", fontWeight: '800'}}><br/>PENDING</span> <span> - {Math.floor(interviewSelectedLink[5])}h left..</span></> : <></>}
                            {/* 0 = link | 1 = name | 2 = designation | 3 = status | 4 = expired | 5 = hrsLeft | 6 = publishedDate | 7 = rating */}
                          </Typography>
                          <Button onClick={() => {navigate( interviewSelectedLink[0] )}}  sx={{ display: "flex", margin: '15px 0px 0px 0px', fontSize: '10px', float: 'right' }}  endIcon={<ArrowRightAltIcon/>}>Proceed</Button>
                          <Button onClick={() => handleInterviewConfirmation(false)} sx={{ display: "flex", margin: '15px 0px 0px 0px', fontSize: '10px', float: 'right' }}  startIcon={<CloseIcon/>}>Close</Button>
                        </Box>
                      </Modal>

                      <Button onClick={() => handleViewArchiveClick(isArchiveOpen ? false : true)} sx={{ margin: '-38px 100px 0px 0px', float: 'right' }} startIcon={<ListAltIcon/>}>VIEW ARCHIVED</Button>
                      <Modal open={isArchiveOpen} onClose={(event, reason) => {
                        // Prevent closing the modal if the reason is 'backdropClick'
                        if (reason !== 'backdropClick') {
                          handleViewArchiveClick(false);
                        }
                        }} BackdropProps={{
                            // Disable clicks on the backdrop to prevent closing the modal
                            onClick: (event) => event.stopPropagation(),
                        }} aria-labelledby="modal-modal-title" aria-describedby="modal-modal-description">
                        <Box sx={{ width: '90%', position: 'absolute', top: '50%', left: '50%', transform: 'translate(-50%, -50%)',bgcolor: 'background.paper', boxShadow: 24, p: 4, padding: '40px 40px 30px 40px!important' }}>
                          <Typography variant="h6" color="black" align="center"> 
                            Interview Archive List
                          </Typography>
                          {isInprogressM ? 
                              <Box sx={{margin: '10px 0px 10px 0px'}}>
                                <Skeleton />
                                <Skeleton animation="wave" />
                                <Skeleton animation={false} />
                              </Box>
                            :
                              <>
                                {Object.keys(interviewArchives).length > 0 ?
                                  interviewArchives.interviewee.length > 0 ?
                                    <>
                                      <Divider sx={{color: 'gray', fontSize: '13px', margin: '10px 0px 15px 0px'}}> Automatically delete after 30 days</Divider>
                                      <TableContainer component={Paper} sx={{maxHeight: '271px'}}>
                                        <Table stickyHeader sx={{ minWidth: '100%' }} size="small" aria-label="a dense table">
                                          <>
                                            <TableHead>
                                              <TableRow>
                                                <TableCell>Name</TableCell>
                                                <TableCell>Job Title</TableCell>
                                                <TableCell>Status</TableCell>
                                                {parseInt(tier) === 0 ? <TableCell>Actions</TableCell> : <></>}
                                              </TableRow>
                                            </TableHead>
                                          
                                            <TableBody>
                                              {interviewArchives.interviewee.map((row, index) => (
                                                <TableRow
                                                  key={row.INTERVIEW_METADATA_id.S}
                                                  sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                                >
                                                  <TableCell component="th" scope="row">
                                                    {row.name}
                                                  </TableCell>
                                                  <TableCell>{row.designation}</TableCell>
                                                  <TableCell sx={{width: '10%'}}>{row.INTERVIEW_METADATA_status.S}</TableCell>
                                                  {parseInt(tier) === 0 ? 
                                                  <TableCell sx={{width: '3%'}}>
                                                    <span unarchivedynamicpanel={row.INTERVIEW_METADATA_id.S} style={{display: 'none', fontSize: '14px'}}>Restoring...</span>
                                                    <Tooltip title="Recover">
                                                      <BrowserUpdatedIcon unarchivedynamicbtn={row.INTERVIEW_METADATA_id.S} onClick={(e) => (handleUnArchiveClick(e, row.INTERVIEW_METADATA_id.S, index))} sx={{margin: '5px 0px 0px 3px', cursor: 'pointer', color: '#787878'}}/>
                                                    </Tooltip>
                                                  </TableCell>
                                                  : <></>}
                                                </TableRow>
                                              ))}
                                            </TableBody>
                                          </>
                                        </Table>
                                      </TableContainer>
                                    </>
                                  :
                                    <Divider sx={{color: 'gray', fontSize: '13px', margin: '10px 0px 15px 0px'}}> No achived data available </Divider>
                                  : <Divider sx={{color: 'gray', fontSize: '13px', margin: '10px 0px 15px 0px'}}> No achived data available </Divider>
                                }
                                
                              </>
                          }
                          <Button onClick={() => handleViewArchiveClick(false)} sx={{ margin: '15px 0px 0px 0px', fontSize: '10px', float: 'right' }}  startIcon={<CloseIcon/>}>Close</Button>
                        </Box>
                      </Modal>

                      {viewType === 'calendar' ?
                        <>
                          <Calendar
                            localizer={localizer}
                            events={calEvents}
                            startAccessor="start"
                            endAccessor="end"
                            style={{ height: 500, margin: '10px 0px 0px 0px' }}
                            onSelectEvent={(e)=>handleCalEventClick(e)}
                            popup
                            views={['month', 'agenda']}
                            onSelectSlot={(e)=>handleCalSlotSelection(e)}
                            selectable
                            eventPropGetter={(eventStyleGetter)}
                          />
                          <Typography variant="caption" gutterBottom sx={{ display: 'block', fontStyle: 'italic', margin: '10px 0px 0px 0px' }}>
                            Based on {timezone} timezone. <span style={{color: '#9e0000', fontWeight: '800'}}>Expired</span> <span style={{color: '#3174ad', fontWeight: '800'}}>Pending</span> <span style={{color: '#2e7d32', fontWeight: '800'}}>Published</span>. You can schedule an interview by clicking the clalendar slot.
                          </Typography>
                          <Modal open={isCalSelectSlotOpen} onClose={(event, reason) => {
                            // Prevent closing the modal if the reason is 'backdropClick'
                            if (reason !== 'backdropClick') {
                              setIsCalSelectSlotOpen(false);
                            }
                            }} BackdropProps={{
                                // Disable clicks on the backdrop to prevent closing the modal
                                onClick: (event) => event.stopPropagation(),
                            }} aria-labelledby="modal-modal-title" aria-describedby="modal-modal-description">
                            <Box sx={{ width: isMobile ? '85%' : '30%', position: 'absolute', top: '50%', left: '50%', transform: 'translate(-50%, -50%)', bgcolor: 'background.paper', boxShadow: 24, p: 4, padding: '40px 40px 30px 40px!important' }}>
                              <Typography variant="caption" color="black" align="center"> 
                                Schedule an Interview on {inviteInterviewDateSelected}?
                              </Typography>
                              <Button onClick={() => navigate(`/invite/${inviteInterviewDateSelected}`) }sx={{ display: "flex", margin: '15px 0px 0px 0px', fontSize: '10px', float: 'right' }}  endIcon={<ArrowRightAltIcon/>}>Proceed</Button>
                              <Button onClick={() => setIsCalSelectSlotOpen(false)} sx={{ margin: '15px 0px 0px 0px', fontSize: '10px', float: 'right' }}  startIcon={<CloseIcon/>}>Close</Button>
                            </Box>
                          </Modal>
                        </>
                      :
                        <>
                          <Divider sx={{color: 'gray', fontSize: '13px', margin: '0px 0px 24px 0px'}}>Interview list ({interviewsList.interviewee.length})</Divider>
                          <Box sx={{margin: '10px 0px 10px 0px', minWidth: "41vh"}}>
                            <TransitionGroup>
                              {interviewsList.interviewee.map(function(perInterviewee, index){
                                const name        = perInterviewee.name;
                                const designation = perInterviewee.designation;
                                const status      = perInterviewee.INTERVIEW_METADATA_status.S;
                                const remarks     = perInterviewee.remarks;
                                const id          = perInterviewee.INTERVIEW_METADATA_id.S;
                                const interviewDateTmp = new Date(parseInt(perInterviewee.INTERVIEW_METADATA_created_date.S));
                                const interviewDate    = interviewDateTmp.toLocaleString("en-US");
                                const inviteLink       = perInterviewee.INTERVIEW_METADATA_invite_link.S;
                                const email            = perInterviewee.email;
                                // const inviteExpirationTmp = JSON.parse(atob(inviteLink.split("/")[2].split(".")[1])).exp;
                                const inviteExpiration = perInterviewee.inviteExpiration; //new Date(parseInt(inviteExpirationTmp * 1000)).toLocaleString("en-US");
                                const isExpired        = perInterviewee.currentStatusIsExpired; //(inviteExpirationTmp * 1000 - Date.now() > 0) ? false : true;
                                const hrsLeft          = perInterviewee.hrsLeft; // (inviteExpirationTmp * 1000 - Date.now()) / (1000 * 60 * 60);
                                const publishedDate    = perInterviewee.INTERVIEW_METADATA_published_date ? perInterviewee.INTERVIEW_METADATA_published_date.S : null;
                                const rating           = perInterviewee.INTERVIEW_METADATA_rating ? perInterviewee.INTERVIEW_METADATA_rating.S : "0";

                                var isRatingEnabled = false;
                                if(publishedDate){
                                  let ageSincePublished = getHoursDifference(Date.now(), publishedDate);
                                  if(ageSincePublished > 24){ // rating is only allowed 24 hours from publish date
                                    isRatingEnabled = true;
                                  }
                                }

                                return(
                                  <Collapse key={index}> {/* reverse index */}
                                    <Card key={id} sx={{ minWidth: 275, margin: '10px 0px 15px 0px', backgroundColor: '#fbfbfb', borderRadius: '0px' }}>
                                      <div style={{fontSize: '13px', display: 'inline-grid', float: 'right', padding: '10px', minWidth: '80px', textAlign: 'center', fontWeight: '450', backgroundColor: '#b7b7b7', color: 'white'}}>{isExpired && status === 'PENDING' ? "EXPIRED" : status}</div>
                                      {recentUpdates.includes(id) ? <div style={{fontSize: '13px', display: 'inline-grid', float: 'right', padding: '10px', minWidth: '80px', textAlign: 'center', fontWeight: '450', backgroundColor: 'green', color: 'white', fontStyle: 'italic', cursor: 'pointer'}} onClick={() => {window.location.reload()}}>UPDATE AVAILABLE</div> : <></>}
                                      <div archivedynamicpanel={id} style={{fontSize: '13px', display: 'none', float: 'right', padding: '10px', minWidth: '80px', textAlign: 'center', fontWeight: '450', backgroundColor: 'orange', color: 'white', fontStyle: 'italic', cursor: 'pointer'}}>ARCHIVING</div>
                                      <CardContent>
                                        <Typography gutterBottom sx={{ color: 'text.secondary', fontSize: 14 }}>
                                          {designation}
                                        </Typography>
                                        <Typography sx={{wordBreak: 'break-all'}} variant="h5" component="div">
                                          {name}
                                        </Typography>
                                        {isExpired ? 
                                            status === 'EXPIRED' ? 
                                              <Typography variant="subtitle2" sx={{color: "red"}}>
                                                Created - {interviewDate}
                                                <br/>
                                                EXPIRED - {inviteExpiration}
                                              </Typography>
                                            :
                                              <>
                                                <Typography variant="subtitle2" sx={{color: "green"}}>
                                                  Created - {interviewDate}
                                                </Typography>
                                                <Typography variant="subtitle2" sx={{color: "green"}}>
                                                  Interview completed - {new Date(parseInt(publishedDate)).toLocaleString("en-US")}
                                                </Typography>
                                                {parseInt(tier) === 0 ?
                                                  <Tooltip title="Rating only allowed 24hrs after interview completion.">
                                                    <Rating sx={{margin: '5px 0px 0px -5px!important'}} name="simple-controlled"
                                                      value={parseInt(rating)}
                                                      disabled={isRatingEnabled ? true : false}
                                                      onChange={(event, rating) => {
                                                        handleRating(id, rating, index)
                                                    }}/>
                                                  </Tooltip>
                                                : <></>}
                                              </>
                                          :
                                            <>
                                              { status === 'PENDING' ? 
                                                <>
                                                  <Typography variant="subtitle2" sx={{color: 'orange'}}>
                                                    Created - {interviewDate}
                                                  </Typography>
                                                  <Typography variant="subtitle2"> 
                                                    Invertiew must be answered before - {inviteExpiration} ({hrsLeft.toFixed(1)} hrs left)
                                                  </Typography> 
                                                </>
                                              : 
                                                <>
                                                  <Typography variant="subtitle2" sx={{color: `${status === 'PENDING' ? "orange": "green"}`}}>
                                                    Created - {interviewDate}
                                                    <br/>
                                                    Interview completed - {new Date(parseInt(publishedDate)).toLocaleString("en-US")}
                                                  </Typography>
                                                  { parseInt(tier) === 0 ? 
                                                    <Tooltip title="Rating only allowed 24hrs after interview completion.">
                                                      <Rating sx={{margin: '5px 0px 0px -5px!important'}} name="simple-controlled"
                                                        value={rating}
                                                        disabled={isRatingEnabled ? true : false}
                                                        onChange={(event, rating) => {
                                                          handleRating(id, rating, index)
                                                        }}
                                                      />
                                                    </Tooltip>
                                                  : <></>}
                                                </>
                                              }
                                            </>
                                        }
                                        <Typography variant="subtitle2">
                                          {email}
                                        </Typography>
                                        <Typography variant="body2">
                                          <em>{remarks}</em>
                                        </Typography>
                                      </CardContent>
                                      { parseInt(tier) === 0 ? 
                                        <CardActions sx={{float: 'left'}} >
                                          <Tooltip title="Archive">
                                            <ArchiveIcon archivedynamicbtn={id} onClick={(e) => (handleArchiveClick(e, id, index))} sx={{margin: '5px 0px 0px 3px', cursor: 'pointer', color: '#787878'}}/>
                                          </Tooltip>
                                        </CardActions>
                                        :<></>
                                      }
                                      <CardActions sx={{float: 'right'}} >
                                        <Button onClick={() => (handleInterviewConfirmation(true, parseInt(tier) === 0 ? `/interview/${id}|${name}|${designation}|${status}|${isExpired}|${hrsLeft}|${publishedDate}|${rating}` : `/${inviteLink}|${name}|${designation}|${status}|${isExpired}|${hrsLeft}|${publishedDate}|${rating}`))} size="small">View Interview <ArrowRightAltIcon/></Button>
                                      </CardActions>
                                    </Card>
                                  </Collapse>
                                )
                              })}
                            </TransitionGroup>
                          </Box>
                        </>
                        }
                      <br/>
                    </>
                  }
                  
                  {/* { 
                    isMobile ?
                      <></>
                    : 
                      <></>
                  } */}
                  <Snackbar open={updateNotifOpen} message="Interview update available! "/>
              </Box>
            </FormControl>
          : 
            <></>
          }
          </Grid>
      </Grid>
    </>
  )
}

export default WelcomePage;