import React, { useState, useEffect } from 'react';
import { useNavigate, useLocation, useParams } from 'react-router-dom';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import FormControl from '@mui/material/FormControl';
import Alert from '@mui/material/Alert';
import Grid from '@mui/material/Grid';
import Button from '@mui/material/Button';
import Stack from '@mui/material/Stack';
import ForbiddenImage from '../images/error.png'
import PaypalCheckout from './_PaypalCheckout';
import { PayPalScriptProvider } from "@paypal/react-paypal-js";

const PaymentPagePaypal = (props) => {
  const navigate                                    = useNavigate();
  const params                                      = useParams();
  const [isError, setIsError]                       = useState("");
  const [loggedUser, setLoggedUser]                 = useState("");
  const [isInprogress, setInprogress]               = useState(true);
  const [tier, setTier]                             = useState(1);
  // auth
  const [auth, setAuth]                             = useState(false);
  const [authLoading, setAuthLoading]               = useState(true);

  // page data | PAYPAL 
  const productDetails                              = JSON.parse(atob(params.productDetails)); // {name: "Starter plan III", "price": "$30.00", "priceNocur": "30.00", "credits":"15", "price_id": "price_1Q9qTKGI7PTiSnQil7kiI66x", "color": '#7e287e'}

  // mobile viewport detection
  const [width, setWidth]                           = useState(window.innerWidth);

  const handleWindowSizeChange = () => {
    setWidth(window.innerWidth);
  }

  useEffect(() => {
    window.addEventListener('resize', handleWindowSizeChange);
    return () => {
      window.removeEventListener('resize', handleWindowSizeChange);
    }
  }, []);
  
  const isMobile = width <= 768;
  console.log(`Mobile Veiwport: ${isMobile}`);

  const removeCookie = (name, path = '/') => {
    var domain = process.env.REACT_APP_ROOT_DOMAIN;
    const cookieString = `${encodeURIComponent(name)}=; expires=Thu, 01 Jan 1970 00:00:00 GMT; Secure=; path=${path}`;
    if (domain) {
        document.cookie = `${cookieString}; domain=${domain}`;
    } else {
        document.cookie = cookieString;
    }
  };

  const initialOptions = {
    "client-id": process.env.REACT_APP_PAYPAL_CLIENTID,
    currency: "USD",
    intent: "capture",
  };

  useEffect(() => {
    setAuthLoading(false);
    props.authState.then(function(authData){
      if(authData){
        setAuth(true);
        console.log(`Auth Connection Status: true | ${JSON.stringify(authData.data.decodedJwtToken.decodedJwtToken.INTERVIEW_USERDATA_username)}`);
        var jwtUserDetails = atob(window.localStorage.getItem("Authorization").split(".")[1]);
        setLoggedUser(JSON.parse(jwtUserDetails).INTERVIEW_USERDATA_username);
        setTier(JSON.parse(jwtUserDetails).INTERVIEW_USERDATA_tier);

        // page data init
        console.log(`Selected Product: ${JSON.stringify(productDetails)}`);
      }else{
        setIsError("Unathorized!");
        setInprogress(false);
        console.log(`Auth Connection Status: false | Error`);
        window.localStorage.removeItem("Authorization");
        window.localStorage.removeItem("ssoProvider");
        removeCookie("Authorization");
        removeCookie("ssoProvider");
        window.location.href = '/auth';
      }
    }).catch(function(err){
      setIsError("Unathorized!");
      setInprogress(false);
      console.log(`Auth Connection Status: false | ${JSON.stringify(err)}`);
      window.localStorage.removeItem("Authorization");
      window.localStorage.removeItem("ssoProvider");
      removeCookie("Authorization");
      removeCookie("ssoProvider");
      window.location.href = '/auth';
    });
  }, []);

  return (
    <>
      <Grid container spacing={1} direction="column" alignItems="center" sx={{margin: "15px 0px 0px 0px"}}> 
        <Grid sx={{minWidth: isMobile ? "100%!important" : "80%!important", maxWidth: isMobile ? "100%!important" : "80%!important"}}>
          { auth === true ? 

            <FormControl fullWidth sx={{margin: "0px 0px 0px 0px"}}>
              <Box sx={{ '& .MuiTextField-root': { m: 1 }, padding: '0px 30px 0px 20px' }} noValidate autoComplete="off">
                <Stack direction="column" spacing={2} justifyContent="center" alignItems="center" minHeight="70vh" maxWidth="70vh" sx={{margin: "0 auto"}}> 
      
                  { parseInt(tier) === 0 ?
                    <PayPalScriptProvider options={initialOptions}>
                      <PaypalCheckout productDetails={productDetails} loggedUser={loggedUser}/>
                    </PayPalScriptProvider>
                  : 
                    <>
                      <FormControl fullWidth>
                        <Box sx={{ minHeight: "50px" }} noValidate autoComplete="off">
                          <Alert sx={{ minWidth: "100%" }} severity="info">
                            Not Eligible for credit purchase. Only Interviewer account requires credits.
                          </Alert>
                        </Box>
                      </FormControl>
                      <FormControl fullWidth>
                        <Box sx={{ minHeight: "50px" }} noValidate autoComplete="off">
                          <Button onClick={() => navigate('/welcome')} sx={{ textTransform: 'none', float: 'right' }} color="secondary">Go Back</Button>
                        </Box>
                      </FormControl>
                    </>
                  }
                </Stack>
                  {/* { 
                    isMobile ?
                      <></>
                    : 
                      <></>
                  } */}
              </Box>
            </FormControl>
          : 
            <Stack direction="column" spacing={2} justifyContent="center" alignItems="center" minHeight="70vh" maxWidth="43vh" sx={{margin: "0 auto"}}> 
              <FormControl fullWidth>
                <Box sx={{ '& .MuiTextField-root': { m: 1, width: '25ch' }, minHeight: "50px" }} noValidate autoComplete="off">
                  <img style={{width: '190px', margin: '0px 0px 20px 115px'}} src={ForbiddenImage} alt="Something went wrong icons created by andinur - Flaticon" />
                  <Alert variant="outlined" severity="error">
                    Unauthorized <strong style={{textDecoration: "underline", cursor: "pointer"}} ><a onClick={() => window.location.href = '/welcome'}>go back to home</a></strong>
                  </Alert>
                </Box>
              </FormControl>
              <Typography color="black" align="center" sx={{fontSize: '10px', margin: '5px 0px 0px 0px!important'}}> 
                If you are receiving this error with high occurence - please contact the support <a href="mailto:administrator@edcelvista.com">administrator@edcelvista.com</a>
              </Typography>
            </Stack>
          }
          </Grid>
      </Grid>
    </>
  )
}

export default PaymentPagePaypal;