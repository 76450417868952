import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import FormControl from '@mui/material/FormControl';
import Grid from '@mui/material/Grid';
import Button from '@mui/material/Button';
import Stack from '@mui/material/Stack';
import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';

const PaymentPage = (props) => {
  const navigate                      = useNavigate();
  // page data
  const [productList, setProductList] = useState([
    {name: "Starter plan I", "price": "$20.00", "credits":"5", "price_id": "price_1Q9qTwGI7PTiSnQi50N7Ov06", "color": '#db8d00'},
    {name: "Starter plan II", "price": "$25.00", "credits":"10", "price_id": "price_1Q9qThGI7PTiSnQiEvcB52PY", "color": '#4646dc'},
    {name: "Starter plan III", "price": "$30.00", "credits":"15", "price_id": "price_1Q9qTKGI7PTiSnQil7kiI66x", "color": '#7e287e'}
  ]);

  // mobile viewport detection
  const [width, setWidth]                           = useState(window.innerWidth);

  const handleWindowSizeChange = () => {
    setWidth(window.innerWidth);
  }

  useEffect(() => {
    window.addEventListener('resize', handleWindowSizeChange);
    return () => {
      window.removeEventListener('resize', handleWindowSizeChange);
    }
  }, []);
  
  const isMobile = width <= 768;
  console.log(`Mobile Veiwport: ${isMobile}`);

  return (
    <>
      <Grid container spacing={1} direction="column" alignItems="center" sx={{margin: "15px 0px 0px 0px"}}> 
        <Grid sx={{minWidth: isMobile ? "100%!important" : "80%!important", maxWidth: isMobile ? "100%!important" : "80%!important"}}>
            <FormControl fullWidth sx={{margin: "0px 0px 0px 0px"}}>
              <Box sx={{ '& .MuiTextField-root': { m: 1 }, padding: '0px 30px 0px 20px' }} noValidate autoComplete="off">
                <Stack direction="column" spacing={2} justifyContent="center" alignItems="center" minHeight="70vh" maxWidth="70vh" sx={{margin: "0 auto"}}> 
                  <Typography variant="h6" color="black" align="center" sx={{margin: '0px 0px 0px 0px!important'}}> 
                    Pricing & Plans
                  </Typography>
                  <Typography variant="caption" color="black" align="center" sx={{margin: '0px 0px 0px 0px!important'}}> 
                    Ping us at administrator@edcelvista.com if you need more volume or some special features. We'll be glad to help.
                  </Typography>
                  <Grid container spacing={2} sx={{minWidth: '200px', justifyContent: 'center', margin: '0px 0px 0px 0px!important'}}>
                    {productList.map((value, index) => (
                      <Grid key={index} item xs={12} sm={6} md={4} sx={{margin: '0px 0px 0px 0px', padding: '10px!important'}}>
                        <Card>
                          <CardContent sx={{backgroundColor: value.color, color: 'white', padding: '10px'}}>
                            <Typography gutterBottom sx={{ color: 'text.secondary', fontSize: 14, color: 'white' }}>
                              {value.name}
                            </Typography>
                            <Typography variant="h5" component="div">
                              {value.price}
                            </Typography>
                            <Typography sx={{ color: 'text.secondary', fontSize: '10px', color: 'white'}}>one-time</Typography>
                            <br/>
                            <Typography sx={{ color: 'text.secondary', fontSize: '14px', fontWeight: '800', color: 'white'}}>Includes:</Typography>
                            <Typography variant="body2">
                              <strong>{value.credits}</strong> Interview credits
                              <br/>
                              <strong>Unlimited</strong> AI Checks <span style={{fontSize: '9px'}}>beta</span>
                            </Typography>
                          </CardContent>
                          <CardActions sx={{justifyContent: 'right'}}>
                            <Button onClick={() => (navigate("/payment"))} sx={{fontSize: '10px'}}>Buy</Button>
                          </CardActions>
                        </Card>
                      </Grid>
                    ))}
                  </Grid>
                </Stack>
                  {/* { 
                    isMobile ?
                      <></>
                    : 
                      <></>
                  } */}
              </Box>
            </FormControl>
          </Grid>
      </Grid>
    </>
  )
}

export default PaymentPage;